import { useEffect, useRef, useState } from 'react';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { Controller, useFormContext } from 'react-hook-form';

import { ApiPaymentIntent } from 'api/account/payments';
import { PaymentSecuredCardsInfo } from 'components/Account';
import { ChargebeeInstance, WindowDOMwithChargebee } from 'store/account/payments';
import { CreditCardInterfaceWithValidation } from 'store/account/payments';

import { CardField } from './style';
import { useAccount, useAddSubscriptionPaymentMethod } from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	paymentIntent: ApiPaymentIntent | null;
	shouldProcessCard: boolean;
	setShouldProcessCard: (shouldProcessCard: boolean) => void;
	closeModal: () => void;
	onCreditCardAdded?: () => void;
}

export function CardNumberFields({
	paymentIntent,
	shouldProcessCard,
	setShouldProcessCard,
	closeModal,
	onCreditCardAdded
}: Props) {
	const { getValues, setValue, setError, control } =
		useFormContext<CreditCardInterfaceWithValidation>();
	const { setError: setErrorNotification } = useAlerts();
	const [{ data: accountData }] = useAccount();

	const [isCardProcessed, setCardProcessed] = useState(false);

	const [{ loading: addSubscriptionPaymentMethodLoading }, addSubscriptionPaymentMethod] =
		useAddSubscriptionPaymentMethod();

	useEffect(() => {
		if (isCardProcessed && !addSubscriptionPaymentMethodLoading) {
			onCreditCardAdded ? onCreditCardAdded() : closeModal();
		}
	}, [isCardProcessed, addSubscriptionPaymentMethodLoading]);

	const cardRef = useRef<ChargebeeInstance>(null);

	useEffect(() => {
		if (shouldProcessCard) {
			const additionalDetails = { ...getValues(), phone: accountData?.details?.phoneNumber };
			const cbInstance = (window as WindowDOMwithChargebee).Chargebee?.getInstance();
			if (cbInstance) {
				if (paymentIntent && cardRef && cardRef.current)
					cardRef.current
						.authorizeWith3ds(paymentIntent, additionalDetails)
						.then((authorizedPaymentIntent: ApiPaymentIntent) => {
							addSubscriptionPaymentMethod(authorizedPaymentIntent.id);
							setCardProcessed(true);
						})
						.catch(error => {
							setErrorNotification({
								message: error.message
							});

							setShouldProcessCard(false);
						});
			}
		}
	}, [shouldProcessCard]);

	function verifyCardState(status: { cardType: string; complete: boolean }) {
		const { cardType, complete } = status;
		if (cardType !== undefined && complete === true) {
			setValue('cardValid', true, {
				shouldDirty: true,
				shouldValidate: true
			});
		} else {
			setError('cardValid', {
				types: {},
				message: 'Card should be valid'
			});
		}
	}

	const classes = {
		focus: 'focus-css-class',
		complete: 'complete-css-class',
		invalid: 'invalid-css-class',
		empty: 'empty-css-class'
	};

	// Style customizations
	const styles = {
		base: {
			fontWeight: '400',
			fontFamily: `Roboto Flex,'Roboto Flex',sans-serif`,
			fontSize: '14px',
			fontSmoothing: 'antialiased',

			':focus': {
				color: '#424770'
			},

			'::placeholder': {
				color: '#090d14'
			},

			':focus::placeholder': {
				color: '#CFD7DF'
			}
		},
		invalid: {
			color: '#fff',
			':focus': {
				color: '#FA755A'
			},
			'::placeholder': {
				color: '#FFCCA5'
			}
		}
	};

	const fonts = ['https://fonts.googleapis.com/css?family=Open+Sans'];

	return (
		<>
			<CardField>
				<Controller
					control={control}
					name="cardValid"
					render={() => (
						<CardComponent
							ref={cardRef}
							fonts={fonts}
							styles={styles}
							classes={classes}
							locale={'en'}
							onBlur={verifyCardState}
							onChange={verifyCardState}
						/>
					)}
				/>
			</CardField>
			<PaymentSecuredCardsInfo isCompactSize />
		</>
	);
}
