import { useEffect } from 'react';

import { ROUTES } from '../types/navigation';
import { useRouteMatch } from 'hooks/navigation';

import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';

interface WindowDOMwithFreshchat extends Window {
	fcWidget?: {
		on: (args: any, args2: any) => void;
		init: (args: any) => void;
		hide: () => void;
		show: () => void;
		isLoaded: () => boolean;
		isInitialized: () => boolean;
		user: {
			clear: () => void;
		};
	};
}

export function FreshchatDisplay() {
	const isHelpPage = useRouteMatch(ROUTES.Help);

	const FRESHCHAT_TOKEN = process.env.REACT_APP_FRESHCHAT_TOKEN;
	const currentUserQuery = useCurrentUserQuery();

	useEffect(() => {
		// every time user logs out, clear the chat
		if (!currentUserQuery.data) {
			const freshChat = (window as any).fcWidget;
			if (freshChat && freshChat.user) {
				freshChat.user.clear();
			}
		}
	}, [currentUserQuery.data]);

	function initFreshChat() {
		const freshChat = (window as any).fcWidget;
		if (freshChat) {
			freshChat.init({
				token: FRESHCHAT_TOKEN,
				host: 'https://wchat.eu.freshchat.com'
			});
		}
	}

	function initialize() {
		const document = window.document;
		const elementId = 'Freshdesk Messaging-js-sdk';

		const elem = document.getElementById(elementId);
		if (elem) {
			initFreshChat();
		} else {
			const e = document.createElement('script');
			e.id = elementId;
			e.async = !0;
			e.src = 'https://wchat.eu.freshchat.com/js/widget.js';
			e.onload = initFreshChat;
			document.head.appendChild(e);
		}
	}

	useEffect(() => {
		const freshChat = (window as WindowDOMwithFreshchat).fcWidget;
		if (freshChat) {
			// initialize Freshchat only when on the help page
			if (!freshChat.isInitialized() && isHelpPage) {
				initialize();
			}

			if (freshChat.isLoaded()) {
				if (isHelpPage) {
					freshChat.show();
				} else {
					freshChat.hide();
				}
			} else {
				freshChat.on('widget:loaded', () => {
					if (isHelpPage) {
						freshChat.show();
					} else {
						freshChat.hide();
					}
				});
			}
		}
	}, [isHelpPage]);

	return null;
}
