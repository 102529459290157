import { useMfaPreferenceQuery } from 'features/data/amplify/useMfaPreferenceQuery';
import { createContext, useContext, useEffect } from 'react';
import { InactivityDetector } from './auto-logout/InactivityDetector';
import { CurrentUserQuery, useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { usePrevious } from 'hooks/utils';

type AuthContext = {
	mfaPreferenceQuery: ReturnType<typeof useMfaPreferenceQuery>;
	currentUserQuery: CurrentUserQuery;
};

export const AuthContext = createContext<AuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const mfaPreferenceQuery = useMfaPreferenceQuery();
	const currentUserQuery = useCurrentUserQuery();

	useSyncLoginStateBetweenTabs(currentUserQuery, mfaPreferenceQuery);

	return (
		<AuthContext.Provider value={{ mfaPreferenceQuery, currentUserQuery }}>
			<InactivityDetector />

			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

const useSyncLoginStateBetweenTabs = (
	currentUserQuery: CurrentUserQuery,
	mfaPreferenceQuery: ReturnType<typeof useMfaPreferenceQuery>
) => {
	const isSignedIn = currentUserQuery.data;
	const wasSignedIn = usePrevious(isSignedIn);
	useEffect(() => {
		if (wasSignedIn && !isSignedIn) {
			localStorage.setItem(LOGIN_KEY, 'false');
		} else if (!wasSignedIn && isSignedIn) {
			localStorage.setItem(LOGIN_KEY, 'true');
		}
	}, [isSignedIn]);

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === LOGIN_KEY) {
				currentUserQuery.refetch();
				mfaPreferenceQuery.refetch();
			}
		};

		window.addEventListener('storage', handleStorageChange);
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);
};
const LOGIN_KEY = 'auth_is-signed-in';
