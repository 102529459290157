import { useRef, useState } from 'react';

import { useEffect } from 'react';
import { InactivityDialog } from './InactivityDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'features/auth/AuthProvider';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';

export const InactivityDetector = () => {
	const navigate = useNavigate();

	const { currentUserQuery } = useAuth();
	const isSignedIn = currentUserQuery.data;

	const [lastActivityTimeStamp, _setLastActivityTimeStamp] = useState<number>(Date.now());
	const lastSessionRefetch = useRef<number>(Date.now());
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const setLastActivityTimeStamp = (timeStamp: number) => {
		_setLastActivityTimeStamp(timeStamp);
		localStorage.setItem(STORAGE_LAST_ACTIVITY_KEY, timeStamp.toString());
	};
	const setLastSessionRefetch = (timeStamp: number) => {
		lastSessionRefetch.current = timeStamp;
		localStorage.setItem(STORAGE_SESSION_REFETCH_KEY, timeStamp.toString());
	};
	const setDialogState = (state: boolean) => {
		setIsDialogOpen(state);
		localStorage.setItem(STORAGE_DIALOG_STATE_KEY, state.toString());
	};

	useEffect(() => {
		const updateLastActivityTimeStamp = () => {
			setDialogState(false);
			setLastActivityTimeStamp(Date.now());

			const overOneMinuteSinceLastSessionRefetch =
				Date.now() - lastSessionRefetch.current > ONE_MINUTE;

			if (overOneMinuteSinceLastSessionRefetch) {
				lastSessionRefetch.current = Date.now();

				if (!isSignedIn) {
					return;
				}

				currentUserQuery.refetch().then(result => {
					if (!result.data) {
						console.error('Failed to refetch session');
					}
				});
			}
		};

		window.addEventListener('mousemove', updateLastActivityTimeStamp);
		window.addEventListener('keydown', updateLastActivityTimeStamp);
		window.addEventListener('click', updateLastActivityTimeStamp);

		return () => {
			window.removeEventListener('mousemove', updateLastActivityTimeStamp);
			window.removeEventListener('keydown', updateLastActivityTimeStamp);
			window.removeEventListener('click', updateLastActivityTimeStamp);
		};
	}, [currentUserQuery, isSignedIn]);

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === STORAGE_LAST_ACTIVITY_KEY && event.newValue) {
				setLastActivityTimeStamp(parseInt(event.newValue));
			}

			if (event.key === STORAGE_SESSION_REFETCH_KEY && event.newValue) {
				setLastSessionRefetch(parseInt(event.newValue));
			}

			if (event.key === STORAGE_DIALOG_STATE_KEY && event.newValue) {
				setDialogState(event.newValue === 'true');
			}
		};

		window.addEventListener('storage', handleStorageChange);
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);

	useEffect(() => {
		const CHECK_INTERVAL = 1000;

		const interval = setInterval(() => {
			if (!isSignedIn) {
				return;
			}

			const milliSecondsSinceLastActivity = Date.now() - lastActivityTimeStamp;

			if (milliSecondsSinceLastActivity > MILLIS_BEFORE_WARNING) {
				setDialogState(true);
			}

			if (milliSecondsSinceLastActivity > MILLIS_BEFORE_LOGOUT) {
				navigate(ROUTE_MAP.auth.logout.createPath());
			}
		}, CHECK_INTERVAL);

		return () => clearInterval(interval);
	}, [lastActivityTimeStamp, navigate, isSignedIn]);

	return (
		<>
			{isSignedIn && (
				<InactivityDialog
					open={isDialogOpen}
					onClose={() => {
						setLastActivityTimeStamp(Date.now());
						setDialogState(false);
					}}
					lastActivityTimeStamp={lastActivityTimeStamp}
				/>
			)}
		</>
	);
};

export const MILLIS_BEFORE_WARNING = 10 * 60 * 1000; // 10 minutes
export const MILLIS_BEFORE_LOGOUT = 15 * 60 * 1000; // 15 minutes

const ONE_MINUTE = 60 * 1000;

export const STORAGE_LAST_ACTIVITY_KEY = 'inactivity-detector_lastActivityTimeStamp';
const STORAGE_SESSION_REFETCH_KEY = 'inactivity-detector_lastSessionRefetch';
const STORAGE_DIALOG_STATE_KEY = 'inactivity-detector_dialogState';
