import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { useCollaborators, useEntry, usePermissions } from 'hooks/store';

export function useEntryDeleteAccess(): boolean {
	const [{ data: entry }] = useEntry();

	const { hasDatasetDeleteAllAccess, hasDatasetDeleteOwnAccess, hasDatasetDeleteOrgAccess } =
		usePermissions();

	const currentUserQuery = useCurrentUserQuery();

	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators({ lazy: true });

	const ownedByUser = entry?.ownedbyuser ?? '';
	const organizationId = entry?.userProjectOrgId ?? '';
	const organizationUsers = organizationsMap[organizationId]?.collaborators ?? [];

	const deleteAccess =
		hasDatasetDeleteAllAccess ||
		(hasDatasetDeleteOwnAccess && ownedByUser === currentUserQuery.data?.username) ||
		(hasDatasetDeleteOrgAccess &&
			organizationUsers.includes(currentUserQuery.data?.username ?? ''));

	return deleteAccess;
}
