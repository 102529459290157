import { env } from 'features/env';
import { disable } from 'aws-amplify/analytics';
import { Amplify } from 'aws-amplify';

export const configureAmplify = () => {
	// Disable analytics on amplify
	disable();

	// TODO: remember me
	const domain = process.env.REACT_APP_SSO_URL_BASE ?? 'sso.ledidi.no';

	Amplify.configure({
		Auth: {
			Cognito: {
				loginWith: {
					username: true,
					email: true,
					oauth: {
						responseType: 'code',
						redirectSignIn: [`${window.location.origin}/federated-login`],
						redirectSignOut: [window.location.origin],
						scopes: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin'],
						domain
					}
				},
				userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
				identityPoolId: env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
				userPoolClientId: env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
			}
		}
	});
};
