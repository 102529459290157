import { useEffect } from 'react';
import { ImportDataTextInterface } from 'api/data/projects';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes as AccountActionTypes } from 'store/account/subscription';
import {
	getImportDataText,
	ActionTypes as ProjectActionTypes,
	selectImportDataText
} from 'store/data/projects';
import { useDispatch, usePrevious, useSelector } from 'hooks/utils';
import { useActivities } from 'hooks/store/utils/useActivities';
import { useAccount } from 'hooks/store';

export function useImportDataText(): OperationResult<ImportDataTextInterface | null> {
	const dispatch = useDispatch();

	const [{ data: accountData, fetched: isAccountFetched }] = useAccount();

	const data = useSelector(state => selectImportDataText(state.data.projects));

	const [{ loading, error }] = useActivities([
		AccountActionTypes.GET_ACCOUNT,
		ProjectActionTypes.GET_IMPORT_DATA_TEXT
	]);

	const prevLanguageCode = usePrevious(accountData?.languageCode);
	useEffect(() => {
		if (prevLanguageCode !== undefined && prevLanguageCode !== accountData?.languageCode)
			handler();
	}, [accountData?.languageCode]);

	useEffect(() => {
		if (!loading && !error && isAccountFetched && !data) handler();
	}, [loading, error, isAccountFetched, data]);

	function handler() {
		dispatch(getImportDataText());
	}

	return [
		{
			data,
			loading,
			error
		},
		handler
	];
}
