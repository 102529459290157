import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Layout } from './Layout';
import * as z from 'zod';
import { useSelector } from 'hooks/utils';
import { Input } from 'features/entry-form-v2/component/Input';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { Button } from 'features/entry-form-v2/component/Button';

import {
	GET_USER_QUERY_KEY,
	GetUserResponse,
	useGetUserQuery,
	User
} from 'features/data/useGetUserQuery';
import { Loader } from 'components/UI/Loader/Loader';
import { useState } from 'react';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useQueryClient } from '@tanstack/react-query';
import { Dictionary } from 'environment';
import { InputError } from 'features/entry-form-v2/component/InputError';

export function AccountSetupPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];
	const userQuery = useGetUserQuery();

	if (userQuery.isLoading) {
		return (
			<Layout title={translation.title}>
				<Loader primary />
			</Layout>
		);
	}

	if (!userQuery.data?.user) {
		return (
			<Layout title={translation.errorLoadingUser.title}>
				<p className="text-base mb-6">{translation.errorLoadingUser.description}</p>

				<Button
					title={translation.errorLoadingUser.button}
					onClick={() => userQuery.refetch()}
				/>
			</Layout>
		);
	}

	return (
		<Layout title={translation.title}>
			<AccountSetupForm user={userQuery.data.user} />
		</Layout>
	);
}

function AccountSetupForm({ user }: { user: User }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];

	const [isSubmitting, setIsSubmitting] = useState(false);

	const form = useForm<AccountSetupFormData>({
		defaultValues: {
			userFirstName: user.userFirstName ?? '',
			userSirName: user.userSirName ?? '',
			phoneNumber: user.phoneNumber ?? '',
			position: user.position ?? '',
			department: user.department ?? '',
			workplace: user.workplace ?? '',
			city: user.city ?? '',
			country: user.country,
			hasAcceptedTerms: false
		},
		resolver: zodResolver(createSchema(translation))
	});

	const onSubmit = async (data: AccountSetupFormData) => {
		setIsSubmitting(true);

		try {
			const { hasAcceptedTerms: _, ...userData } = data;

			await makeRequest({
				data: {
					user: userData,
					isUserActivating: true
				},
				method: 'updateUser',
				service: 'user'
			});

			queryClient.setQueryData<GetUserResponse>([GET_USER_QUERY_KEY], oldData => {
				if (!oldData) return undefined;
				return {
					...oldData,
					user: {
						...oldData.user,
						...userData
					}
				};
			});

			navigate(ROUTE_MAP.projects.path);
		} catch (error) {
			console.error(error);

			toast.error(translation.toastError, { position: 'top-center' });
		} finally {
			setIsSubmitting(false);
		}
	};

	const country = form.watch('country');

	return (
		<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-10">
			<div>
				<h2 className="text-xl text-gray-700 mb-6">{translation.personalInformation}</h2>
				<div className="flex gap-8 w-full mb-10">
					<Input
						className="flex-1"
						label={translation.firstName}
						placeholder={translation.firstName}
						id="userFirstName"
						error={form.formState.errors.userFirstName?.message}
						obligatory
						{...form.register('userFirstName')}
					/>

					<Input
						className="flex-1"
						label={translation.lastName}
						obligatory
						placeholder={translation.lastName}
						id="userSirName"
						error={form.formState.errors.userSirName?.message}
						{...form.register('userSirName')}
					/>
				</div>

				<Input
					label={translation.phoneNumber}
					placeholder={translation.phoneNumber}
					obligatory
					id="phoneNumber"
					error={form.formState.errors.phoneNumber?.message}
					{...form.register('phoneNumber')}
				/>
			</div>

			<div>
				<h2 className="text-xl text-gray-700 mb-6">{translation.workInformation}</h2>

				<div className="flex flex-col gap-8">
					<Input
						label={translation.workplace}
						placeholder={translation.workplace}
						obligatory
						id="workplace"
						error={form.formState.errors.workplace?.message}
						{...form.register('workplace')}
					/>

					<div className="flex gap-8 ">
						<CountrySelector
							onSelect={country => form.setValue('country', country?.name ?? '')}
							value={country}
							className="flex-1 !text-sm"
							label={translation.country}
							placeholder={translation.country}
							error={form.formState.errors.country?.message}
						/>

						<Input
							className="flex-1"
							label={translation.city}
							placeholder={translation.city}
							id="city"
							error={form.formState.errors.city?.message}
							{...form.register('city')}
						/>
					</div>

					<div className="flex flex-col gap-2 ">
						<div className="flex gap-6 ">
							<Input
								className="flex-1"
								label={translation.position}
								placeholder={translation.position}
								id="position"
								error={form.formState.errors.position?.message}
								{...form.register('position')}
							/>

							<Input
								className="flex-1"
								label={translation.department}
								placeholder={translation.department}
								obligatory
								id="department"
								error={form.formState.errors.department?.message}
								{...form.register('department')}
							/>
						</div>

						<p className="text-base italic">{translation.weNeedInformation}</p>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-2">
				<div className="flex items-center gap-2">
					<input
						id="acceptTerms"
						type="checkbox"
						{...form.register('hasAcceptedTerms')}
					/>

					<label className="text-base" htmlFor="acceptTerms">
						{translation.TERMS.acceptTerms}{' '}
						<a
							href="https://ledidi.com/trust-centre/terms-of-service"
							target="_blank"
							className="underline text-primary-700"
						>
							{translation.TERMS.termsOfService}
						</a>
					</label>
				</div>

				{form.formState.errors.hasAcceptedTerms && (
					<InputError error={form.formState.errors.hasAcceptedTerms.message} />
				)}
			</div>

			<Button
				type="submit"
				className="w-full"
				title={translation.finish}
				loading={isSubmitting}
			/>
		</form>
	);
}
const createSchema = (dictionary: Dictionary) => {
	const schema = z.object({
		userFirstName: z.string().min(1, dictionary.requiredError),
		userSirName: z.string().min(1, dictionary.requiredError),
		phoneNumber: z.string().min(1, dictionary.requiredError),
		position: z.string(),
		department: z.string().min(1, dictionary.requiredError),
		workplace: z.string().min(1, dictionary.requiredError),
		city: z.string(),
		country: z.string().optional(),
		hasAcceptedTerms: z.boolean().refine(value => value, {
			message: dictionary.validation.termsMustBeAccepted
		})
	});

	return schema;
};

type AccountSetupFormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		errorLoadingUser: {
			title: 'Error loading user',
			description: 'Please try again later or contact support if the problem persists.',
			button: 'Try again'
		},

		title: 'A few more details',
		personalInformation: 'Personal information',
		workInformation: 'Work information',
		acceptTerms: "I accept Ledidi's",
		position: 'Position',
		department: 'Department',
		MANDATORY_FIELDS: {
			pretext: 'Fields marked with an',
			posttext: 'are mandatory'
		},
		finish: 'Finish',
		firstName: 'First name',
		lastName: 'Last name',
		phoneNumber: 'Phone number',
		city: 'City',
		workplace: 'Workplace',
		country: 'Country',
		weNeedInformation:
			'We need you to enter the correct information about yourself, so that your colleagues know who they are working with.',
		TERMS: {
			acceptTerms: "I accept Ledidi's",
			termsOfService: 'Terms of service'
		},
		requiredError: 'Please fill in this field.',
		toastError: 'An error occurred when trying to update your account.',

		validation: {
			termsMustBeAccepted: 'You must accept the terms of service to continue.'
		}
	},

	NB: {
		errorLoadingUser: {
			title: 'Feil ved henting av bruker',
			description:
				'Vennligst prøv igjen senere eller kontakt support hvis problemet vedvarer.',
			button: 'Prøv igjen'
		},

		title: 'Kontooppsett',
		personalInformation: 'Personlig informasjon',
		workInformation: 'Arbeidsinformasjon',
		acceptTerms: "Jeg godtar Ledidi's",
		position: 'Stilling',
		department: 'Avdeling',
		MANDATORY_FIELDS: {
			pretext: 'Felt merket med',
			posttext: 'er obligatorisk'
		},
		finish: 'Fullfør',
		firstName: 'Fornavn',
		lastName: 'Etternavn',
		phoneNumber: 'Telefonnummer',
		city: 'By',
		country: 'Land',
		workplace: 'Arbeidsplass',
		weNeedInformation:
			'Vi trenger at du legger inn riktig informasjon om deg selv, slik at dine kolleger vet hvem de arbeider med.',
		TERMS: {
			acceptTerms: "Jeg godtar Ledidi's",
			termsOfService: 'Vilkår for bruk'
		},
		requiredError: 'Vennligst fyll ut dette feltet.',
		toastError: 'En feil oppstod da vi prøvde å oppdatere kontoen din.',
		validation: {
			termsMustBeAccepted: 'Du må godta vilkårene for å fortsette.'
		}
	}
} as const;

type Dictionary = (typeof DICTIONARY)[keyof typeof DICTIONARY];
