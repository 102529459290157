import { useNavigate, useParams } from 'react-router-dom';
import { EntryHeader } from '../../component/EntryHeader';
import { useGetProjectQuery } from '../../data/useGetProjectQuery';
import { ROUTE_MAP } from '../../utils/routeMap';
import { createSubmitEvent, EntryForm, NextAction } from 'features/entry-form-v2/EntryForm';
import { Button } from 'features/entry-form-v2/component/Button';
import { Entry } from 'features/entry-form-v2/types';
import { StrictMode, useRef, useState } from 'react';
import { ErrorModal } from 'features/entry-form-v2/ErrorModal';
import {
	getLatestEntryVersion,
	useGetLatestDataEntryVersionQuery
} from 'features/entry-form-v2/data/useGetLatestDataEntryVersionQuery';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useInsertDataEntryMutation } from 'features/entry-form-v2/data/useInsertDataEntryMutation';
import {
	isSeriesFormItem,
	useProjectData
} from 'features/entry-form-v2/data/useProjectData/useProjectData';
import { SelectGroup } from 'features/entry-form-v2/smart-components/select-group/SelectGroup';
import {
	EntryFormSkeleton,
	ErrorLoadingEntry,
	OptionsMenuButton
} from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { SelectForm, useFormSelector } from 'features/entry-form-v2/smart-components/SelectForm';
import { SplitButton } from 'features/entry-form-v2/component/SplitButton';
import { AggregationRulesDetails } from 'features/entry-form-v2/smart-components/AggregationRulesDetails';
import { Menu } from '@headlessui/react';
import { CloseIcon, FileTextIcon, MoreIcon } from '@icons';
import { useGetRepeatingSetRowsV2Query } from 'features/entry-form-v2/data/useGetRepeatingSetRowsV2Query';
import { SeriesEntryNavigation } from 'features/entry-form-v2/smart-components/series-navigation/SeriesNavigation';
import { useGetFormsQuery } from 'features/entry-form-v2/data/useGetFormsQuery/useGetFormsQuery';
import { IconButton } from 'features/entry-form-v2/component/IconButton';
import clsx from 'clsx';
import { getQueryParam } from 'features/entry-form-v2/create-entry/CreateEntryPageV1_5';

export const CreateSeriesEntryPageV1_5 = () => {
	const { track } = useTracking();
	const params = useParams();
	const navigate = useNavigate();

	const [formKeyManualIdentifier, setFormKeyManualIdentifier] = useState(0);

	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;

	const allowLeavePageOverrideRef = useRef(false);

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const insertDataEntryMutation = useInsertDataEntryMutation();

	const {
		formId,
		setSelectedFormId,
		shouldShowFormSelector,
		getFormsQueryIsLoading,
		toggleShowFormSelector
	} = useFormSelector({
		projectId,
		seriesName
	});

	// I think we should split useProjectData into a separate hook for series, but it's not clear to me how to restructure the tests when we do that, so doing this "hack" for now
	const projectDataQuery = useProjectData({
		projectId
	});
	const seriesFormItem = projectDataQuery.data?.formItems
		.filter(isSeriesFormItem)
		.find(item => item.name === seriesName);

	const seriesProjectDataQuery = useProjectData({
		projectId,
		seriesName,
		selectedFormId: formId
	});

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const [entryIsSubmitting, setEntryIsSubmitting] = useState(false);
	const submitEntry = async (args: { entry: Entry; action?: NextAction }) => {
		try {
			setEntryIsSubmitting(true);

			if (!seriesProjectDataQuery.data) {
				console.error(
					"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
				);
				return;
			}

			const createdSeriesEntry = await insertDataEntryMutation.mutateAsync({
				projectId,
				entry: args.entry,
				organizationGroupId: selectedGroupId,
				setInfo: {
					mainSetRevisionId: dataEntryQuery.data?.entry['original_id'],
					setName: seriesName
				}
			});

			const latestEntryVersion = await getLatestEntryVersion({
				entryId,
				projectId
			});

			allowLeavePageOverrideRef.current = true;

			track({
				eventName: 'entry_series_created'
			});

			if (args.action?.type === 'saveAndNavigate') {
				const nextFormId = getQueryParam({
					url: args.action.nextNavLocation,
					paramName: 'formId'
				});

				// If the formId changes it is safe to assume that the user is navigating to a different form
				if (nextFormId && nextFormId !== formId) {
					allowLeavePageOverrideRef.current = true;

					return navigate(
						ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.update.createPath(
							{
								projectId,
								entryId: latestEntryVersion.entry.datasetentryid,
								seriesName,
								seriesEntryId: createdSeriesEntry.insertedEntry.datasetentryid,
								formId: nextFormId
							}
						),
						{ replace: true }
					);
				}

				setFormKeyManualIdentifier(prev => prev + 1);
				navigate(args.action.nextNavLocation, { replace: true });
				return;
			}

			if (args.action?.type === 'saveAndClose') {
				setFormKeyManualIdentifier(prev => prev + 1);
				return navigate(-1);
			}

			if (args.action?.type === 'saveAndCreateNew') {
				navigate(
					ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.create.createPath({
						projectId,
						entryId: latestEntryVersion.entry.datasetentryid,
						seriesName,
						formId
					}),
					{ replace: true }
				);
				return setFormKeyManualIdentifier(prev => prev + 1);
			}

			navigate(
				ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId: latestEntryVersion.entry.datasetentryid,
					seriesName,
					seriesEntryId: createdSeriesEntry.insertedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (_e) {
			// Error is handled in the ErrorModal
		} finally {
			setEntryIsSubmitting(false);

			allowLeavePageOverrideRef.current = false;
		}
	};

	// Needed to not render SeriesNavigation before it has loaded
	const getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	const isLoading =
		seriesProjectDataQuery.isLoading ||
		getFormsQueryIsLoading ||
		getRepeatingDataSetRowsV2Query.isLoading;

	return (
		<StrictMode>
			{/** Same height as the header */}
			<div className="h-[60px]" />

			<div className="flex flex-col mt-20">
				<ErrorModal
					onClose={insertDataEntryMutation.reset}
					error={insertDataEntryMutation.error}
				/>

				{isLoading && <EntryFormSkeleton />}

				{seriesProjectDataQuery.error && (
					<ErrorLoadingEntry
						isFetching={seriesProjectDataQuery.isFetching}
						refetch={seriesProjectDataQuery.refetch}
					/>
				)}

				{!isLoading && (
					<SelectForm
						expanded={shouldShowFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
						seriesName={seriesName}
					/>
				)}

				{!isLoading && seriesProjectDataQuery.data && (
					<div className="flex flex-col md:w-[756px] lg:mx-auto">
						{!selectedGroupId && (
							<SelectGroup
								onGroupSelected={setSelectedGroupId}
								projectId={projectId}
							/>
						)}

						<AggregationRulesDetails className="mb-10" />

						<SeriesEntryNavigation />

						<EntryForm
							key={`${formId}-${formKeyManualIdentifier}`}
							Header={
								<Header
									isSubmitting={entryIsSubmitting}
									seriesLabel={seriesFormItem?.label}
									selectedFormId={formId}
									shouldShowFormSelector={shouldShowFormSelector}
									toggleShowFormSelector={toggleShowFormSelector}
								/>
							}
							onSubmitForConfirmation={async ({ nextAction, dirtyData }) => {
								await submitEntry({
									entry: dirtyData,
									action: nextAction
								});
							}}
							onSubmitForSubmission={async ({ nextAction, dirtyData }) => {
								await submitEntry({
									entry: dirtyData,
									action: nextAction
								});
							}}
							projectData={seriesProjectDataQuery.data}
							allowLeavePageOverrideRef={allowLeavePageOverrideRef}
							submitting={entryIsSubmitting}
						/>
					</div>
				)}
			</div>
		</StrictMode>
	);
};

const Header = ({
	isSubmitting,
	seriesLabel,
	selectedFormId,
	shouldShowFormSelector,
	toggleShowFormSelector
}: {
	seriesLabel?: string;
	isSubmitting: boolean;
	selectedFormId: string | null;
	shouldShowFormSelector: boolean;
	toggleShowFormSelector: () => void;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;

	const navigate = useNavigate();

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const getProjectQuery = useGetProjectQuery({ projectId });

	const submitToForm = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLElement;
		const form = target.closest('form');

		const submitEvent = createSubmitEvent(target);

		form?.dispatchEvent(submitEvent);
	};

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.projects.byId.dataset.createPath({
						projectId
					})
				},
				{
					title: 'Update Entry',
					url: ROUTE_MAP.projects.byId.dataset.update.createPath({
						projectId,
						entryId,
						formId: null
					})
				},
				{
					title: seriesLabel,
					url: ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.createPath({
						projectId,
						entryId,
						seriesName
					})
				}
			]}
			title="New Series Entry"
		>
			<div className="flex gap-4 items-center">
				{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
					<IconButton
						onClick={toggleShowFormSelector}
						className="self-end"
						aria-label="Toggle form selector"
					>
						<FileTextIcon
							className={clsx(
								'text-icon-base',
								shouldShowFormSelector && 'text-primary-500'
							)}
						/>
					</IconButton>
				)}

				<OptionsMenu
					handlePrint={() => {
						window.open(
							ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.create.print.createPath(
								{
									projectId,
									entryId,
									seriesName,
									formId: selectedFormId
								}
							),
							'_blank'
						);
					}}
				/>

				<div className="flex gap-[1px]">
					<Button
						loading={isSubmitting}
						type="submit"
						className="col-start-4 col-span-2 rounded-r-none"
						data-action="save"
						title="Save"
						variant="primary"
					/>

					<SplitButton className="rounded-l-none">
						<SplitButton.Item
							data-action="saveAndClose"
							title="Save and close"
							onClick={submitToForm}
						/>
						<SplitButton.Item
							data-action="saveAndCreateNew"
							title="Save and create new"
							onClick={submitToForm}
						/>
					</SplitButton>
				</div>

				<IconButton onClick={() => navigate(-1)}>
					<CloseIcon
						className="text-icon-base"
						data-testid="entry-form-header_close-icon"
					/>
				</IconButton>
			</div>
		</EntryHeader>
	);
};

export const OptionsMenu = ({ handlePrint }: { handlePrint: () => void }) => {
	const { track } = useTracking();

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="hover:bg-gray-700/10 rounded-full p-2">
					<MoreIcon className="text-icon-base" aria-label="More options" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked'
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</div>
	);
};
