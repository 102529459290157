import { Reducer, Action } from 'redux';

import { ActionTypes as ProjectsActionTypes, SetProjectIdAction } from 'store/data/projects/types';

interface A extends Action {
	payload: any;
}

export const withReset =
	<S>(reducer: Reducer<S, A>, initialState: S) =>
	(state: S | undefined, action: A) => {
		switch (action.type) {
			case 'RESET_STORE': {
				return JSON.parse(JSON.stringify(initialState));
			}

			default:
				return reducer(state, action);
		}
	};

export const withProjectId =
	<S>(reducer: Reducer<S, A & SetProjectIdAction>) =>
	(state: S | undefined, action: A & SetProjectIdAction): S => {
		switch (action.type) {
			case ProjectsActionTypes.SET_PROJECT_ID: {
				const {
					payload: { projectId }
				} = action as SetProjectIdAction;

				if (state) {
					return reducer(
						{
							...state,
							projectId
						},
						action
					);
				}

				return reducer(state, action);
			}

			default:
				return reducer(state, action);
		}
	};

export const withResetToInitialOnProjectIdChange =
	<
		S extends {
			projectId: string | null;
		}
	>(
		reducer: Reducer<S, A & SetProjectIdAction>,
		initialState: S
	) =>
	(state: S | undefined, action: A & SetProjectIdAction): S => {
		switch (action.type) {
			case ProjectsActionTypes.SET_PROJECT_ID: {
				const {
					payload: { projectId }
				} = action as SetProjectIdAction;

				if (state && state.projectId !== projectId) {
					return {
						...JSON.parse(JSON.stringify(initialState)),
						projectId
					};
				}

				return reducer(state, action);
			}

			default:
				return reducer(state, action);
		}
	};
