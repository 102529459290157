import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { BooleanMap, RequireOnlyOne } from 'types/index';
import { buildBooleanMap } from 'helpers/maps';
import { useDefaultOrganizationId } from 'hooks/store';
import { useMutableState, usePrevious } from 'hooks/utils';
import { CollaboratorsRichData } from 'store/data/collaborators';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';

export interface CollaboratorsTableCheckedState {
	all: boolean;
	partial: {
		main: boolean;
		organizations: BooleanMap;
	};
	one: {
		collaborator: boolean;
		organization: boolean;
	};
	checked: {
		collaborators: string[];
		organizations: string[];
	};
	isSelf: boolean;
	isDefaultOrganization: boolean;
	inTotal: number;
}

interface CheckedMapState {
	collaborators: BooleanMap;
	organizations: BooleanMap;
}

interface Props {
	collaboratorsData: CollaboratorsRichData;
}

export function useCollaboratorsTableCheckedData({ collaboratorsData }: Props) {
	const currentUserQuery = useCurrentUserQuery();

	const { collaboratorIds, organizations, organizationsMap, organizationIds } = collaboratorsData;

	const defaultOrganizationId = useDefaultOrganizationId();

	const initialCheckedMap: CheckedMapState = useMemo(
		() => ({
			collaborators: buildBooleanMap(collaboratorIds, false),
			organizations: buildBooleanMap(organizationIds, false)
		}),
		[collaboratorIds, organizationIds]
	);

	const [checkedMap, setCheckedMap] = useMutableState<CheckedMapState>(initialCheckedMap);

	const checkedState = useMemo(() => getCheckedState(checkedMap), [checkedMap]);

	// SYNC `checkedMap` STATE
	const collaboratorsDataIds = useMemo(
		() => [collaboratorIds, organizationIds],
		[collaboratorIds, organizationIds]
	);
	const prevCollaboratorsDataIds = usePrevious(collaboratorsDataIds);
	useEffect(() => {
		if (isEqual(prevCollaboratorsDataIds, collaboratorsDataIds)) return;

		const checkedCollaborators = collaboratorIds.reduce<BooleanMap>((acc, collaboroatorId) => {
			acc[collaboroatorId] = checkedMap.collaborators[collaboroatorId] ?? false;

			return acc;
		}, {});
		const checkedOrganizations = organizationIds.reduce<BooleanMap>((acc, organizationId) => {
			acc[organizationId] = checkedMap.collaborators[organizationId] ?? false;

			return acc;
		}, {});

		const newCheckedMap: CheckedMapState = {
			collaborators: checkedCollaborators,
			organizations: checkedOrganizations
		};

		const hasChanges = !isEqual(checkedMap, newCheckedMap);

		if (hasChanges) setCheckedMap(newCheckedMap);
	}, [collaboratorsDataIds]);

	/**
	 * Resets all checked values to `false`
	 */
	function resetChecked() {
		setCheckedMap(state => {
			Object.keys(state.collaborators).forEach(
				collaboratorId => (state.collaborators[collaboratorId] = false)
			);
			Object.keys(state.organizations).forEach(
				organizationId => (state.organizations[organizationId] = false)
			);
		});
	}

	/**
	 * Toggles `checked` / `un-checked`
	 *
	 * @param input `collaboratorId` / `organizationId`
	 */
	function toggleChecked(
		input: RequireOnlyOne<{
			collaboratorId?: string;
			organizationId?: string;
		}>
	) {
		const { collaboratorId, organizationId } = input;

		if (collaboratorId) {
			setCheckedMap(state => {
				state.collaborators[collaboratorId] = !state.collaborators[collaboratorId];
			});
		}
		if (organizationId) {
			const organization = organizationsMap[organizationId];

			setCheckedMap(state => {
				const flag = !state.organizations[organizationId];

				state.organizations[organizationId] = flag;

				organization.collaborators.forEach(collaboratorId => {
					state.collaborators[collaboratorId] = flag;
				});
			});
		}
	}

	/**
	 * Toggles all - `checked` / `un-checked`
	 */
	function toggleAllChecked() {
		setCheckedMap(state => {
			const flag = !getCheckedState(state).all;

			Object.keys(state.collaborators).forEach(
				collaboratorId => (state.collaborators[collaboratorId] = flag)
			);
			Object.keys(state.organizations).forEach(
				organizationId => (state.organizations[organizationId] = flag)
			);
		});
	}

	/**
	 * Returns helpful flags to differ between checked states
	 *
	 * @param state `checkedMap` state
	 *
	 * @returns all - all collaborators and organizations checked
	 * @returns partial - at least one collaborator or organization checked
	 * @returns one.collaborator - exactly one collaborator checked
	 * @returns one.organization - exactly one organization checked
	 */
	function getCheckedState(state: CheckedMapState): CollaboratorsTableCheckedState {
		const collaboratorsCheckedValues = Object.values(state.collaborators);
		const organizationsCheckedValues = Object.values(state.organizations);

		const allCollaboratorsChecked = collaboratorsCheckedValues.every(v => v);
		const allOrganizationsChecked = organizationsCheckedValues.every(v => v);
		const someCollaboratorsChecked = collaboratorsCheckedValues.some(v => v);
		const someOrganizationsChecked = organizationsCheckedValues.some(v => v);

		const allChecked = allCollaboratorsChecked && allOrganizationsChecked;
		const partiallyChecked =
			(someCollaboratorsChecked || someOrganizationsChecked) && !allChecked;
		const oneCollaboratorChecked =
			collaboratorsCheckedValues.filter(v => v).length === 1 && !someOrganizationsChecked;
		const oneOrganizationChecked =
			organizationsCheckedValues.filter(v => v).length === 1 && !someCollaboratorsChecked;

		const checkedCollaboratorIds = Object.entries(state.collaborators)
			.filter(([, checked]) => checked)
			.map(([collaboratorId]) => collaboratorId);

		const checkedOrganizationIds = Object.entries(state.organizations)
			.filter(([, checked]) => checked)
			.map(([organizationId]) => organizationId);

		const checkedInTotal = [...checkedCollaboratorIds, ...checkedOrganizationIds].length;

		const partiallyCheckedOrganizations = organizations.reduce<BooleanMap>(
			(acc, organization) => {
				let partial = false;

				organization.collaborators.forEach(collaboratorId => {
					if (checkedCollaboratorIds.includes(collaboratorId)) partial = true;
				});

				acc[organization.id] = partial;

				return acc;
			},
			{}
		);

		const checkedSelf = currentUserQuery.data?.username
			? checkedCollaboratorIds.includes(currentUserQuery.data.username)
			: false;
		const checkedDefaultOrganization = defaultOrganizationId
			? checkedOrganizationIds.includes(defaultOrganizationId)
			: false;

		return {
			all: allChecked,
			partial: {
				main: partiallyChecked,
				organizations: partiallyCheckedOrganizations
			},
			one: {
				collaborator: oneCollaboratorChecked,
				organization: oneOrganizationChecked
			},
			checked: {
				collaborators: checkedCollaboratorIds,
				organizations: checkedOrganizationIds
			},
			isSelf: checkedSelf,
			isDefaultOrganization: checkedDefaultOrganization,
			inTotal: checkedInTotal
		};
	}

	return {
		checkedMap,
		checkedState,
		resetChecked,
		toggleChecked,
		toggleAllChecked
	};
}
