import { Layout } from '../Layout';
import { Loader } from 'components/UI/Loader';
import { useEffect, useState } from 'react';
import { getCurrentUser, signInWithRedirect } from '@aws-amplify/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { Button } from 'features/entry-form-v2/component/Button';
import { useSelector } from 'hooks/utils';
import { getErrorMessage, isSSOProvider } from './utils';
import { z } from 'zod';

export function FederatedLoginPage() {
	const navigate = useNavigate();

	const lang = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[lang];

	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();

	const ssoProvider = searchParams.get('sso');
	const state = searchParams.get('state');

	const callbackError = searchParams.get('error');
	const callbackErrorDescription = searchParams.get('error_description');

	useEffect(() => {
		const getUser = async () => {
			setLoading(true);

			try {
				const user = await getCurrentUser();
				if (user) {
					navigate(ROUTE_MAP.projects.path);
				}
			} catch {
				if (ssoProvider) {
					/* Log in through provider in query params */
					signInWithRedirect({
						provider: { custom: mapProviderName(ssoProvider) },
						customState: JSON.stringify({ provider: ssoProvider })
					});
					return;
				}

				if (callbackError) {
					setLoading(false);
				}
			} finally {
				setLoading(false);
			}
		};

		getUser();
	}, []);

	const getParsedState = () => {
		if (!state) {
			return null;
		}

		const [, hexState] = state.split('-');
		return parseHexState(hexState);
	};

	const retryLogin = () => {
		const parsedState = getParsedState();
		if (parsedState) {
			signInWithRedirect({
				provider: { custom: mapProviderName(parsedState.provider) },
				customState: JSON.stringify({ provider: parsedState.provider })
			});
		}
	};

	const getSSOErrorMessage = () => {
		const errorCode = callbackErrorDescription?.split('PreSignUp failed with error ')[1];

		const trimmedErrorCode = errorCode?.endsWith('. ') // Aws lambda appends '. ' to the error code in the URL
			? errorCode?.substring(0, errorCode.length - 2)
			: errorCode;

		if (!trimmedErrorCode) {
			return null;
		}

		const parsedState = getParsedState();
		if (!parsedState) {
			return null;
		}

		const ssoProvider = parsedState.provider;
		if (!isSSOProvider(ssoProvider)) {
			return null;
		}

		const errorMessage = getErrorMessage(ssoProvider, trimmedErrorCode, lang);
		return errorMessage ?? translation.genericErrorMessage;
	};

	const ssoSpecificErrorMessage = getSSOErrorMessage();

	return (
		<Layout>
			{loading && <Loader />}
			{!loading && callbackError && (
				<div className="text-center">
					<div className="mx-auto max-w-2xl rounded-md bg-red-50 p-4 mb-8">
						<div className="flex items-start">
							<div className="flex-shrink-0 mt-0.5">
								<svg
									className="h-5 w-5 text-red-400"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
							<div className="ml-3 text-left">
								<h3 className="text-sm font-medium text-red-800">
									{translation.authenticationError}
								</h3>
								<div className="mt-2 text-sm text-red-700">
									{ssoSpecificErrorMessage ? (
										<>
											<p className="mb-4">
												{translation.ERROR_ALERT.ssoErrorDescription}
											</p>
											<li>{ssoSpecificErrorMessage}</li>
										</>
									) : (
										<p>{translation.genericErrorMessage}</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="mt-10 flex items-center justify-center gap-x-6">
						<Button
							onClick={retryLogin}
							title={translation.tryAgain}
							variant="primary"
						/>
						<a
							href="mailto:support@ledidi.no"
							className="text-sm font-semibold text-gray-900"
						>
							{translation.contactSupport} <span aria-hidden="true">&rarr;</span>
						</a>
					</div>
				</div>
			)}
		</Layout>
	);
}

function parseHexState(hexState: string) {
	// Convert hex string to bytes array
	const bytes = new Uint8Array(hexState.match(/.{1,2}/g)?.map(byte => parseInt(byte, 16)) ?? []);
	// Convert bytes to string using TextDecoder
	const decodedState = new TextDecoder().decode(bytes);
	const parsedState = ParsedState.safeParse(JSON.parse(decodedState));

	if (parsedState.success) {
		return parsedState.data;
	}

	return null;
}

const ParsedState = z.object({ provider: z.string() });

const DICTIONARY = {
	EN: {
		authenticationError: 'Authentication Error',
		genericErrorMessage:
			'Something went wrong while logging in. Please try again or contact customer support if the issue persists.',
		tryAgain: 'Try again',
		contactSupport: 'Contact support',
		ERROR_ALERT: {
			title: 'Authentication Error',
			genericErrorMessage:
				'Something went wrong while logging in. Please try again or contact customer support if the issue persists.',
			ssoErrorDescription: 'Please address the following issues and try again.'
		}
	},
	NB: {
		authenticationError: 'Autentiseringsfeil',
		genericErrorMessage:
			'Noe gikk galt under innloggingen. Vennligst prøv igjen eller kontakt kundeservice hvis problemet vedvarer.',
		tryAgain: 'Prøv igjen',
		contactSupport: 'Kontakt support',
		ERROR_ALERT: {
			title: 'Autentiseringsfeil',
			genericErrorMessage:
				'Noe gikk galt under innloggingen. Vennligst prøv igjen eller kontakt kundeservice hvis problemet vedvarer.',
			ssoErrorDescription: 'Vennligst adresser følgende problemer og prøv igjen.'
		}
	}
} as const;

function mapProviderName(provider: string) {
	switch (provider) {
		case 'sykehuspartner':
			return 'Sykehuspartner';
		case 'cmr':
			return 'CMR';
		case 'cornell':
			return 'cornell.edu';
		case 'ledidissotest':
			return 'LedidiSSOtest';
	}

	console.warn(`Unknown provider: ${provider}`);
	return provider;
}
