import { useCallback, useMemo } from 'react';
import {
	type Entries as EntriesType,
	selectEntriesTableParams,
	setEntriesTableParams
} from 'store/data/entries';
import { EntriesTable } from './EntriesTable';
import { useVariablesPageModalsController } from 'pages/Variables/useVariablesPageModalsController';
import { cloneDeep } from 'lodash';
import { getEntriesRows } from 'helpers/entries';
import { useNavigation } from 'hooks/navigation';
import {
	useProjectId,
	useEntryId,
	useEntries,
	useStatuses,
	useEntriesSearchTerm,
	useEntriesErrors,
	useVariablesData,
	useVariablesTableCheckedData,
	useTransferEntriesOwnershipContext,
	useUsername,
	useFilters,
	useIsEntryPageFetched,
	usePermissions
} from 'hooks/store';
import {
	useDeepCompareEffect,
	useDeepCompareMemo,
	useDeepCompareMemoize,
	useDispatch,
	usePrevious,
	useSelector
} from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { TableSort } from 'store/ui/tables/types';

import { formatScientificNumberEntryValue } from 'store/data/entries/parsers';
import { useShouldUseV1_5Routing } from 'features/entry-form-v2/utils/isV1_5project';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';

interface Props {
	transferOwnershipUsage?: boolean;
	presetEntries?: EntriesType;
	activeSort?: TableSort;
	filterDrawerContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
	handleSort: (columnName: string) => void;
}

function EntriesMemoized({
	presetEntries,
	activeSort,
	filterDrawerContainerRef,
	handleSort
}: Props) {
	const dispatch = useDispatch();
	const { navigate, routes } = useNavigation();

	const [projectId] = useProjectId();
	const [, setEntryId] = useEntryId();
	const { hasOwnershipAllAccess } = usePermissions();

	const [{ data: entries, loading: loadingEntries }, getEntriesPage] = useEntries({
		lazy: true,
		paginated: true,
		filtered: true
	});

	const [{ filters }] = useFilters();

	const [
		{
			data: { statusesMap }
		}
	] = useStatuses({ lazy: true });
	const [searchTerm] = useEntriesSearchTerm();

	const { pageSize, pageIndex, totalCount } = useSelector(state =>
		//@ts-ignore
		selectEntriesTableParams(state.data.entries)
	);

	const isPageFetched = useIsEntryPageFetched(pageIndex) && !presetEntries;

	const [{ errors, filter }] = useEntriesErrors();

	const variablesData = useVariablesData({ initial: true });

	const shouldUseV1_5Routing = useShouldUseV1_5Routing();

	const {
		actions: {
			getIsTransferOwnership,
			getSelectedEntries,
			addSelectedEntry,
			removeSelectedEntry,
			toggleSelectedEntryConfirmed,
			getPreviewModal
		}
	} = useTransferEntriesOwnershipContext();
	const transferOwnershipPreviewModal = getPreviewModal();

	const rows = useMemo(() => {
		return getEntriesRows({
			entries: presetEntries ?? entries,
			...(transferOwnershipPreviewModal && { filters }),
			...(transferOwnershipPreviewModal && { searchTerm }),
			statusesMap,
			variablesMap: variablesData.variablesMap,
			errors: {
				data: errors,
				filter
			}
		});
	}, [
		useDeepCompareMemoize(entries),
		filters,
		searchTerm,
		useDeepCompareMemoize(statusesMap),
		variablesData.variablesMap,
		errors,
		filter,
		hasOwnershipAllAccess,
		transferOwnershipPreviewModal
	]);

	// TABLE VIEW STATES
	const { checkedState } = useVariablesTableCheckedData();

	const {
		modals: { variableModal },
		modalsComponent
	} = useVariablesPageModalsController({ checkedState });

	const username = useUsername();
	// const isInEntriesTransferMode = getIsTransferOwnership() && !getPreviewModal() && username;
	const isInEntriesTransferMode = getIsTransferOwnership() && username;

	function handleTransferOwnershipRowClick(entryId: string) {
		if (getSelectedEntries().some(selectedEntry => selectedEntry.entryId === entryId)) {
			removeSelectedEntry(entryId);
		} else {
			addSelectedEntry(entryId);
		}
	}

	function handleTransferOwnershipPreviewRowClick(entryId: string) {
		toggleSelectedEntryConfirmed(entryId);
	}

	const handleRowClick = (entryId: string, newTab?: boolean) => {
		const isTransferOwnership = getIsTransferOwnership();
		const previewModal = getPreviewModal();

		if (isTransferOwnership) {
			if (previewModal) {
				handleTransferOwnershipPreviewRowClick(entryId);
			} else {
				handleTransferOwnershipRowClick(entryId);
			}
			return;
		}

		if (!projectId) {
			console.error('Project ID is missing');
			return;
		}

		let url = routes.projects.dataset.update(projectId, entryId);
		// Override url if v1.5 is enabled and we support all features the project requires in v1.5
		if (shouldUseV1_5Routing) {
			url = ROUTE_MAP.projects.byId.dataset.update.createPath({
				projectId,
				entryId,
				formId: null
			});
		}

		if (newTab) {
			window.open(routes.projects.dataset.update(projectId, entryId));
			return;
		}

		setEntryId(entryId);
		navigate(url);
	};

	const handleOpenSeriesTableFromEntries = useCallback(
		(args: { entryId: string; seriesName: string }) => {
			if (projectId) {
				if (shouldUseV1_5Routing) {
					navigate(
						ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.createPath({
							projectId,
							entryId: args.entryId,
							seriesName: args.seriesName
						})
					);
				} else {
					navigate(routes.projects.dataset.update(projectId, args.entryId));
				}
			}
		},
		[projectId, routes, shouldUseV1_5Routing]
	);

	function getComputedEntriesData() {
		if (getIsTransferOwnership() && getPreviewModal()) {
			return rows.filter(row =>
				getSelectedEntries().some(
					selectedEntry => selectedEntry.entryId === row.datasetentryid
				)
			);
		}

		return rows;
	}

	// RESET PAGE METADATA ON PREVIEW;
	useDeepCompareEffect(() => {
		if (presetEntries) {
			dispatch(
				setEntriesTableParams({
					pageIndex: 0,
					pageSize
				})
			);
		}
	}, [presetEntries, totalCount]);

	// PAGINATION GET EFFECT;

	useDeepCompareEffect(() => {
		// in preview modal we don't do any fetching!
		if (isPageFetched || presetEntries || transferOwnershipPreviewModal) return;
		const filterByOwner = isInEntriesTransferMode && !hasOwnershipAllAccess;
		getEntriesPage(filterByOwner ? { username } : undefined);
	}, [
		pageIndex,
		pageSize,
		isPageFetched,
		isInEntriesTransferMode,
		presetEntries,
		transferOwnershipPreviewModal
	]);

	// INITIAL OWNERSHIP RESET TABLE PARAMS EFFECT (user could be on 3rd page and there might not be a 3rd page of user owned entries)
	const prevIsInEntriesTransferMode = usePrevious(isInEntriesTransferMode);
	useDeepCompareEffect(() => {
		if (!prevIsInEntriesTransferMode && isInEntriesTransferMode) {
			dispatch(setEntriesTableParams({ pageIndex: 0, pageSize }));
		}
		if (transferOwnershipPreviewModal) return;
	}, [prevIsInEntriesTransferMode, isInEntriesTransferMode, transferOwnershipPreviewModal]);

	useDeepCompareEffect(() => {
		// in preview modal we don't do any fetching!
		if (presetEntries || transferOwnershipPreviewModal || hasOwnershipAllAccess) return;
		getEntriesPage(isInEntriesTransferMode ? { username } : undefined);
	}, [
		isInEntriesTransferMode,
		presetEntries,
		transferOwnershipPreviewModal,
		hasOwnershipAllAccess
	]);

	const entriesData = getComputedEntriesData();
	const processedEntries = useDeepCompareMemo(() => {
		const processedEntries: EntriesType = cloneDeep(entriesData).map(data => {
			return Object.keys(data).reduce((acc, key) => {
				const value = Number(data[key]);
				const isInteger = value % 1 === 0;
				const isScientific = String(acc[key]).includes('e');
				const hasVisiblePrecision =
					variablesData.variablesMap[key] &&
					variablesData.variablesMap[key].visiblePrecision;
				const decimalsLength = value.toString().split('.')[1]?.length || 0;

				// If the value is not a number, null, or is an integer not in scientific notation, skip processing
				if (isNaN(value) || data[key] === null || (isInteger && !isScientific)) {
					return acc;
				}

				if (hasVisiblePrecision) {
					const precision = Math.min(
						decimalsLength,
						Number(variablesData.variablesMap[key].visiblePrecision)
					);

					if (precision) {
						if (!isScientific) {
							const factor = 10 ** precision;
							const result = Math.round(value * factor) / factor;
							const parts = result.toString().split('.');
							acc[key] = [parts[0], parts[1]?.substring(0, precision) || ''].join(
								'.'
							);
						} else {
							acc[key] = formatScientificNumberEntryValue(value, precision);
						}
					} else {
						acc[key] = !isScientific ? value.toFixed().toString() : acc[key];
					}
				} else {
					if (!isScientific) {
						const twoDecimalsResult = Math.round(value * 100) / 100;
						acc[key] = twoDecimalsResult.toString();
					} else {
						acc[key] = formatScientificNumberEntryValue(value, 2);
					}
				}

				return acc;
			}, data);
		});
		return processedEntries;
	}, [variablesData.variablesMap, entriesData]);

	return (
		<>
			<EntriesTable
				filterDrawerContainerRef={filterDrawerContainerRef}
				activeSort={activeSort}
				handleSort={handleSort}
				entries={processedEntries}
				onRowClick={handleRowClick}
				onHeaderVariableClick={variableModal.open}
				handleSeriesClick={handleOpenSeriesTableFromEntries}
				loading={loadingEntries}
			/>

			{/* MODALS */}
			{modalsComponent}
		</>
	);
}

export const Entries = withMemo(EntriesMemoized);
