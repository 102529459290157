import { Layout, LoginBackLinkStyle } from './Layout';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'features/entry-form-v2/component/Input';
import { useSelector } from 'hooks/utils';
import { confirmSignIn } from '@aws-amplify/auth';
import { isAuthStep, useNavigateToNextStep } from './useNavigateToNextStep';
import { Button } from 'features/entry-form-v2/component/Button';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { isInvalidPasswordException } from './amplify/amplify-errors';
import { isSignInException } from './amplify/amplify-errors';
export function NewPasswordRequiredPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];
	const navigateToNextStep = useNavigateToNextStep();
	const navigate = useNavigate();

	const form = useForm({
		defaultValues: {
			password: '',
			confirmPassword: ''
		},
		resolver: zodResolver(createSchema(translation))
	});

	const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

	const onSubmit = async (data: LoginFormData) => {
		try {
			setIsUpdatingPassword(true);

			const result = await confirmSignIn({
				challengeResponse: data.password
			});

			if (!isAuthStep(result.nextStep.signInStep)) {
				console.error(new Error('unhandled step'), {
					step: result.nextStep.signInStep
				});

				form.setError('confirmPassword', { message: translation.errors.unknown });
				return;
			}

			navigateToNextStep({ step: result.nextStep.signInStep });
		} catch (error) {
			if (isSignInException(error)) {
				console.error(error);
				toast.error(translation.errors.unknown, { position: 'top-center' });
				navigate(ROUTE_MAP.auth.login.path);
				return;
			}

			if (isInvalidPasswordException(error)) {
				form.setError('password', { message: translation.errors.unknown });
				console.error(
					'Invalid new password was set. This should have been caught by the schema. Check the schema and password policy.',
					error
				);
				return;
			}

			console.error(error);
			form.setError('password', { message: translation.errors.unknown });
		} finally {
			setIsUpdatingPassword(false);
		}
	};

	return (
		<Layout title={translation.title}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
				<Input
					className="mb-6"
					label={translation.passwordInput.label}
					placeholder={translation.passwordInput.placeholder}
					id={'password'}
					type="password"
					autoComplete="new-password"
					error={form.formState.errors.password?.message}
					{...form.register('password')}
				/>
				<Input
					className="mb-6"
					label={translation.confirmPasswordInput.label}
					placeholder={translation.confirmPasswordInput.placeholder}
					id={'confirmPassword'}
					type="password"
					autoComplete="new-password"
					error={form.formState.errors.confirmPassword?.message}
					{...form.register('confirmPassword')}
				/>

				<Button
					title={translation.updatePasswordButtonText}
					className="mb-6"
					loading={isUpdatingPassword}
				/>

				<Link to={ROUTE_MAP.auth.login.path} className={LoginBackLinkStyle}>
					{translation.backToLogin}
				</Link>
			</form>
		</Layout>
	);
}

const createSchema = (t: Dictionary) =>
	z
		.object({
			password: z
				.string()
				.min(16, { message: t.passwordInput.validation.required })
				.regex(/[a-z]/, { message: t.passwordInput.validation.missingLowerCase })
				.regex(/[A-Z]/, { message: t.passwordInput.validation.missingUpperCase })
				.regex(/[0-9]/, { message: t.passwordInput.validation.missingNumber }),
			confirmPassword: z
				.string()
				.min(1, { message: t.confirmPasswordInput.validation.required })
		})
		.refine(data => data.password === data.confirmPassword, {
			message: t.confirmPasswordInput.validation.passwordsMustMatch,
			path: ['confirmPassword']
		});

type LoginFormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		title: 'New password',

		passwordInput: {
			label: 'New password',
			placeholder: 'New password',
			validation: {
				required: 'New password must be at least 16 characters long',
				missingLowerCase: 'At least a lowercase character',
				missingUpperCase: 'At least an uppercase character',
				missingNumber: 'At least a digit'
			}
		},
		confirmPasswordInput: {
			label: 'Confirm new password',
			placeholder: 'Confirm new password',
			validation: {
				required: 'Confirm new password is required',
				passwordsMustMatch: 'Passwords must match'
			}
		},

		updatePasswordButtonText: 'Update password',
		backToLogin: 'Back to login',
		errors: {
			unknown: 'An error occured while updating password'
		}
	},
	NB: {
		title: 'Nytt passord',

		passwordInput: {
			label: 'Nytt passord',
			placeholder: 'Skriv inn nytt passord',
			validation: {
				required: 'Nytt passord er påkrevd',
				missingLowerCase: 'Minst én liten bokstav',
				missingUpperCase: 'Minst én stor bokstav',
				missingNumber: 'Minst ett tall'
			}
		},

		confirmPasswordInput: {
			label: 'Bekreft nytt passord',
			placeholder: 'Bekreft nytt passord',
			validation: {
				required: 'Bekreft nytt passord er påkrevd',
				passwordsMustMatch: 'Passordene stemmer ikke'
			}
		},

		updatePasswordButtonText: 'Oppdater passord',
		backToLogin: 'Tilbake til innlogging',

		errors: {
			unknown: 'En feil oppsto under oppdatering av passord'
		}
	}
} as const;
type Dictionary = (typeof DICTIONARY)[keyof typeof DICTIONARY];
