import { fetchAuthSession } from '@aws-amplify/auth';

const SERVICE_URLS_BY_SERVICE = {
	cde: process.env.REACT_APP_API_URL_DATAENTRY as string,
	projects: process.env.REACT_APP_API_URL_PROJECT as string,
	stats: process.env.REACT_APP_API_URL_STATS as string,
	user: process.env.REACT_APP_API_URL_USER as string
};

type Service = keyof typeof SERVICE_URLS_BY_SERVICE;

export class LedidiRequestError extends Error {
	errors: {
		code: string;
	}[];
	service: string;
	method: string;
	traceId?: string;

	constructor({
		service,
		method,
		errors,
		traceId
	}: {
		service: string;
		method: string;
		errors: { code: string }[];
		traceId?: string;
	}) {
		super(`LedidiRequestError: ${service} ${method}`);
		this.errors = errors;
		this.service = service;
		this.method = method;
		this.traceId = traceId;
	}
}

export const makeRequest = async <T>({
	data,
	method,
	service
}: {
	data?: any;
	method: string;
	service: Service;
}): Promise<T> => {
	const accessTokenStr = (await fetchAuthSession()).tokens?.idToken?.toString();

	const result = await fetch(SERVICE_URLS_BY_SERVICE[service], {
		method: 'POST',
		body: JSON.stringify({
			method,
			accessTokenStr,
			...data
		}),
		headers: {
			'Content-Type': 'application/json'
		}
	});

	if (!result.ok) {
		throw new LedidiRequestError({
			service,
			method,
			errors: [{ code: `error.httpError.${result.status}` }]
		});
	}

	const json: T & {
		statusCode: string;
		httpStatusCode: number;
		ledidiStatusCode: string;
		message?: string;
		errors?: {
			code: string;
		}[];
		traceId?: string;
	} = await result.json();

	const statusCode = Number(json?.statusCode) ? Number(json.statusCode) : json?.httpStatusCode;
	if (statusCode < 200 || statusCode >= 300) {
		// If the error code is not already in the errors array, add it so we can handle errors in a consistent way later
		if (!json.errors?.find(e => e.code === json.ledidiStatusCode)) {
			json.errors = json.errors ?? [];
			json.errors.push({
				code: json.ledidiStatusCode
			});
		}
	}
	if (json.errors) {
		throw new LedidiRequestError({
			service,
			method,
			errors: json.errors,
			traceId: json.traceId
		});
	}

	return json;
};
