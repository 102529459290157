import { Navigate, useLocation, Outlet } from 'react-router-dom';
import ErrorBoundary from 'app/ErrorBoundary';
import { ROUTES } from 'types/navigation';
import { Loader } from 'components/UI/Loader';
import {
	useAccount,
	useSubscription,
	useWithProject,
	usePermissionAccess,
	useSubscriptionRules
} from 'hooks/store';
import { useEffect } from 'react';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { fetchAuthSession } from 'aws-amplify/auth';
import { configureSentryUser } from 'sentry';
import { identify } from 'app/tracking/TrackingProvider';
import { LastPaymentWarning } from 'components/Account/LastPaymentWarning/LastPaymentWarning';
import { LicenceLimitationModal } from 'components/Account/Subscription/Modals/LicenceLimitationModal';
import { AccountUMProvider } from 'components/Providers';

export function WithSubscriptionRules() {
	const [{ fetched: isAccountFetched }] = useAccount();
	const [
		{
			data: {
				subscriptionTypes: { isLedidiEnterprise },
				userTypesAndRoles: { isSubscriptionOwner }
			},
			fetched: isSubscriptionFetched
		}
	] = useSubscription();

	const { showEnterpriseAdminRoute } = useSubscriptionRules();

	if (!isAccountFetched) return <Loader primary />;

	if (isSubscriptionFetched && (!isSubscriptionOwner || isLedidiEnterprise)) {
		if (showEnterpriseAdminRoute) {
			<Navigate to={ROUTES.EnterpriseAdminSubscription} replace />;
		} else return <Navigate to={ROUTES.AccountUMSubscription} replace />;
	}

	// This never happens ???
	return <Outlet />;
}

export function WithLoggedInComponent() {
	useWithProject();
	usePermissionAccess();

	useSegmentIdentification();
	useSentryIdentification();

	const { pathname } = useLocation();

	return (
		<ErrorBoundary pathname={pathname}>
			<LastPaymentWarning />
			<LicenceLimitationModal />
			<AccountUMProvider />

			<Outlet />
		</ErrorBoundary>
	);
}

const useSentryIdentification = () => {
	const currentUserQuery = useCurrentUserQuery();

	useEffect(() => {
		async function asyncShit() {
			try {
				const session = await fetchAuthSession();
				const sub = session.tokens?.idToken?.payload.sub;
				if (!sub) {
					throw new Error('No sub found');
				}

				configureSentryUser({ sub });
			} catch (error) {
				console.error(
					'loggedIn true but cannot get current user from Amplify. This is not supposed to happen'
				);
			}
		}

		if (currentUserQuery.data) {
			asyncShit();
		}
	}, [currentUserQuery.data]);
};

const useSegmentIdentification = () => {
	const [
		{
			data: { details: userDetails }
		}
	] = useSubscription();

	useEffect(() => {
		async function asyncShit() {
			try {
				const session = await fetchAuthSession();
				const sub = session.tokens?.idToken?.payload.sub;

				if (!sub) {
					throw new Error('No sub found');
				}

				const subscriptionId = userDetails?.subscriptionId || '';
				identify({
					userId: sub,
					trait: { subscriptionId: subscriptionId }
				});
			} catch (error) {
				console.error(
					'loggedIn true but cannot get current user from Amplify. This is not supposed to happen'
				);
			}
		}

		if (userDetails) {
			asyncShit();
		}
	}, [userDetails]);
};
