import { fetchMFAPreference, FetchMFAPreferenceOutput } from 'aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';

export type MfaStatus = FetchMFAPreferenceOutput;

export const useMfaPreferenceQuery = () => {
	return useQuery({
		queryKey: ['mfaPreference'],
		queryFn: fetchMFAPreference
	});
};
