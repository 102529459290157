import { StrictMode, useEffect, useState, useRef, forwardRef } from 'react';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from '../component/Button';
import { Menu } from '@headlessui/react';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { ROUTE_MAP } from '../utils/routeMap';
import { ErrorModal } from '../ErrorModal';
import { EntryHistoryModal } from '../smart-components/EntryHistoryModal';
import { Entry, Variable } from '../types';
import { EntryDetailsModal } from '../smart-components/EntryDetailsModal';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { EntryHeader } from '../component/EntryHeader';
import { Skeleton } from '../component/Skeleton';
import { ConfirmDiffModal } from '../confirm-diff-modal/ConfirmDiffModal';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';
import { useGetLatestDataEntryVersionQuery } from '../data/useGetLatestDataEntryVersionQuery';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useUpdateDataEntryMutation } from '../data/useUpdateDataEntryMutation';
import { createSubmitEvent, EntryForm, NextAction } from '../EntryForm';
import { SelectForm, useFormSelector } from '../smart-components/SelectForm';
import { DiffedField } from '../utils/formUtils';
import { parseBackendValues } from '../utils/parse-backend-values/parseBackendValues';
import { DeleteEntryModal } from '../smart-components/DeleteEntryModal';
import { SplitButton } from '../component/SplitButton';
import { SelectStatusButton } from '../smart-components/SelectStatusButton';
import { MoreIcon } from '@icons';
import { useEntries } from 'hooks/store/data/entries/useEntries';
import { V1_5_ChangelogDialog } from '../changelog/ChangelogDialog';

export const UpdateEntryPageV1_5 = () => {
	const { track } = useTracking();

	const navigate = useNavigate();

	/**
	 * This is used to allow the user to leave the page without the diff modal.
	 * Used to override the behavior of usePrompt in EntryForm
	 */
	const allowLeavePageOverrideRef = useRef<boolean>(false);

	const routeParams = useParams();
	const entryId = routeParams.entryId as string;
	const projectId = routeParams.projectId as string;

	const [_, fetchEntries] = useEntries({ lazy: true });

	const {
		formId,
		shouldShowFormSelector: showFormSelector,
		toggleShowFormSelector,
		setSelectedFormId,
		getFormsQueryIsLoading,
		defaultFormId
	} = useFormSelector({
		projectId
	});

	const projectDataQuery = useProjectData({
		projectId,
		selectedFormId: formId
	});

	const updateDataEntryMutation = useUpdateDataEntryMutation();

	const [dataPendingSubmission, setDataPendingSubmission] = useState<DataPendingSubmission>();

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	useEffect(() => {
		// Make sure the url matches the latest entry id so updates are most likely to work
		if (dataEntryQuery.data && dataEntryQuery.data.entry.datasetentryid !== entryId) {
			navigate(
				ROUTE_MAP.projects.byId.dataset.update.createPath({
					projectId,
					entryId: dataEntryQuery.data.entry.datasetentryid,

					// defaultFormId is needed because of a race condition where this is called without formId before the search params are updated in useFormSelector
					formId: formId || defaultFormId
				}),
				{ replace: true }
			);
		}
	}, [dataEntryQuery.data]);

	const loading =
		projectDataQuery.isLoading || dataEntryQuery.isLoading || getFormsQueryIsLoading;
	const error = projectDataQuery.error || dataEntryQuery.error;

	const { scrollToLastVisibleElement } = useLastVisibleElement({
		selector: '[data-scroll-anchor]',
		storageKey: `last-visible-element_projectId:${projectId}_entryId:${entryId}_formId:${formId}`,
		loading
	});

	useEffect(() => {
		if (!loading) {
			scrollToLastVisibleElement();
		}
	}, [loading]);

	const submitEntry = async (args: { entry: Entry; action: NextAction }) => {
		if (!projectDataQuery.data) {
			console.error(
				"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
			);
			return;
		}

		try {
			const result = await updateDataEntryMutation.mutate({
				projectId,
				entryId,
				entry: args.entry
			});

			fetchEntries();

			allowLeavePageOverrideRef.current = true;

			if (args.action.type === 'saveAndNavigate') {
				navigate(args.action.nextNavLocation, { replace: true });
				return;
			}
			if (args.action.type === 'saveAndClose') {
				navigate(ROUTE_MAP.projects.byId.dataset.createPath({ projectId }), {
					replace: true
				});
				return;
			}

			navigate(
				ROUTE_MAP.projects.byId.dataset.update.createPath({
					projectId,
					entryId: result.updatedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);

			await dataEntryQuery.refetch();
		} catch (_e) {
			// Error is handled in the ErrorModal
		} finally {
			track({
				eventName: 'entry_updated',
				data: {
					entryHasStatus: false,
					action: args.action
				}
			});

			setDataPendingSubmission(undefined);

			allowLeavePageOverrideRef.current = false;
		}
	};

	if (error) {
		return (
			<ErrorLoadingEntry
				isFetching={projectDataQuery.isFetching}
				refetch={projectDataQuery.refetch}
			/>
		);
	}

	return (
		<StrictMode>
			<V1_5_ChangelogDialog />

			{/** Same height as the header */}
			<div className="h-[60px]" />

			<div className="flex flex-col mt-20">
				{!loading && (
					<SelectForm
						expanded={showFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
					/>
				)}
				<div
					className="flex flex-col lg:w-[756px] lg:mx-auto px-4 md:px-10"
					data-testid="update-entry-page"
				>
					<ErrorModal
						onClose={updateDataEntryMutation.reset}
						error={updateDataEntryMutation.error}
					/>

					{loading && <EntryFormSkeleton />}

					{
						// this loading seems redundant, but because of the quirk with formsQuery.isFetchedAfterMount, it caused the page both show skeleton and the data.
						// Putting this here prevents such inconsistencies
						!loading && dataEntryQuery.data && projectDataQuery.data && (
							<>
								<SubmitDiffModal
									dataPendingSubmission={dataPendingSubmission}
									initialEntry={dataEntryQuery.data.entry}
									onClose={() => setDataPendingSubmission(undefined)}
									onConfirm={submitEntry}
									onDelete={() =>
										navigate(
											ROUTE_MAP.projects.byId.dataset.createPath({
												projectId
											})
										)
									}
									submitting={updateDataEntryMutation.loading}
									variables={projectDataQuery.data.variables}
								/>

								<EntryForm
									key={dataEntryQuery.data?.entry?.datasetentryid + formId}
									allowLeavePageOverrideRef={allowLeavePageOverrideRef}
									Header={
										<Header
											entryIsSubmitting={updateDataEntryMutation.loading}
											formSelectorIsShowing={showFormSelector}
											onShowFormSelectorClicked={toggleShowFormSelector}
											onEntryDeleted={() => {
												allowLeavePageOverrideRef.current = true;

												navigate(
													ROUTE_MAP.projects.byId.dataset.createPath({
														projectId
													})
												);
											}}
										/>
									}
									projectData={projectDataQuery.data}
									initialEntry={dataEntryQuery.data.entry}
									submitting={updateDataEntryMutation.loading}
									onSubmitForConfirmation={async ({ nextAction, dirtyData }) => {
										setDataPendingSubmission({
											entry: dirtyData,
											actionType: nextAction.type
										});
									}}
									onSubmitForSubmission={async ({ nextAction, dirtyData }) => {
										await submitEntry({
											entry: dirtyData,
											action: nextAction
										});
									}}
								/>
							</>
						)
					}
				</div>
			</div>
		</StrictMode>
	);
};

const Header = ({
	entryIsSubmitting,
	onShowFormSelectorClicked,
	formSelectorIsShowing,
	onEntryDeleted
}: {
	entryIsSubmitting: boolean;
	onShowFormSelectorClicked: () => void;
	formSelectorIsShowing: boolean;
	onEntryDeleted: () => void;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	const { formId } = useFormSelector({
		projectId
	});

	const getProjectQuery = useGetProjectQuery({ projectId });

	const getFormsQuery = useGetFormsQuery({
		projectId
	});

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	return (
		<>
			<EntryHeader
				title="Update Entry"
				breadCrumbs={[
					{
						title: getProjectQuery.data?.project.projectName,
						url: ROUTE_MAP.projects.byId.dataset.createPath({
							projectId
						})
					}
				]}
			>
				<div className="flex gap-4 items-center">
					<Link
						to={ROUTE_MAP.projects.byId.dataset.create.createPath({
							projectId,
							formId
						})}
					>
						<Button variant="secondary" title="Create New Entry" type="button" />
					</Link>

					{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
						<button
							onClick={onShowFormSelectorClicked}
							className="self-end"
							type="button"
						>
							<Icon
								svg={Svgs.FileText}
								active={formSelectorIsShowing}
								propagate
								variant={v => v.button}
							/>
						</button>
					)}

					<SelectStatusButton />

					<OptionsMenu
						onEntryDeleted={onEntryDeleted}
						entryId={entryId}
						projectId={projectId}
						hasDeleteAccess={!!dataEntryQuery.data?.entryAccess.delete}
						handlePrint={() =>
							window.open(
								ROUTE_MAP.projects.byId.dataset.update.print.createPath({
									projectId,
									entryId,
									formId
								}),
								'_blank'
							)
						}
					/>

					{dataEntryQuery.data?.entryAccess.write ? (
						<div className="flex gap-[1px]">
							<Button
								loading={entryIsSubmitting}
								className="col-start-4 col-span-2 rounded-r-none"
								title="Save"
								variant="primary"
								data-testid="entry-form-header_save-button"
							/>

							<SplitButton className="rounded-l-none">
								<SplitButton.Item
									title="Save and close"
									data-action="saveAndClose"
									onClick={e => {
										const target = e.target as HTMLElement;
										const form = target.closest('form');

										const submitEvent = createSubmitEvent(target);

										form?.dispatchEvent(submitEvent);
									}}
								/>
							</SplitButton>
						</div>
					) : (
						<ViewOnly />
					)}

					<Link to={ROUTE_MAP.projects.byId.dataset.createPath({ projectId })}>
						<Icon
							svg={Svgs.Close}
							variant={v => v.buttonActive}
							dataTestId="entry-form-header_close-icon"
							propagate
						/>
					</Link>
				</div>
			</EntryHeader>
		</>
	);
};

type OptionsMenuButtonProps = {
	children: React.ReactNode;
	onClick: () => void;
	ariaLabel: string;
};
export const OptionsMenuButton = forwardRef<HTMLButtonElement, OptionsMenuButtonProps>(
	({ children, onClick, ariaLabel }, ref) => {
		return (
			<button
				ref={ref}
				className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
				onClick={onClick}
				aria-label={ariaLabel}
			>
				{children}
			</button>
		);
	}
);

const ViewOnly = () => (
	<div className="border-gray-700 rounded-md border px-4 py-2 group hover:cursor-help relative">
		<p>View only</p>

		<p className="absolute right-0 z-50 w-80 top-full mt-2 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 shadow-lg">
			You can only view this entry due to read-only rights
		</p>
	</div>
);

export const OptionsMenu = ({
	entryId,
	projectId,
	hasDeleteAccess,
	handlePrint,
	onEntryDeleted
}: {
	entryId: string;
	projectId: string;
	hasDeleteAccess: boolean;
	handlePrint: () => void;
	onEntryDeleted: () => void;
}) => {
	const { track } = useTracking();

	const [showDeleteEntryModal, setShowDeleteEntryModal] = useState(false);
	const [showEntryHistoryModal, setShowEntryHistoryModal] = useState(false);
	const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false);

	return (
		<div className="relative">
			<Menu>
				<Menu.Button
					className="hover:bg-gray-700/10 rounded-full p-2"
					aria-label="More options"
					title="More options"
				>
					<MoreIcon className="text-icon-base" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked',
									data: {
										entryType: 'entry'
									}
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryDetailsModal(true)}
							ariaLabel="Entry details"
						>
							Entry details
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryHistoryModal(true)}
							ariaLabel="Entry history"
						>
							Entry history
						</OptionsMenuButton>
					</Menu.Item>

					{hasDeleteAccess && (
						<Menu.Item>
							<OptionsMenuButton
								ariaLabel="Delete entry"
								onClick={() => setShowDeleteEntryModal(true)}
							>
								Delete entry
							</OptionsMenuButton>
						</Menu.Item>
					)}
				</Menu.Items>
			</Menu>

			{hasDeleteAccess && (
				<DeleteEntryModal
					title="Delete Entry"
					description="Are you sure you want to delete this entry?"
					entryId={entryId}
					projectId={projectId}
					show={showDeleteEntryModal}
					onClose={() => setShowDeleteEntryModal(false)}
					onEntryDeleted={onEntryDeleted}
				/>
			)}

			<EntryDetailsModal
				open={showEntryDetailsModal}
				onClose={() => setShowEntryDetailsModal(false)}
				entryId={entryId}
				projectId={projectId}
			/>

			<EntryHistoryModal
				open={showEntryHistoryModal}
				onClose={() => setShowEntryHistoryModal(false)}
				entryId={entryId}
				projectId={projectId}
			/>
		</div>
	);
};

export type DataPendingSubmission = {
	entry: Entry;
	actionType: 'save' | 'saveAndClose' | 'saveAndCreateNew';
};
export const SubmitDiffModal = ({
	dataPendingSubmission,
	initialEntry,
	onClose,
	onConfirm,
	onDelete,
	submitting,
	variables
}: {
	dataPendingSubmission?: DataPendingSubmission;
	initialEntry: Entry;
	onClose: () => void;
	onConfirm: (args: { entry: Entry; action: NextAction }) => void;
	submitting?: boolean;
	variables: Variable[];
	onDelete: () => void;
}) => {
	if (!dataPendingSubmission) {
		return null;
	}

	const initialValues = parseBackendValues({
		variables,
		entry: initialEntry
	});
	const currentValues = {
		...initialValues,
		...parseBackendValues({ variables, entry: dataPendingSubmission.entry }) // there is a bug here if there is a file variable they can't be parsed like this but it will work because the
	};

	const diffedFields: Record<string, DiffedField> = {};
	for (const key of Object.keys(dataPendingSubmission.entry)) {
		if (variables.find(v => v.variableName === key)?.variableType === 'file') {
			diffedFields[key] = {
				from: initialValues[key],
				to: dataPendingSubmission.entry[key]
			};
		} else {
			diffedFields[key] = {
				from: initialValues[key],
				to: currentValues[key]
			};
		}
	}

	return (
		<ConfirmDiffModal
			title="Please review the pending changes"
			diff={diffedFields}
			onClose={onClose}
			onConfirm={() =>
				onConfirm({
					entry: dataPendingSubmission.entry,
					action: {
						type: dataPendingSubmission.actionType
					}
				})
			}
			submitting={submitting}
			variables={variables}
			onDelete={onDelete}
		/>
	);
};

export const EntryFormSkeleton = () => {
	return (
		<div
			className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
			role="progressbar"
		>
			<HeaderSkeleton />

			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
		</div>
	);
};

export const HeaderSkeleton = () => (
	<div className="bg-gray-300 fixed top-0 right-0 left-0 h-[60px]" />
);

const InputSkeleton = () => (
	<div className="flex flex-col gap-1">
		<Skeleton className="w-40 h-4 rounded-sm" />
		<Skeleton className="w-full h-14 rounded-md" />
	</div>
);

export const ErrorLoadingEntry = ({
	isFetching,
	refetch,
	message = 'Error loading data, please try again. Contact support if the problem persists.'
}: {
	isFetching: boolean;
	refetch: () => void;
	message?: string;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	return (
		<div className="v-[100vw] h-[100vh] flex items-center justify-center flex-col">
			<Icon svg={Svgs.LedidiLogo} customSize={5} />

			<p className="text-base font-bold mt-20">{message}</p>

			<div className="flex flex-col gap-4 mt-10">
				<Button title="Reload" variant="primary" loading={isFetching} onClick={refetch} />

				<Link
					className="text-sm underline self-center"
					to={ROUTE_MAP.projects.byId.dataset.createPath({ projectId })}
				>
					Back to dataset
				</Link>
			</div>
		</div>
	);
};

/**
 * Options for the useLastVisibleElement hook
 */
interface UseLastVisibleElementOptions {
	/** CSS selector for the elements to observe */
	selector: string;
	/** Threshold for visibility (0-1), 1 means fully visible */
	threshold?: number;
	/** Root margin for the observer */
	rootMargin?: string;
	/** Local storage key to save the last visible element ID */
	storageKey?: string;

	loading?: boolean;
}

/**
 * Hook that tracks the last fully visible element on screen and stores it
 * for restoration after navigation
 */
export const useLastVisibleElement = ({
	selector,
	threshold = 1.0,
	rootMargin = '0px',
	storageKey = 'lastVisibleElementId',
	loading
}: UseLastVisibleElementOptions) => {
	useEffect(() => {
		if (loading) {
			return;
		}

		// Skip if not in browser environment
		if (typeof window === 'undefined' || typeof IntersectionObserver === 'undefined') {
			return;
		}

		const elementsToObserve = document.querySelectorAll(selector);
		const currentlyVisibleElements: Set<string> = new Set();

		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					const id = entry.target.id;

					if (!id) {
						console.warn('Element being observed has no ID:', entry.target);
						return;
					}

					if (entry.isIntersecting) {
						currentlyVisibleElements.add(id);
					} else {
						currentlyVisibleElements.delete(id);
					}
				});

				if (currentlyVisibleElements.size > 0) {
					const allElements = Array.from(document.querySelectorAll(selector)).filter(
						el => el.id && currentlyVisibleElements.has(el.id)
					);

					if (allElements.length > 0) {
						const topmostElement = allElements[0].id;

						sessionStorage.setItem(storageKey, topmostElement);
					}
				}
			},
			{
				root: null, // viewport
				rootMargin,
				threshold
			}
		);

		// Start observing all elements
		elementsToObserve.forEach(element => {
			if (element.id) {
				observer.observe(element);
			} else {
				console.warn('Element has no ID and will not be tracked:', element);
			}
		});

		return () => {
			elementsToObserve.forEach(element => {
				observer.unobserve(element);
			});
			observer.disconnect();
		};
	}, [selector, threshold, rootMargin, storageKey, loading]);

	const scrollToLastVisibleElement = () => {
		const lastVisibleElementId = sessionStorage.getItem(storageKey);

		if (lastVisibleElementId) {
			const element = document.getElementById(lastVisibleElementId);
			if (element) {
				element.style.scrollMarginTop = '80px'; // 60px from fixed header + some extra padding
				element.scrollIntoView({ block: 'start' });
				return true;
			}
		}
		return false;
	};

	return {
		scrollToLastVisibleElement
	};
};
