import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useEntry } from './useEntry';
import { useCollaboratorsData } from '../collaborators/useCollaboratorsData';
import { useOrganizationsByCollaborator } from '../collaborators/useOrganizationsByCollaborator';
import { useMemo } from 'react';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';

export function useEntryWriteAccess(): boolean {
	const [{ data: entry }] = useEntry();

	const { hasDatasetWriteAllAccess, hasDatasetWriteOwnAccess, ...permissions } = usePermissions();

	const hasDatasetWriteOrgAccess = permissions.projectOrganizationAccesses?.find(
		orgAccess => orgAccess.organizationId.toString() === entry?.userProjectOrgId
	);

	const currentUserQuery = useCurrentUserQuery();

	const collaborator =
		useCollaboratorsData().collaboratorsMap[currentUserQuery.data?.username ?? ''];

	const userOrganizations = useOrganizationsByCollaborator(collaborator?.userId);

	const ownedByUser = entry?.ownedbyuser ?? '';
	const organizationId = entry?.userProjectOrgId ?? '';

	return useMemo(() => {
		return (
			(hasDatasetWriteOwnAccess && ownedByUser === currentUserQuery.data?.username) ||
			(hasDatasetWriteOrgAccess?.writeAccess &&
				userOrganizations.map(d => d.id).includes(organizationId)) ||
			hasDatasetWriteAllAccess
		);
	}, [
		hasDatasetWriteAllAccess,
		hasDatasetWriteOwnAccess,
		hasDatasetWriteOrgAccess,
		currentUserQuery.data?.username,
		ownedByUser,
		entry?.userProjectOrgId
	]);
}
