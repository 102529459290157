import { useState, useMemo } from 'react';
import {
	StatusModal,
	StatusesTable,
	DeleteStatusModal,
	NoStatuses,
	CreateStatusesFromCategoriesModal
} from 'components/Statuses';
import { filterStatusesBySearchTerm } from 'helpers/statuses';
import { Status, StatusColor } from 'store/data/statuses';
import { StatusesPageHeader } from './StatusesPageHeader';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import {
	useProject,
	usePermissions,
	useIsProjectOwner,
	useStatuses,
	useEntries
} from 'hooks/store';

import { selectEntriesTableParams } from 'store/data/entries';
import { useSelector } from 'hooks/utils';

const initialStatus: Status = {
	name: '',
	label: '',
	description: '',
	statusColor: StatusColor.Color1,
	dueTimeUnit: null,
	dueTimeAmount: null
};

export function StatusesPage() {
	const [{ loading: fetchingProject }] = useProject();
	const { fetched: arePermissionsFetched, loading: loadingPermissions } = usePermissions();

	const isProjectOwner = useIsProjectOwner();

	const [
		{
			data: { statuses, hasStatuses },
			loading: loadingStatuses,
			fetched: areStatusesFetched
		}
	] = useStatuses();

	const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
	const [selectedDeleteStatus, setSelectedDeleteStatus] = useState<Status | null>(null);
	const [createStatusesFromCategoriesModal, setCreateStatusesFromCategoriesModal] =
		useState(false);

	// TODO: move in redux maybe
	const [searchTerm, setSearchTerm] = useState('');

	const [{ data: entries }] = useEntries();
	const { totalCount } = useSelector(state => selectEntriesTableParams(state.data.entries));
	const shouldShowUpdateAllEntries = entries.length > 0 && totalCount < 5000;

	const filteredStatuses = useMemo(
		() => filterStatusesBySearchTerm(statuses, searchTerm),
		[statuses, searchTerm]
	);

	function onAddNewStatus() {
		setSelectedStatus({ ...initialStatus });
	}

	function onStatusUpdate(status: Status) {
		setSelectedStatus(status);
	}

	function onDeleteStatus(status: Status) {
		setSelectedDeleteStatus(status);
	}

	const loadingStatusesInitial = loadingStatuses && !areStatusesFetched;

	const loading = fetchingProject || loadingPermissions || loadingStatusesInitial;
	const immediate = !areStatusesFetched || !arePermissionsFetched;

	return (
		<>
			<StatusesPageHeader
				isProjectOwner={isProjectOwner ?? false}
				hasStatuses={hasStatuses}
				searchTermState={{ searchTerm, setSearchTerm }}
				onCreateClick={onAddNewStatus}
				onCreateFromCategoriesClick={() => setCreateStatusesFromCategoriesModal(true)}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{hasStatuses ? (
						<StatusesTable
							statuses={filteredStatuses}
							onUpdate={onStatusUpdate}
							onDelete={onDeleteStatus}
						/>
					) : (
						<NoStatuses
							onCreateClick={onAddNewStatus}
							onCreateFromCategoryVariableClick={() =>
								setCreateStatusesFromCategoriesModal(true)
							}
						/>
					)}
				</Grid.Container>
			</Suspend>

			{/*
				==============
					MODALS
				==============
			*/}

			{/* ADD / EDIT STATUS MODAL */}
			{selectedStatus && (
				<StatusModal
					status={selectedStatus}
					onClose={() => setSelectedStatus(null)}
					shouldShowUpdateAllEntries={shouldShowUpdateAllEntries}
				/>
			)}

			{/* DELETE STATUS MODAL */}
			{selectedDeleteStatus && (
				<DeleteStatusModal
					status={selectedDeleteStatus}
					onClose={() => setSelectedDeleteStatus(null)}
				/>
			)}

			{/* DELETE STATUS MODAL */}
			{createStatusesFromCategoriesModal && (
				<CreateStatusesFromCategoriesModal
					onClose={() => setCreateStatusesFromCategoriesModal(false)}
				/>
			)}
		</>
	);
}
