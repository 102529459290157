import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { selectIsAccountFetched, getAccount, ActionTypes } from 'store/account/subscription';
import { AccountDetails } from 'store/account/subscription';
import { LanguageType, StorageKeys } from 'types/index';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { useMemo } from 'react';

interface AccountData {
	username: string | null;
	details: AccountDetails | null;
	languageCode: LanguageType;
	isProfileIncomplete: boolean;
}

export function useAccount(): OperationResult<AccountData | undefined> {
	const dispatch = useDispatch();

	const currentUserQuery = useCurrentUserQuery();

	const fetched = useSelector(state => selectIsAccountFetched(state.account.subscription));
	const details = useSelector(state => state.account.subscription.accountDetails);

	const languageCode = details
		? details.userPreferences.chosenLanguageCode
		: (localStorage.getItem(StorageKeys.UserLanguage) as LanguageType) ?? LanguageType.English;

	const isProfileIncomplete =
		details !== null &&
		(!details.userFirstName ||
			!details.userSirName ||
			!details.department ||
			!details.phoneNumber ||
			!details.workplace);

	const [{ loading, error }] = useActivity(ActionTypes.GET_ACCOUNT);

	const data = useMemo(() => {
		if (!currentUserQuery.data) {
			return undefined;
		}

		return {
			username: currentUserQuery.data.username,
			details,
			languageCode,
			isProfileIncomplete
		};
	}, [currentUserQuery.data, details, languageCode, isProfileIncomplete]);

	function handler() {
		dispatch(getAccount());
	}

	return [{ data, loading, error, fetched }, handler];
}
