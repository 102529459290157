import { Control, useForm, Resolver, FieldErrors, useFormState, useWatch } from 'react-hook-form';
import { Variable, Entry } from './types';
import { zodResolver } from '@hookform/resolvers/zod';
import { toZodSchema } from './utils/zodUtils';
import { StringVariableInput } from './inputs/StringVariableInput';
import { NumericVariableInput } from './inputs/NumericVariableInput';
import { CategoryVariableInput } from './inputs/CategoryNonFixedVariableInput';
import { CategoryMultipleVariableInput } from './inputs/CategoryMultipleNonFixedVariableInput';
import { DateVariableInput } from './inputs/DateVariableInput';
import { DateTimeVariableInput } from './inputs/DateTimeVariableInput';
import { FileVariableInput } from './inputs/FileVariableInput';
import { UserDefinedUniqueVariableInput } from './inputs/UserDefinedUniqueInput';
import { TimeDurationVariableInput } from './inputs/TimeDurationVariableInput';
import { RefObject, useMemo, useState } from 'react';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import clsx from 'clsx';
import { useRef } from 'react';
import {
	FormItem,
	GroupFormItem,
	isCategoryFixedFormItem,
	isCategoryMultipleFixedFormItem,
	isVariableFormItem,
	SeriesFormItem,
	ProjectData,
	VariableFormItem
} from './data/useProjectData/useProjectData';

import {
	defaultEmptyValueForVariables,
	parseBackendValues,
	ParsedEntry
} from './utils/parse-backend-values/parseBackendValues';
import { DiffedField, getDirtyFields, ParsedEntryValue } from './utils/formUtils';
import { ConfirmDiffModal } from './confirm-diff-modal/ConfirmDiffModal';
import { PersonalDataWarning } from './smart-components/PersonalDataWarning';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrompt } from 'hooks/navigation/usePrompt';
import { useTracking } from 'app/tracking/TrackingProvider';
import { VisibilityRuleTrigger } from './inputs/DependencyVisibilityTrigger';
import { CategoryMultipleFixedVariableInput } from './inputs/CategoryMultipleFixedVariableInput';
import { CategoryFixedVariableInput } from './inputs/CategoryFixedVariableInput';
import { SeriesEntryCount } from './smart-components/SeriesEntryCount';
import { ROUTE_MAP } from './utils/routeMap';
import {
	SeriesEntryBody,
	TableSkeleton
} from './smart-components/series-entry-body/SeriesEntryBody';
import { useSeriesTablesDataQuery } from './smart-components/series-entry-body/useSeriesTablesDataQuery';
import { useProcessSeriesEntryBodyRows } from './series/details/SeriesDetailsPage';
import { CircleCheckIcon } from '@icons';
import { isEmptyFormValue } from './smart-components/EntryHistoryModal';
import { SafariUserWarning } from './smart-components/SafariUserWarning';
import { toast } from 'react-toastify';

const SUBMIT_ACTION_TYPES = [
	'save',
	'saveAndClose',
	'saveAndCreateNew',
	'saveAndNavigate'
] as const;
export type SubmitActionType = (typeof SUBMIT_ACTION_TYPES)[number];

type SubmitAction = {
	type: 'save' | 'saveAndClose' | 'saveAndCreateNew';
};
type SubmitActionWithNavigate = {
	type: 'saveAndNavigate';
	nextNavLocation: string;
};

export type NextAction = SubmitAction | SubmitActionWithNavigate;

interface Props {
	initialEntry?: Entry;
	onSubmitForConfirmation: (args: {
		nextAction: SubmitAction;
		dirtyData: ParsedEntry;
	}) => Promise<void>;
	onSubmitForSubmission: (args: {
		nextAction: SubmitActionWithNavigate;
		dirtyData: ParsedEntry;
	}) => Promise<void>;
	Header: React.ReactNode;
	projectData: ProjectData;
	submitting?: boolean;

	/**
	 * Use this to allow the user to leave the page without the diff modal, even if there are unsaved pending changes in the form.
	 */
	allowLeavePageOverrideRef?: RefObject<boolean>;
}

export const EntryForm = ({
	projectData,
	initialEntry,
	onSubmitForConfirmation,
	onSubmitForSubmission,
	Header,
	submitting,
	allowLeavePageOverrideRef
}: Props) => {
	const overrideErrors = useRef<Record<string, string | undefined>>({});
	const { control, handleSubmit, setError, getValues, formState } = useForm<Entry>({
		mode: 'onBlur',
		resolver: combineResolvers(
			zodResolver(toZodSchema(projectData.variables)),
			overrideErrorsResolver(overrideErrors)
		),
		defaultValues: initialEntry
			? parseBackendValues({ variables: projectData.variables, entry: initialEntry })
			: defaultEmptyValueForVariables(projectData.variables)
	});

	const { defaultValues } = formState;

	const handleOverrideError = (name: string, error: string | undefined) => {
		overrideErrors.current[name] = error;
		setError(name, { message: error, type: 'custom' });
	};

	const validateAndSubmit = (nextAction?: NextAction) => {
		handleSubmit(
			data => {
				const dirtyFields = getDirtyFields(getValues(), defaultValues || {});

				if (dirtyFields.length === 0) {
					toast.warning('No changes to submit', {
						position: 'top-center'
					});
					return;
				}
				const dirtyData = Object.fromEntries(
					Object.entries(data).filter(([key]) => dirtyFields.includes(key))
				);

				if (nextAction?.type === 'saveAndNavigate') {
					return onSubmitForSubmission({
						nextAction: nextAction,
						dirtyData
					});
				}

				onSubmitForConfirmation({
					nextAction: nextAction || { type: 'save' },
					dirtyData
				});
			},
			_error => {
				toast.error(
					'Could not submit entry, ensure all required fields are filled, and satisfies validation rules.',
					{
						position: 'top-center'
					}
				);
			}
		)();
	};

	const anyObligatory = projectData.variables.some(v => v.obligatory);
	return (
		<>
			<DiffModalNavigationIntercept
				control={control}
				variables={projectData.variables}
				allowLeavePageOverrideRef={allowLeavePageOverrideRef}
				onSaveConfirmed={nextNavLocation => {
					validateAndSubmit({ type: 'saveAndNavigate', nextNavLocation });
				}}
				isSubmitting={submitting}
			/>

			<form
				className="grid grid-cols-2 gap-10 relative mb-52"
				data-testid="entry-form"
				onSubmit={e => {
					e.preventDefault();

					const action = (e.nativeEvent as SubmitEvent).submitter?.dataset.action;
					const submitType = parseSubmitType(action);
					let nextAction: NextAction | undefined = undefined;

					if (submitType && submitType !== 'saveAndNavigate') {
						nextAction = { type: submitType };
					}

					validateAndSubmit(nextAction);
				}}
			>
				{Header}

				<PersonalDataWarning />

				{projectData.formTitle && (
					<h1 className="text-2xl font-semibold col-span-full whitespace-pre-line">
						{projectData.formTitle}
					</h1>
				)}

				{projectData.formItems.map((item, index) => (
					<FormItemComponent
						key={index}
						item={item}
						control={control}
						onError={handleOverrideError}
					/>
				))}

				{anyObligatory && (
					<p className="mt-4 text-sm col-span-full">
						Fields marked with an (*) are required
					</p>
				)}
			</form>

			<SafariUserWarning />
		</>
	);
};

const DiffModalNavigationIntercept = ({
	control,
	variables,
	allowLeavePageOverrideRef,
	onSaveConfirmed,
	isSubmitting
}: {
	control: FormControl;
	variables: Variable[];
	allowLeavePageOverrideRef?: RefObject<boolean>;
	onSaveConfirmed: (nextNavLocation: string) => void;
	isSubmitting?: boolean;
}) => {
	const { track } = useTracking();
	const navigate = useNavigate();

	const currentLocation = useLocation();
	const formState = useFormState({ control });
	const currentFormValues = useWatch({ control });

	const allowNavigation = useRef(false);
	const [nextNavLocation, setNextNavLocation] = useState<string>();

	const dirtyFields = getDirtyFields(currentFormValues, formState.defaultValues || {});

	const isDirty = dirtyFields.length > 0;

	const [showModal, setShowModal] = useState(false);

	// This is needed to intercept navigation from react router
	usePrompt(nextLocation => {
		if (allowLeavePageOverrideRef?.current) {
			return true;
		}

		const sameLocation = currentLocation.pathname === nextLocation.pathname;

		const oldSearchParams = new URLSearchParams(currentLocation.search);
		const newSearchParams = new URLSearchParams(nextLocation.search);

		const formWasChanged = oldSearchParams.get('formId') !== newSearchParams.get('formId');

		if (sameLocation && !formWasChanged) {
			return true;
		}

		if (allowLeavePageOverrideRef?.current) {
			return true;
		}

		if (isDirty && !allowNavigation.current) {
			setShowModal(true);
			const nextLocationWithSearch = nextLocation.pathname + nextLocation.search;
			setNextNavLocation(nextLocationWithSearch);
			return false;
		}

		return true;
	}, isDirty);

	const diff: Record<string, DiffedField> = {};
	for (const field of dirtyFields) {
		diff[field] = {
			// the amount of boilerplate needed to not have to cast was a bit too much, this replaces any anyways, so it's a small improvement I guess
			from: formState.defaultValues?.[field] as ParsedEntryValue,
			to: currentFormValues[field] as ParsedEntryValue
		};
	}

	if (!showModal || !isDirty) return null;
	return (
		<ConfirmDiffModal
			title="You have unsaved changes"
			diff={diff}
			onClose={() => {
				setShowModal(false);
				track({
					eventName: 'entry_diff_cancelled'
				});
			}}
			submitting={isSubmitting}
			onConfirm={() => {
				track({
					eventName: 'entry_diff_confirmed'
				});

				if (!nextNavLocation) {
					console.error(
						new Error('nextNavLocation is not set, this is not supposed to happen')
					);
					return;
				}

				onSaveConfirmed(nextNavLocation);
			}}
			onDelete={() => {
				track({
					eventName: 'entry_diff_discarded'
				});
				if (!nextNavLocation) {
					console.error(
						new Error('nextNavLocation is not set, this is not supposed to happen')
					);
					return;
				}
				allowNavigation.current = true;
				navigate(nextNavLocation);
			}}
			variables={variables}
		/>
	);
};

const combineResolvers = (
	zodResolver: Resolver<ParsedEntry>,
	customResolver: Resolver<ParsedEntry>
): Resolver<ParsedEntry> => {
	return async (values: ParsedEntry, context, options) => {
		const [zodResults, customResults] = await Promise.all([
			zodResolver(values, context, options),
			customResolver(values, context, options)
		]);

		// TODO: MARTIN WRITE TESTS FOR THIS 🤘
		const hasErrors = !isEmptyObject(zodResults.errors) || !isEmptyObject(customResults.errors);

		return {
			values: hasErrors ? {} : zodResults.values, // zodResults.values is the values that passed the zod validation
			errors: {
				...zodResults.errors,
				...customResults.errors
			}
		};
	};
};

const isEmptyObject = (obj: Record<string, any>) => {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const overrideErrorsResolver = (
	ref: React.MutableRefObject<Record<string, string | undefined>>
): Resolver<ParsedEntry> => {
	return async (values: ParsedEntry, _, __) => {
		const errors: FieldErrors<ParsedEntry> = {};

		for (const [name, error] of Object.entries(ref.current)) {
			if (error !== undefined) {
				errors[name] = { type: 'custom', message: error };
			}
		}

		return { values, errors };
	};
};

const FormItemComponent = ({
	item,
	control,
	onError
}: {
	item: FormItem;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}) => {
	switch (item.type) {
		case 'variable': {
			return (
				<VisibilityRuleTrigger control={control} variableFormItem={item}>
					<VariableInput control={control} item={item} onError={onError} />
				</VisibilityRuleTrigger>
			);
		}

		case 'group': {
			return (
				<GroupContainer
					groupLabel={item.group.groupLabel}
					formItem={item}
					onError={onError}
					control={control}
				/>
			);
		}

		case 'separator':
			return <hr className="col-span-full" />;

		case 'subtitle':
			return (
				<h2 className="font-semibold col-span-full text-base whitespace-pre-line">
					{item.text}
				</h2>
			);

		case 'text':
			return <p className="col-span-full text-base whitespace-pre-line">{item.text}</p>;

		case 'series': {
			return <SeriesContainer formItem={item} />;
		}
	}
};

const SeriesContainer = ({ formItem }: { formItem: SeriesFormItem }) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	if (entryId) {
		return (
			<UpdateEntrySeriesContainer
				formItem={formItem}
				projectId={projectId}
				entryId={entryId}
			/>
		);
	}

	return <CreateEntrySeriesContainer formItem={formItem} />;
};

const CreateEntrySeriesContainer = ({ formItem }: { formItem: SeriesFormItem }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="col-span-full rounded-lg shadow-normal flex flex-col ">
			<button
				type="button"
				className="flex justify-between items-center p-6"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="flex gap-1 items-center">
					<Icon
						svg={Svgs.ChevronDown}
						className={clsx('transition-all', isOpen && 'rotate-180')}
						propagate
					/>

					<Icon size={s => s.m} svg={Svgs.Set} />

					<h2 className="text-base font-semibold">{formItem.label}</h2>
				</div>
			</button>

			{isOpen && (
				<div className="px-6 pb-6">
					<p className="text-base text-gray-700">
						Save the entry to be able to register series data
					</p>
				</div>
			)}
		</div>
	);
};

const UpdateEntrySeriesContainer = ({
	formItem,
	projectId,
	entryId
}: {
	formItem: SeriesFormItem;
	projectId: string;
	entryId: string;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className="col-span-full rounded-lg shadow-normal flex flex-col"
			data-scroll-anchor
			id={`scroll-anchor_${formItem.name}`}
		>
			<button
				type="button"
				className="flex justify-between items-center p-6"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="flex gap-1 items-center">
					<Icon
						svg={Svgs.ChevronDown}
						className={clsx('transition-all', isOpen && 'rotate-180')}
						propagate
					/>

					<Icon size={s => s.m} svg={Svgs.Set} propagate />

					<h2 className="text-base font-semibold">{formItem.label}</h2>
				</div>

				<div className="flex gap-4 items-center">
					<SeriesEntryCount seriesName={formItem.name} />

					<Link
						to={ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.createPath({
							projectId,
							entryId,
							seriesName: formItem.name
						})}
					>
						<Icon
							svg={Svgs.Maximize}
							size={s => s.m}
							colors={{
								color: Colors.primary.normal
							}}
							variant={v => v.button}
							propagate
						/>
					</Link>

					<Link
						className="rounded-full border border-primary-500"
						to={ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.create.createPath(
							{
								projectId,
								entryId,
								seriesName: formItem.name,
								formId: null
							}
						)}
					>
						<Icon
							svg={Svgs.Add}
							size={s => s.m}
							variant={v => v.button}
							colors={{
								color: Colors.primary.normal
							}}
							propagate
						/>
					</Link>
				</div>
			</button>

			{isOpen && (
				<div className="px-6 pb-6">
					<SeriesEntryBodyContainer
						entryId={entryId}
						projectId={projectId}
						seriesName={formItem.name}
					/>
				</div>
			)}
		</div>
	);
};

const SeriesEntryBodyContainer = ({
	entryId,
	projectId,
	seriesName
}: {
	entryId: string;
	projectId: string;
	seriesName: string;
}) => {
	const processedColumns = useSeriesTablesDataQuery({ projectId, entryId, seriesName });
	const processedRows = useProcessSeriesEntryBodyRows({
		entryId,
		projectId,
		seriesName
	});

	if (processedRows.loading) {
		return <TableSkeleton />;
	}

	if (processedRows.error) {
		return <p className="font-semibold text-error-500">Error fetching entries</p>;
	}

	if (!processedColumns.data) {
		return <p className="font-semibold text-error-500">Error processing columns</p>;
	}

	if (!processedRows.data) {
		return <p className="font-semibold text-error-500">Error processing rows</p>;
	}

	return (
		<SeriesEntryBody
			seriesName={seriesName}
			columns={processedColumns.data.columns}
			rows={processedRows.data}
			variables={processedColumns.data.variables}
			projectId={projectId}
			entryId={entryId}
		/>
	);
};

const GroupContainer = ({
	groupLabel,
	formItem,
	onError,
	control
}: {
	groupLabel: string;
	formItem: GroupFormItem;
	onError: (name: string, error: string | undefined) => void;
	control: FormControl;
}) => {
	const variables = useMemo(
		() => formItem.formItems.filter(isVariableFormItem).map(item => item.variable),
		[formItem]
	);

	const [isOpen, setIsOpen] = useState(
		formItem.formItems.some(
			formItem => isVariableFormItem(formItem) && formItem.variable.obligatory
		)
	);

	return (
		<div
			className="p-6 col-span-full rounded-lg shadow-normal flex flex-col relative"
			data-scroll-anchor
			id={`scroll-anchor_${formItem.group.groupName}`}
		>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="flex justify-between items-center"
				type="button"
			>
				<div className="flex gap-2 items-center self-stretch">
					<Icon
						onClick={() => setIsOpen(!isOpen)}
						svg={Svgs.ChevronDown}
						className={clsx('transition-all', isOpen && 'rotate-180')}
					/>

					<Icon size={s => s.s} svg={Svgs.Folder} />

					<h2 className="text-base font-semibold">{groupLabel}</h2>
				</div>

				<GroupStatusIcon control={control} variables={variables} />
			</button>

			<div
				className={clsx(
					'grid grid-cols-2 py-[10px] gap-10 lg:gap-y-10',
					!isOpen && 'hidden'
				)}
			>
				{formItem.formItems.map((item, index) => (
					<FormItemComponent
						key={index}
						item={item}
						control={control}
						onError={onError}
					/>
				))}
			</div>
		</div>
	);
};

const GroupStatusIcon = ({
	control,
	variables
}: {
	control: FormControl;
	variables: Variable[];
}) => {
	const formState = useFormState({ control });

	const errors = formState.errors;

	const hasErrors = variables.some(v => !!errors[v.variableName]);

	const allValuesInGroup = useWatch({ control, name: variables.map(v => v.variableName) });

	const allVariablesValidated = useMemo(() => {
		let allValid = true;

		for (let i = 0; i < variables.length; i++) {
			const value = allValuesInGroup[i];
			if (isEmptyFormValue(value)) {
				allValid = false;
			}
		}

		return allValid;
	}, [allValuesInGroup]);

	if (hasErrors) {
		return (
			<Icon svg={Svgs.AlertCircle} colors={{ color: Colors.text.error }} size={s => s.m} />
		);
	}

	if (allVariablesValidated) {
		return (
			<CircleCheckIcon
				className="text-[16px] text-success-500"
				aria-label="All variables are valid"
			/>
		);
	}

	return null;
};

export type FormControl = Control<Entry, any>;

const VariableInput = ({
	item,
	control,
	onError
}: {
	item: VariableFormItem;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}) => {
	switch (item.variableType) {
		case 'string':
			return <StringVariableInput control={control} formItem={item} />;

		case 'float':
		case 'integer': {
			return <NumericVariableInput control={control} formItem={item} />;
		}

		case 'category': {
			if (isCategoryFixedFormItem(item)) {
				return <CategoryFixedVariableInput control={control} item={item} />;
			}
			return <CategoryVariableInput control={control} item={item} />;
		}

		case 'categoryMultiple': {
			if (isCategoryMultipleFixedFormItem(item)) {
				return <CategoryMultipleFixedVariableInput control={control} item={item} />;
			}
			return <CategoryMultipleVariableInput control={control} item={item} />;
		}

		case 'date': {
			return <DateVariableInput control={control} formItem={item} />;
		}

		case 'datetime': {
			return <DateTimeVariableInput control={control} formItem={item} />;
		}

		case 'file': {
			return <FileVariableInput onError={onError} control={control} formItem={item} />;
		}

		case 'userDefinedUnique': {
			return <UserDefinedUniqueVariableInput control={control} formItem={item} />;
		}

		case 'timeDuration': {
			return (
				<TimeDurationVariableInput control={control} formItem={item} onError={onError} />
			);
		}
	}

	console.error(new Error('Unhandled variable type: '), {
		// @ts-ignore
		variableType: variable.variableType
	});

	return null;
};

const parseSubmitType = (type: string | undefined): SubmitActionType | undefined => {
	if (!type) return undefined;

	if (SUBMIT_ACTION_TYPES.some(t => t === type)) {
		return type as SubmitActionType;
	}

	console.warn(`Invalid submit type: ${type}`);
	return undefined;
};

export const createSubmitEvent = (submitter: HTMLElement): Event => {
	let submitEvent: Event;

	try {
		submitEvent = new SubmitEvent('submit', {
			bubbles: true,
			cancelable: true,
			submitter: submitter
		});
	} catch (e) {
		submitEvent = new Event('submit', {
			bubbles: true,
			cancelable: true
		});

		// Extend the event with submitter property
		Object.assign(submitEvent, { submitter });
	}

	return submitEvent;
};
