import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { initButtonProps } from 'helpers/buttons';

import { ActionTypes, CopyProjectElements, UpdateProject } from 'store/data/projects';
import { InputType } from 'types/index';
import { Column, Row } from '../CreateAndImport/CreateProjectForm/CreateProjectForm.style';
import { CheckboxContainer, FormContainer } from './CopyProjectModal.style';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { StickyFooter } from 'components/UI/StickyFooter';
import {
	useTranslation,
	useScopedProjects,
	useProjectById,
	useCopyProject,
	useUpdateProject,
	useTransactionActivity,
	useCopiedProjectId,
	useUsername
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { ChangeOwnerModal } from '../ChangeOwnerModal/ChangeOwnerModal';
import { useCompletedAction } from 'hooks/utils';
import { UserCard } from 'components/UI/UserCard';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useQuery } from '@tanstack/react-query';
import { fetchAuthSession } from '@aws-amplify/core';

const firstLetterToUpperCase = (label: string) => {
	return label.charAt(0).toUpperCase() + label.slice(1);
};
interface Props {
	projectId: string;
	onClose: (success?: boolean) => void;
}

enum CheckboxOptions {
	Variables = 'variables',
	Groups = 'groups',
	Forms = 'forms',
	Dependencies = 'dependencies',
	Statuses = 'statuses',
	StatusPermissions = 'statusPermissions',
	Series = 'series',
	Data = 'data',
	ProjectRoles = 'projectRoles'
}

export type CopyProjectNewOwner = {
	emailAddress: string;
	userId: string;
	userFirstName?: string;
	userSirName?: string;
};

export function CopyProjectModal({ projectId, onClose }: Props) {
	const { translate } = useTranslation();
	const { track } = useTracking();

	const { data: isDatasetSuperAdmin } = useQuery({
		queryKey: ['isDatasetSuperAdmin'],
		queryFn: async () => {
			try {
				const session = await fetchAuthSession();
				const isDatasetSuperAdmin =
					session.tokens?.idToken?.payload['custom:datasetRole'] === 'datasetSuperAdmin';

				return isDatasetSuperAdmin;
			} catch {
				return false;
			}
		}
	});

	const [, getProjects] = useScopedProjects();
	const [, setCopiedProjectId] = useCopiedProjectId();
	const project = useProjectById(projectId);
	const userId = useUsername();
	const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
	const [endDateError, setEndDateError] = useState<string>(() => {
		if (!project || !project.projectEndDate) return '';
		const date = new Date(project.projectEndDate);

		date.setHours(0, 0, 0, 0);

		const now = new Date(new Date().setHours(0, 0, 0, 0));

		if (date < now) {
			return translate(dict => dict.createProject.projectEndDateError);
		} else {
			if (endDateError.length > 0) {
				return '';
			}
		}
	});

	const [projectOwner, setProjectOwner] = useState<CopyProjectNewOwner>({
		userFirstName: project?.projectOwnerDetails?.userFirstName || '',
		userSirName: project?.projectOwnerDetails?.userSirName || '',
		userId: project?.projectOwnerDetails?.userId || '',
		emailAddress: project?.projectOwnerDetails?.emailAddress || ''
	});

	const isSameOwner = useMemo(
		() => projectOwner.userId === project?.projectOwnerDetails?.userId,
		[projectOwner, project]
	);

	useEffect(() => {
		handleAutoSelectionRules();
		if (!isSameOwner) resetForm();
	}, [isSameOwner]);

	const [
		{ loading: copyingProject, data: copiedProjectId, fetched: isFetchedCopiedProjectId },
		copyProject
	] = useCopyProject();
	const [{ loading: updatingProject, error: errorUpdatingProject }, updateProject] =
		useUpdateProject();

	const [{ loading: copyingData }] = useTransactionActivity(ActionTypes.COPY_PROJECT);

	const [isElementChecked, setIsElementChecked] = useState<CopyProjectElements>({
		variables: { checked: false, disabled: false, visible: true },
		groups: { checked: false, disabled: false, visible: true },
		forms: { checked: false, disabled: false, visible: true },
		dependencies: { checked: false, disabled: false, visible: true },
		statuses: { checked: false, disabled: false, visible: true },
		statusPermissions: { checked: false, disabled: false, visible: true },
		series: { checked: false, disabled: false, visible: true },
		data: { checked: false, disabled: false, visible: isDatasetSuperAdmin ?? false },
		// 'projectRoles' should be added back after we settle on a solution for PRJCTS-8389
		projectRoles: { checked: false, disabled: false, visible: false }
	});

	const validationSchema = yup.object({
		projectName: yup
			.string()
			.trim()
			.required(translate(dict => dict.createProject.projectTitleError))
	});

	const initialValues: UpdateProject = {
		projectId: projectId,
		projectName: project?.projectName || '',
		givenProjectNumber: project?.givenProjectNumber || '',
		status: project?.status || ProjectStatus.Ongoing,
		projectType: project?.projectType || ProjectType.CORE,
		projectEndDate: project?.projectEndDate || '',
		description: project?.description || '',
		slideFolderURL: project?.slideFolderURL || ''
	};

	const [textAreaCount, setTextAreaCount] = useState(initialValues.description.length);

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		Controller,
		Form,
		touchedFields,
		reset: resetForm,
		control,
		isDirty: formHasChanges,
		errors,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema,
		enableReinitialize: true
	});

	const handleSelectedElement = (element: string) => {
		isElementChecked[element].checked = !isElementChecked[element].checked;
		handleAutoSelectionRules();
	};

	const handleAutoSelectionRules = () => {
		if (
			isElementChecked.forms.checked === true ||
			isElementChecked.dependencies.checked === true ||
			isElementChecked.statuses.checked === true ||
			isElementChecked.statusPermissions.checked === true ||
			isElementChecked.data.checked === true ||
			isElementChecked.projectRoles.checked === true
		) {
			setIsElementChecked(prevState => ({
				...prevState,
				variables: { ...prevState.variables, checked: true, disabled: true },
				groups: { ...prevState.groups, checked: true, disabled: true },
				statuses: {
					...prevState.statuses,
					checked:
						isElementChecked.statusPermissions.checked === true
							? true
							: prevState.statuses.checked,
					disabled: isElementChecked.statusPermissions.checked === true ? true : false
				},
				series: { ...prevState.series, checked: true, disabled: true }
			}));
		} else {
			setIsElementChecked(prevState => ({
				...prevState,
				variables: { ...prevState.variables, disabled: false },
				groups: { ...prevState.groups, disabled: false },
				series: { ...prevState.series, disabled: false }
			}));
		}
		if (isElementChecked.groups.checked === true) {
			setIsElementChecked(prevState => ({
				...prevState,
				variables: { ...prevState.variables, checked: true, disabled: true }
			}));
		}

		if (isElementChecked.series.checked) {
			setIsElementChecked(prevState => ({
				...prevState,
				variables: { ...prevState.variables, checked: true, disabled: true },
				groups: { ...prevState.groups, checked: true, disabled: true }
			}));
		}

		setIsElementChecked(prevState => ({
			...prevState,
			data: !isSameOwner
				? { ...prevState.data, checked: false, disabled: true }
				: { ...prevState.data, disabled: false }
		}));
	};
	const values = { ...getValues() };

	const calculateLetters = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTextAreaCount(e.target.value.length);
		if (e.target.value.length <= 0) {
			setTextAreaCount(initialValues.description.length);
		}
	};
	const handleFormSubmit = handleSubmit(() => {
		copyProject({
			projectId,
			projectDefinition: initialValues.projectName.length > 0,
			variables: isElementChecked.variables.checked,
			groups: isElementChecked.groups.checked,
			dependencies: isElementChecked.dependencies.checked,
			statuses: isElementChecked.statuses.checked,
			statusPermissions: isElementChecked.statusPermissions.checked,
			series: isElementChecked.series.checked,
			forms: isElementChecked.forms.checked,
			data: isElementChecked.data.checked,
			projectRoles: isElementChecked.projectRoles.checked,
			newOwnerId: !isSameOwner ? projectOwner.userId : undefined
		});
		track({
			eventName: 'project_copied',
			data: {
				project_id: projectId,
				variables: isElementChecked.variables.checked,
				groups: isElementChecked.groups.checked,
				dependencies: isElementChecked.dependencies.checked,
				statuses: isElementChecked.statuses.checked,
				statusPermissions: isElementChecked.statusPermissions.checked,
				series: isElementChecked.series.checked,
				forms: isElementChecked.forms.checked,
				data: isElementChecked.data.checked,
				projectRoles: isElementChecked.projectRoles.checked,
				isSameOwner
			}
		});
	});

	useCompletedAction(updatingProject, errorUpdatingProject, () => {
		onFinish();
	});

	useEffect(() => {
		if (isFetchedCopiedProjectId && copiedProjectId && !copyingData) {
			if (formHasChanges && isSameOwner) {
				trimFields();
				updateProject({ ...values, projectId: copiedProjectId });
			} else {
				onFinish();
			}
		}
	}, [isFetchedCopiedProjectId, copiedProjectId, isSameOwner]);

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.trim());
		setValue('description', values.description.trim());
		setValue('givenProjectNumber', values.givenProjectNumber.trim());
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ copyProject }) => copyProject.copyProjectButton),
			disabled: formHasChanges ? !canSubmitForm : false,
			loading: copyingProject || updatingProject,
			onClick: handleFormSubmit
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};
	});

	function onFinish() {
		if (isSameOwner) getProjects();
		setCopiedProjectId(null);
		onClose();
	}

	function handleClose() {
		if (copiedProjectId) {
			if (isSameOwner) getProjects();
			setCopiedProjectId(null);
		}
		onClose();
	}

	function checkProjectEndDate(dateStr: string) {
		if (dateStr === '' && endDateError !== '') {
			setEndDateError('');
			return;
		}
		const date = new Date(dateStr);

		date.setHours(0, 0, 0, 0);

		const now = new Date(new Date().setHours(0, 0, 0, 0));

		if (date < now) {
			setEndDateError(translate(dict => dict.createProject.projectEndDateError));
		} else {
			if (endDateError.length > 0) {
				setEndDateError('');
			}
		}
	}

	return (
		<Modal
			data-test-id="confirmCopyProject"
			title={translate(dict => dict.copyProject.copyProject)}
			onClose={handleClose}
			enterAsPrimaryOnClick
			visible
			close
			size={s => s.full}
			fullSizeConfig={{
				narrow: true,
				centerTitle: true
			}}
		>
			<FormContainer justify={j => j.between} column>
				<Form onSubmit={handleFormSubmit}>
					<Input
						{...register('projectName')}
						type={InputType.Text}
						label={translate(dict => dict.copyProject.newProjectTitle)}
						error={touchedFields.projectName ? errors.projectName?.message : ''}
						autoFocus
						placeholder={values.projectName}
						disabled={!isSameOwner}
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('description')}
						type={InputType.Textarea}
						label={translate(dict => dict.copyProject.newProjectDescription)}
						rows={4}
						placeholder={values.description}
						maxLength={240}
						onChange={e => calculateLetters(e)}
						disabled={!isSameOwner}
					/>
					<Typography.Hint>{textAreaCount} / 240</Typography.Hint>
					<Spacer size={s => s.s} />
					<Row>
						<Column>
							<Input
								{...register('givenProjectNumber')}
								type={InputType.Text}
								label={translate(dict => dict.copyProject.newProjectNumber)}
								placeholder={values.givenProjectNumber}
								disabled={!isSameOwner}
							/>
						</Column>
						<Column>
							<Controller
								control={control}
								name="projectEndDate"
								defaultValue={values.projectEndDate}
								render={({ field: { value } }) => (
									<Input
										type={InputType.Date}
										value={value}
										error={endDateError}
										label={translate(
											dict => dict.copyProject.newProjectEndDate
										)}
										onDateChange={({ formattedDate }) => {
											checkProjectEndDate(formattedDate);
											setValue('projectEndDate', formattedDate, {
												shouldDirty: true,
												shouldValidate: true
											});
										}}
										disabled={!isSameOwner}
									/>
								)}
							/>
						</Column>
					</Row>
					<Spacer size={s => s.s} />
					<InputLabel
						label={translate(dict => dict.copyProject.changeOwner.projectOwner)}
					/>

					<Flex>
						<UserCard.Table
							userId={projectOwner.userId}
							userData={{
								...projectOwner,
								userSirName:
									projectOwner.userSirName +
									(projectOwner.userId === userId
										? ` (${translate(({ tags }) => tags.you)})`
										: '')
							}}
						/>
						<Button
							variant={v => v.link}
							title={translate(dict => dict.copyProject.changeOwner.changeOwner)}
							marginOffset={{ left: 1.6 }}
							onClick={() => setShowChangeOwnerModal(true)}
						/>
					</Flex>
					<Spacer size={s => s.xs} />
					<Typography.Hint>
						*
						{translate(
							dict => dict.copyProject.changeOwner.changeProjectDetailsRestriction
						)}
					</Typography.Hint>

					<Spacer size={s => s.l} />
					<InputLabel label={translate(dict => dict.copyProject.include)} />
					<Typography.Hint>
						{translate(dict => dict.copyProject.selectElement)}
					</Typography.Hint>
					<Spacer size={s => s.s} />
					<CheckboxContainer>
						{Object.keys(isElementChecked).map(
							elementKey =>
								isElementChecked[elementKey].visible && (
									<Checkbox
										marginOffset={{ bottom: 1 }}
										key={elementKey}
										className="checkbox"
										label={firstLetterToUpperCase(
											translate(
												dict =>
													dict.copyProject.copyOptions[
														elementKey as CheckboxOptions
													]
											)
										)}
										onClick={() => handleSelectedElement(elementKey)}
										checked={isElementChecked[elementKey].checked}
										disabled={isElementChecked[elementKey].disabled}
									/>
								)
						)}
					</CheckboxContainer>
				</Form>
				<StickyFooter
					primary={buttonProps.primary}
					neutral={formHasChanges && buttonProps.neutral}
					maxWidth={65.2}
					modalFooter={true}
				/>
			</FormContainer>

			{showChangeOwnerModal && (
				<ChangeOwnerModal
					projectId={projectId}
					currentOwnerEmail={projectOwner.emailAddress}
					onClose={newOwner => {
						setShowChangeOwnerModal(false);
						newOwner && setProjectOwner(newOwner);
					}}
				/>
			)}
		</Modal>
	);
}
