import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAccount, useSubscription } from 'hooks/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
export function AccountUMProvider() {
	const [
		{ fetched: isAccountFetched, data: accountData, error: fetchingAccountError },
		getAccount
	] = useAccount();
	const ldclient = useLDClient();
	const navigate = useNavigate();

	const currentUserQuery = useCurrentUserQuery();
	const isLoggedIn = Boolean(currentUserQuery.data);

	const [
		{
			fetched: isSubscriptionFetched,
			loading: subscriptionLoading,
			error: subscriptionError,
			data: { details: subscriptionDetails }
		},
		getSubscription
	] = useSubscription();

	useEffect(() => {
		if (fetchingAccountError) {
			navigate(ROUTE_MAP.auth.logout.createPath());
		}
	}, [fetchingAccountError]);

	useEffect(() => {
		if (!isAccountFetched && isLoggedIn) {
			getAccount();
		}
	}, [isAccountFetched, isLoggedIn]);

	useEffect(() => {
		if (isAccountFetched && isLoggedIn) {
			if (ldclient) {
				const context: any = ldclient.getContext();

				if (context) {
					context.user.name = accountData?.details?.userid;
					context.user.firstName = accountData?.details?.userFirstName;
					context.user.lastName = accountData?.details?.userFirstName;
					context.user.email = accountData?.details?.emailAddress;
					if (subscriptionDetails)
						context.user.subscriptionId = subscriptionDetails.subscriptionId;
				}

				localStorage.setItem('LDContext', JSON.stringify(context));

				ldclient.identify(context);
			}
		}
	}, [isAccountFetched, subscriptionDetails, isLoggedIn]);

	useEffect(() => {
		if (!isSubscriptionFetched && !subscriptionLoading && !subscriptionError && isLoggedIn) {
			getSubscription();
		}
	}, [isSubscriptionFetched, subscriptionError, subscriptionLoading, isLoggedIn]);

	return null;
}
