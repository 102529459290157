import { Loader } from 'components/UI/Loader';
import { useAuth } from 'features/auth/AuthProvider';
import {
	MILLIS_BEFORE_LOGOUT,
	STORAGE_LAST_ACTIVITY_KEY
} from 'features/auth/auto-logout/InactivityDetector';
import { useEffect } from 'react';
import { isProfileIncomplete, useGetUserQuery } from 'features/data/useGetUserQuery';
import { E2E_TEST_USERS } from 'features/consts/e2e-consts';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'pages/Logout/utils';

export function ProtectedRoutes() {
	const { mfaPreferenceQuery, currentUserQuery } = useAuth();
	const userQuery = useGetUserQuery({
		enabled: Boolean(currentUserQuery.data)
	});

	const { hasCheckedForInactivity } = useCheckForInactivity();

	const hasEnabledMfa = Boolean(
		mfaPreferenceQuery.data?.enabled && mfaPreferenceQuery.data.enabled.length > 0
	);
	const isExemptFromMFA = E2E_TEST_USERS.includes(currentUserQuery.data?.username ?? '');

	const loading =
		currentUserQuery.isLoading ||
		userQuery.isLoading ||
		mfaPreferenceQuery.isLoading ||
		!hasCheckedForInactivity;

	if (loading) {
		return <Loader primary />;
	}

	return (
		<ProtectedRoutesInner
			isLoggedIn={Boolean(currentUserQuery.data)}
			hasEnabledMfa={hasEnabledMfa}
			loginFlow={currentUserQuery.data?.signInDetails ? 'password' : 'sso'}
			isExemptFromMFA={isExemptFromMFA}
			isProfileIncomplete={isProfileIncomplete(userQuery.data?.user)}
		/>
	);
}

function ProtectedRoutesInner({
	isLoggedIn,
	loginFlow,
	hasEnabledMfa,
	isExemptFromMFA,
	isProfileIncomplete
}: {
	isLoggedIn: boolean;
	loginFlow: 'sso' | 'password';
	hasEnabledMfa: boolean;
	isExemptFromMFA: boolean;
	isProfileIncomplete: boolean;
}) {
	const currentPath = useLocation().pathname;

	if (!isLoggedIn) {
		return <Navigate to={ROUTE_MAP.auth.login.path} />;
	}

	if (loginFlow !== 'sso') {
		if (!hasEnabledMfa) {
			if (isExemptFromMFA) {
				return <Outlet />;
			}

			if (!currentPath.includes(ROUTE_MAP.auth.setupTotp.path)) {
				return <Navigate to={ROUTE_MAP.auth.setupTotp.path} />;
			}

			// Important to return the outlet here, if not we get a infinite redirect loop
			return <Outlet />;
		}

		if (hasEnabledMfa && currentPath.includes(ROUTE_MAP.auth.setupTotp.path)) {
			return <Navigate to={ROUTE_MAP.projects.path} />;
		}
	}

	if (isProfileIncomplete && !currentPath.includes(ROUTE_MAP.auth.setupAccountInfo.path)) {
		return <Navigate to={ROUTE_MAP.auth.setupAccountInfo.path} />;
	}
	if (!isProfileIncomplete && currentPath.includes(ROUTE_MAP.auth.setupAccountInfo.path)) {
		return <Navigate to={ROUTE_MAP.projects.path} />;
	}

	return <Outlet />;
}

const useCheckForInactivity = () => {
	const navigate = useNavigate();

	const [hasCheckedForInactivity, setHasCheckedForInactivity] = useState(false);
	useEffect(() => {
		const checkForInactivity = async () => {
			const lastActivityTimeStamp = localStorage.getItem(STORAGE_LAST_ACTIVITY_KEY);

			if (!lastActivityTimeStamp) {
				return setHasCheckedForInactivity(true);
			}

			const millisSinceLastActivity = Date.now() - parseInt(lastActivityTimeStamp);

			if (millisSinceLastActivity > MILLIS_BEFORE_LOGOUT) {
				await logout();

				navigate(ROUTE_MAP.auth.login.createPath());
			} else {
				setHasCheckedForInactivity(true);
			}
		};

		checkForInactivity();
	}, [navigate, logout]);

	return { hasCheckedForInactivity };
};
