import { useEffect, useState } from 'react';
import { Dialog } from '../component/Dialog';
import { Button } from '../component/Button';
import { SeriesOverviewRevamp } from './steps/SeriesOverviewRevamp';
import { ConsolidateActionbar } from './steps/ConsolidateActionbar';
import { ProjectName } from './steps/ProjectName';
import { Introduction } from './steps/Introduction';
import { SeriesOverviewWithData } from './steps/SeriesOverviewWithData';
import { SeriesEntryFormOverhaul } from './steps/SeriesEntryFormOverhaul';
import { Summary } from './steps/Summary';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Statuses } from './steps/Statuses';
import { ConfirmChanges } from './steps/ConfirmChanges';
import { SeriesEntryOrdering } from './steps/SeriesEntryOrdering';
import { useEntryV15Release } from '../utils/isV1_5project';
import { getCurrentUser } from '@aws-amplify/auth';
import { E2E_TEST_USERS } from 'features/consts/e2e-consts';

export const V1_5_ChangelogDialog = () => {
	const entryV15Release = useEntryV15Release();

	const [show, setShow] = useState(false);

	useEffect(() => {
		const showDialogIfFeasible = async () => {
			const user = await getCurrentUser();
			const isUserExemptedFromChangelog = E2E_TEST_USERS.includes(user.username);

			// It's very annoying to see the changelog on every new preview
			if (isPreviewUrl() || isUserExemptedFromChangelog) {
				return;
			}

			const hasDismissed = localStorage.getItem(CHANGELOG_DISMISSED_KEY);
			if (!hasDismissed) {
				// also check if user is created after release date
				setShow(true);
			}
		};

		showDialogIfFeasible();
	}, []);

	if (entryV15Release !== 'fullyEnabled') {
		return null;
	}

	return (
		<Dialog
			open={show}
			onClose={() => {
				setShow(false);
				localStorage.setItem(CHANGELOG_DISMISSED_KEY, 'true');
			}}
			title="Change Log"
		>
			<Changelog
				onFinish={() => {
					setShow(false);
					localStorage.setItem(CHANGELOG_DISMISSED_KEY, 'true');
				}}
			/>
		</Dialog>
	);
};

const isPreviewUrl = () => {
	const regex = /^https:\/\/pr-\d+\.d2pdobwlnouhyc\.amplifyapp\.com\/login$/;
	return regex.test(window.location.href);
};

const Changelog = ({ onFinish }: { onFinish: () => void }) => {
	const { track } = useTracking();

	const [stepIndex, setStepIndex] = useState(0);
	const step = STEPS[stepIndex];

	return (
		<div className="w-[600px] h-[600px] flex flex-col relative">
			<div className="flex flex-col grow">
				{step === 'introduction' && <Introduction />}

				{step === 'consolidate-action-bar' && <ConsolidateActionbar />}

				{step === 'confirm-changes' && <ConfirmChanges />}

				{step === 'show-project-name-in-navbar' && <ProjectName />}

				{step === 'series-overview_how-to-access' && <SeriesOverviewRevamp />}

				{step === 'series-overview_with-data' && <SeriesOverviewWithData />}

				{step === 'series-entry-form-overhaul' && <SeriesEntryFormOverhaul />}

				{step === 'series-entry-ordering' && <SeriesEntryOrdering />}

				{step === 'statuses' && <Statuses />}

				{step === 'summary' && (
					<Summary
						onFinish={onFinish}
						onBack={() => {
							setStepIndex(stepIndex - 1);
						}}
					/>
				)}
			</div>

			<div className="absolute bottom-0 left-0 p-4">
				Step {stepIndex + 1} of {STEPS.length}
			</div>

			{step !== 'summary' && (
				<div className="flex gap-10 justify-end">
					{stepIndex > 0 && (
						<Button
							title="Previous"
							variant="secondary"
							onClick={() => {
								setStepIndex(stepIndex - 1);
							}}
						/>
					)}

					{stepIndex < STEPS.length - 1 && (
						<Button
							title="Next"
							onClick={() => {
								setStepIndex(stepIndex + 1);

								const nextStep = STEPS[stepIndex + 1];

								track({
									eventName: `entry_form_v1.5_changelog_step_viewed_${nextStep}`
								});
							}}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export const CHANGELOG_DISMISSED_KEY = 'entry_form_v1.5_changelog_dismissed_2025-03-11';

const STEPS = [
	'introduction',
	'consolidate-action-bar',
	'show-project-name-in-navbar',
	'confirm-changes',
	'series-overview_how-to-access',
	'series-overview_with-data',
	'series-entry-form-overhaul',
	'series-entry-ordering',
	'statuses',
	'summary'
] as const;
