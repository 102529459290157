import { useCallback, useState } from 'react';

import { ROUTES } from 'types/navigation';
import { NotificationDrawer } from 'app/Notifications';
import { Icon, NotificationIcon } from 'components/UI/Icons';
import { Colors, MediaQueries, Svgs } from 'environment';
import { renderComponent } from '../helpers';
import { User } from './User';
import { Container, LeftContainer, Tab, Tabs, Title } from './HeaderMain.style';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { stringAsBoolean } from 'helpers/generic';
import { openNewTabSubscriptionPage } from 'helpers/navigation';
import {
	useNavigation,
	useRouteMatch,
	useIsRouteWithProjectId,
	useMatchEnterpriseAdmin
} from 'hooks/navigation';
import {
	useTranslation,
	useProject,
	useNotificationsBatch,
	useSubscriptionRules,
	useScopedProjects
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { useResetProjectValidity } from 'hooks/store/data/projects/useResetProjectValidity';
import { useLocation } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useGetIsUserAdminQuery } from 'features/data/useGetIsUserAdminQuery';

interface Props {
	leftComponent?: React.ReactNode | null;
	rightComponent?: React.ReactNode | null;
	onLogoClick?: () => void;
	fullWidth?: boolean;
}

const ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ADMIN_PATH_ROOT}`;
const SHOW_NOTIFICATIONS = stringAsBoolean(process.env.REACT_APP_USE_NOTIFICATIONS!);

export function HeaderMain({ leftComponent, rightComponent, onLogoClick, fullWidth }: Props) {
	const { routes, navigate } = useNavigation();
	const location = useLocation();
	const { translate } = useTranslation();

	const logout = async () => {
		navigate(ROUTE_MAP.auth.logout.createPath());
	};

	const [{ data: project }] = useProject();
	const [_, fetchProjects] = useScopedProjects({ lazy: true });
	const resetProjectValidity = useResetProjectValidity();

	const { isRouteWithProjectId } = useIsRouteWithProjectId();

	const { showUpgradeButton, showEnterpriseAdminRoute } = useSubscriptionRules();

	const isOnProjectsListRoute = useRouteMatch(ROUTES.Projects);
	const isOnAdminUsersRoute = useRouteMatch(ADMIN_PATH_ROOT + ROUTES.AdminUsers);
	const isOnAdminEnterpriseRoute = useRouteMatch(ADMIN_PATH_ROOT + ROUTES.AdminEnterprise);
	const isOnHelpPageRoute = useRouteMatch(ROUTES.Help);
	const isOnTemplateRolesRoute = useRouteMatch(ROUTES.TemplateRoles);

	const isOnEnterpriseAdminRoutes = useMatchEnterpriseAdmin();

	const isOnProjectsRoute = isOnProjectsListRoute;

	const { data: isUserAdmin } = useGetIsUserAdminQuery();

	const [showNotifications, setShowNotifications] = useState(false);

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg}`
	);
	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const [
		{
			data: { unreadCount }
		}
	] = useNotificationsBatch();

	function goToHelpPage() {
		navigate(routes.help);
	}

	function goToList() {
		const listRoute = routes.projects.list;

		if (listRoute !== location.pathname) {
			resetProjectValidity();

			fetchProjects();
		}
		navigate(listRoute);
	}

	function getProjectTitle() {
		return <Title>{project?.projectName}</Title>;
	}

	const onCloseNotificationDrawer = useCallback(() => {
		setShowNotifications(false);
	}, []);

	function getNavigationTabs() {
		return (
			!isPhone && (
				<Tabs>
					<Tab
						to={routes.projects.list}
						$active={isOnProjectsListRoute}
						title={translate(({ iconTooltip }) => iconTooltip.menuProjects)}
					>
						<Icon className="navigation-tab" svg={Svgs.MenuProjects} propagate />
					</Tab>

					<Tab
						to={routes.templateRoles}
						$active={isOnTemplateRolesRoute}
						title={translate(
							({ accountUM }) => accountUM.userDrawer.views.roleTemplates
						)}
					>
						<Icon
							title={translate(
								({ accountUM }) => accountUM.userDrawer.views.roleTemplates
							)}
							className="navigation-tab"
							svg={Svgs.Package}
							propagate
						/>
					</Tab>
					{showEnterpriseAdminRoute && (
						<Tab
							to={routes.enterpriseAdmin.subscription}
							$active={isOnEnterpriseAdminRoutes}
							title={translate(({ enterpriseAdmin }) => enterpriseAdmin.header.title)}
						>
							<Icon
								title={translate(
									({ enterpriseAdmin }) => enterpriseAdmin.header.title
								)}
								className="navigation-tab"
								svg={Svgs.MenuAdmin}
								propagate
								dataTestId="main-navigation-enterprise-admin"
							/>
						</Tab>
					)}
					{isUserAdmin && (
						<Tab
							title={translate(({ admin }) => admin.users.list.title)}
							to={routes.admin.view}
							$active={isOnAdminUsersRoute || isOnAdminEnterpriseRoute}
						>
							<Icon className="navigation-tab" svg={Svgs.MenuAdmin} propagate />
						</Tab>
					)}
				</Tabs>
			)
		);
	}

	function getLeftComponent() {
		// CUSTOM COMPONENT
		if (leftComponent !== undefined) {
			// EMPTY COMPONENT - OVERWRITE DEFAULT (LEAVE EMPTY)
			if (leftComponent === null) return;

			const isLeftComponentText = typeof leftComponent === 'string';

			return isLeftComponentText ? (
				<Title title={leftComponent as string}>{leftComponent}</Title>
			) : (
				<LeftContainer>{leftComponent}</LeftContainer>
			);
		}

		// LIST ROUTE - DISPLAY PROJECT/PROM/ADMIN NAVIGATION TABS
		if (
			isOnProjectsRoute ||
			isOnHelpPageRoute ||
			isOnAdminUsersRoute ||
			isOnTemplateRolesRoute ||
			isOnAdminEnterpriseRoute ||
			isOnEnterpriseAdminRoutes
		) {
			return getNavigationTabs();
		}

		// PROJECT ROUTE - DISPLAY PROJECT TITLE
		if (isRouteWithProjectId) return getProjectTitle();
	}

	return (
		<Container className="header-main" fullWidth={fullWidth}>
			{/* MAIN LOGO */}
			<Icon
				svg={Svgs.LedidiLogoGrey}
				customSize={3}
				marginOffset={{ left: -2, right: isPhone ? 0 : 4.2 }}
				onClick={onLogoClick ?? goToList}
				id="main_header_logo"
			/>

			{/* LEFT COMPONENT */}
			{getLeftComponent()}

			{/* RIGHT COMPONENT */}
			{renderComponent(rightComponent) ?? (
				<Flex align={a => a.center} wrap>
					{showUpgradeButton && (
						<Button
							variant={v => v.upgrade}
							title={translate(({ buttons }) => buttons.upgrade)}
							marginOffset={{ right: 2.4 }}
							onClick={() => openNewTabSubscriptionPage(true)}
						/>
					)}

					{!isPhone && (
						<Icon
							title={translate(({ helpPage }) => helpPage.headerIcon)}
							svg={Svgs.Help}
							variant={v => v.button}
							marginOffset={{ right: 1.4 }}
							colors={{
								color: isOnHelpPageRoute ? Colors.primary.normal : undefined,
								hover: Colors.primary.normal,
								hoverBackground: Colors.primary.disabled
							}}
							onClick={goToHelpPage}
						/>
					)}
					{SHOW_NOTIFICATIONS && (
						<>
							<NotificationIcon
								propagate={false}
								count={unreadCount}
								marginOffset={{ right: 2.4 }}
								active={showNotifications}
								onClick={() => {
									setShowNotifications(value => !value);
								}}
							/>
							<NotificationDrawer
								open={showNotifications}
								onClose={onCloseNotificationDrawer}
							/>
						</>
					)}
					{!isMobileDevice && (
						<Button
							variant={v => v.link}
							title={translate(({ buttons }) => buttons.logOut)}
							marginOffset={{ right: 2.4 }}
							onClick={logout}
						/>
					)}
					<User />
				</Flex>
			)}
		</Container>
	);
}
