import { useEffect, useState } from 'react';
import { Layout, LoginBackLinkStyle } from './Layout';
import { setUpTOTP, signOut, updateMFAPreference, verifyTOTPSetup } from '@aws-amplify/auth';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { Skeleton } from 'features/entry-form-v2/component/Skeleton';
import QRCode from 'qrcode.react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { useSelector } from 'hooks/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'features/entry-form-v2/component/Input';
import { Button } from 'features/entry-form-v2/component/Button';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useNavigate } from 'react-router-dom';
import { env } from 'features/env';
import { useAuth } from './AuthProvider';

export const SetupTotpPage = () => {
	const navigate = useNavigate();
	const currentUser = useCurrentUserQuery();
	const [totpSecret, setTotpSecret] = useState<string>();

	const { language } = useSelector(state => state.ui.i18n);
	const translation = DICTIONARY[language];

	const { mfaPreferenceQuery } = useAuth();

	const form = useForm<{ code: string }>({
		defaultValues: {
			code: ''
		},
		resolver: zodResolver(createSchema(translation))
	});

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (data: { code: string }) => {
		try {
			setIsLoading(true);
			await verifyTOTPSetup({
				code: data.code
			});

			await updateMFAPreference({
				totp: 'PREFERRED'
			});

			await mfaPreferenceQuery.refetch();

			navigate(ROUTE_MAP.projects.path);
		} catch (error) {
			if (isEnableSoftwareTokenMFAException(error)) {
				return form.setError('code', { message: translation.errors.codeMismatch });
			}

			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const setupTOTPasd = async () => {
			if (!currentUser.data?.username || !!totpSecret) return;

			const setupResult = await setUpTOTP();
			const appName =
				env.REACT_APP_ENVIRONMENT === 'production' ? 'Ledidi' : 'Ledidi (Systest)';
			const setupUri = setupResult.getSetupUri(appName, currentUser.data?.username);
			setTotpSecret(setupUri.toString());
		};

		setupTOTPasd().catch(console.error);
	}, [currentUser]);

	return (
		<Layout title={translation.title}>
			<form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
				<p className="text-base mb-10 text-center">{translation.description}</p>

				{!totpSecret && (
					<Skeleton className="w-[128px] mb-10 aspect-square rounded-sm self-center" />
				)}

				{totpSecret && <QRCode value={totpSecret} className="self-center mb-10" />}

				<Input
					id="code"
					className="mb-6"
					label={translation.codeInput.label}
					placeholder={translation.codeInput.placeholder}
					maxLength={6}
					error={form.formState.errors.code?.message}
					{...form.register('code')}
				/>

				<Button
					type="submit"
					className="w-full mb-6"
					loading={isLoading}
					title={translation.confirmButton}
				/>

				<button
					className={LoginBackLinkStyle}
					onClick={async () => {
						await signOut();
						navigate(ROUTE_MAP.auth.login.path);
					}}
				>
					{translation.backButton}
				</button>
			</form>
		</Layout>
	);
};

const createSchema = (t: Translation) =>
	z.object({
		code: z.string().min(6, t.codeInput.validation).max(6, t.codeInput.validation)
	});

const DICTIONARY = {
	EN: {
		title: 'Two-factor authentication',
		description:
			'Scan this with a smartphone MFA app, like Google Authenticator, then input the generated code',

		codeInput: {
			label: 'MFA Authentication Code',
			placeholder: 'Enter the code',
			validation: 'Code must be 6 digits'
		},

		confirmButton: 'Confirm',
		backButton: 'Back to login',

		errors: {
			codeMismatch: 'Code is invalid'
		}
	},

	NB: {
		title: 'To-faktor-autentisering',
		description: 'Scan QR-koden med din autentiseringsapp',

		codeInput: {
			label: 'Skriv inn koden fra din autentiseringsapp',
			placeholder: 'Skriv inn koden',
			validation: 'Koden må være 6 siffer'
		},

		confirmButton: 'Bekreft',
		backButton: 'Tilbake til innlogging',

		errors: {
			codeMismatch: 'Koden er ugyldig'
		}
	}
} as const;
type Translation = (typeof DICTIONARY)[keyof typeof DICTIONARY];

const isEnableSoftwareTokenMFAException = (error: any) => {
	return error.name === 'EnableSoftwareTokenMFAException';
};
