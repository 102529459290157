import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

export type GetUserResponse = {
	user: User;
};

export const useGetUserQuery = (props: Partial<UseQueryOptions<GetUserResponse>> = {}) => {
	const query = useQuery<GetUserResponse>({
		queryKey: [GET_USER_QUERY_KEY],
		queryFn: async () =>
			makeRequest<GetUserResponse>({
				service: 'user',
				method: GET_USER_METHOD
			}),
		...props
	});

	return {
		...query,
		isLoading: query.isLoading && query.fetchStatus !== 'idle'
	};
};

export const GET_USER_METHOD = 'getUser';

export type User = {
	userid: string;
	userFirstName: string | null;
	userSirName: string | null;
	emailAddress: string;
	organization: string;
	department: string | null;
	phoneNumber: string | null;
	isAdmin: boolean;
	workplace: string | null;
	city: string;
	country: string;
	position: string;
};

export const isProfileIncomplete = (user?: User) => {
	if (!user) {
		return false;
	}

	return (
		!user.userFirstName ||
		!user.userSirName ||
		!user.phoneNumber ||
		!user.workplace ||
		!user.department
	);
};

export const GET_USER_QUERY_KEY = 'user';

export const createStubGetUserResponse = (user: Partial<User>): GetUserResponse => ({
	user: {
		userid: 'test-user-id',
		userFirstName: 'Test',
		userSirName: 'User',
		emailAddress: 'test@example.com',
		organization: 'Test Organization',
		department: 'Test Department',
		phoneNumber: '1234567890',
		isAdmin: false,
		workplace: 'Test Workplace',
		city: 'Test City',
		country: 'Test Country',
		position: 'Test Position',
		...user
	}
});
