import { fetchAuthSession } from '@aws-amplify/core';
import { InfoCircleIcon } from '@icons';
import clsx from 'clsx';
import { useSelector } from 'hooks/utils';
import { useState } from 'react';
import { toast } from 'react-toastify';

export function Security() {
	const language = useSelector(state => state.ui.i18n.language);

	const translation = DICTIONARY[language];

	const [isCopying, setIsCopying] = useState(false);
	const copyTokenToClipboard = async () => {
		setIsCopying(true);

		try {
			const session = await fetchAuthSession();

			if (!session.tokens?.idToken) {
				throw new Error('No token found');
			}

			navigator.clipboard.writeText(session.tokens.idToken.toString());

			toast.success(translation.copyTokenToClipboard, {
				position: 'top-center'
			});
		} catch (error) {
			toast.error(translation.copyTokenToClipboardError);
			console.error(error);
		} finally {
			setIsCopying(false);
		}
	};

	return (
		<div className="flex flex-col justify-end items-end px-[24px] h-full">
			<div className="group w-[24px] h-[24px]">
				<button
					className={clsx('w-full h-full hidden group-hover:block', {
						'opacity-50': isCopying
					})}
					onClick={copyTokenToClipboard}
					disabled={isCopying}
				>
					<InfoCircleIcon className="w-full h-full text-black " />
				</button>
			</div>
		</div>
	);
}

const DICTIONARY = {
	EN: {
		copyTokenToClipboard: 'Token copied to clipboard',
		copyTokenToClipboardError: 'Failed to copy token to clipboard'
	},

	NB: {
		copyTokenToClipboard: 'Token kopiert',
		copyTokenToClipboardError: 'Feil ved kopi av token'
	}
} as const;
