import { Layout, LoginBackLinkStyle } from './Layout';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'features/entry-form-v2/component/Input';
import { useSelector } from 'hooks/utils';
import { resetPassword } from '@aws-amplify/auth';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'features/entry-form-v2/component/Button';
import { isLimitExceededException } from './amplify/amplify-errors';

export function ForgotPasswordPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];
	const [searchParams] = useSearchParams();

	const navigateToNextStep = useNavigateToNextStep();

	const [isSendingResetCode, setIsSendingResetCode] = useState(false);

	const form = useForm({
		defaultValues: {
			username: searchParams.get('username') ?? ''
		},
		resolver: zodResolver(createSchema(translation))
	});

	const onSubmit = async (data: FormData) => {
		try {
			setIsSendingResetCode(true);

			const result = await resetPassword({
				username: data.username
			});

			navigateToNextStep({
				step: result.nextStep.resetPasswordStep,
				username: encodeURIComponent(data.username)
			});
		} catch (error) {
			if (isLimitExceededException(error)) {
				return form.setError('username', { message: translation.errors.limitExceeded });
			}

			form.setError('username', { message: translation.errors.unknown });
			console.error(error);
		} finally {
			setIsSendingResetCode(false);
		}
	};

	return (
		<Layout title={translation.title}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
				<Input
					className="mb-6"
					label={translation.usernameInput.label}
					id={'username'}
					autoFocus
					autoComplete="username"
					error={form.formState.errors.username?.message}
					{...form.register('username')}
				/>

				<Button
					className="mb-6"
					type="submit"
					title={translation.resetPasswordButtonText}
					loading={isSendingResetCode}
				/>

				<Link to={ROUTE_MAP.auth.login.path} className={LoginBackLinkStyle}>
					{translation.goBack}
				</Link>
			</form>
		</Layout>
	);
}

const createSchema = (translation: Translation) =>
	z.object({
		username: z.string().min(1, translation.usernameInput.validation.username)
	});

type FormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		title: 'Forgot password',
		usernameInput: {
			label: 'Username or email',
			validation: {
				username: 'Username or email is required'
			}
		},
		resetPasswordButtonText: 'Reset password',
		goBack: 'Go back',

		errors: {
			limitExceeded: 'Too many attempts, please try again later',
			unknown: 'An error occured while resetting password, please try again'
		}
	},

	NB: {
		title: 'Glemt passord',
		usernameInput: {
			label: 'Brukernavn eller e-post',
			validation: {
				username: 'Brukernavn eller e-post er påkrevd'
			}
		},

		resetPasswordButtonText: 'Tilbakestill passord',
		goBack: 'Gå tilbake',

		errors: {
			limitExceeded: 'For mange forsøk, vennligst prøv igjen senere',
			unknown: 'En feil oppsto under tilbakestilling av passord, vennligst prøv igjen'
		}
	}
} as const;

type Translation = (typeof DICTIONARY)[keyof typeof DICTIONARY];
