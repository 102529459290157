import { useNavigate } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useAuth } from 'features/auth/AuthProvider';

export function useNavigateToNextStep() {
	const navigate = useNavigate();

	const { currentUserQuery, mfaPreferenceQuery } = useAuth();

	const navigateToNextStep = async ({
		step,
		username
	}: {
		step: AuthStep | string;
		username?: string;
	}) => {
		const unhandledError = new Error(`${step} is an unhandled step`);
		if (!isAuthStep(step)) {
			throw unhandledError;
		}

		if (step === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
			return navigate(ROUTE_MAP.auth.confirmTotp.createPath());
		}

		if (step === 'CONFIRM_SIGN_UP') {
			throw new Error('CONFIRM_SIGN_UP is an unhandled step');
		}

		if (step === 'RESET_PASSWORD') {
			throw new Error('DONE is an unhandled step');
		}

		if (step === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
			return navigate(ROUTE_MAP.auth.confirmResetPassword.createPath({ username }));
		}

		if (step === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
			return navigate(ROUTE_MAP.auth.newPasswordRequired.createPath({ username }));
		}

		if (step === 'DONE' || step === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
			await Promise.all([currentUserQuery.refetch(), mfaPreferenceQuery.refetch()]);
			navigate(ROUTE_MAP.projects.path);
			return;
		}

		step satisfies never;
	};

	return navigateToNextStep;
}

export const authSteps = [
	'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
	'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
	'CONFIRM_SIGN_UP',
	'RESET_PASSWORD',
	'CONFIRM_RESET_PASSWORD_WITH_CODE',
	'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
	'DONE'
] as const;
export type AuthStep = (typeof authSteps)[number];
export const isAuthStep = (step: string): step is AuthStep => authSteps.includes(step as AuthStep);
