export const SSO_PROVIDERS = ['sykehuspartner', 'ledidissotest', 'cmr', 'cornell'] as const;
export type SSOProvider = (typeof SSO_PROVIDERS)[number];

export const isSSOProvider = (provider: string): provider is SSOProvider => {
	return SSO_PROVIDERS.includes(provider as SSOProvider);
};

type SSOProviderMessages = {
	[Provider in SSOProvider]: Record<string, string>;
};

export const getErrorMessage = (
	provider: SSOProvider,
	errorCode: string,
	lang: 'EN' | 'NB'
): string | undefined => {
	const messages = DICTIONARY[lang][provider];

	if (!messages) {
		return undefined;
	}

	const message: string | undefined = messages[errorCode];
	return message;
};

const DICTIONARY: Record<string, SSOProviderMessages> = {
	EN: {
		sykehuspartner: {
			missing_email: 'Your user account is missing an email address.',
			missing_phone_number: 'Your user account is missing a phone number.',
			invalid_phone_number_format:
				"Your user account's phone number is missing a country code. Please update it to include the country code. Example: +4712345890."
		},
		cmr: {},
		ledidissotest: {
			missing_email: 'Your user account is missing an email address.',
			missing_phone_number: 'Your user account is missing a phone number.',
			invalid_phone_number_format:
				"Your user account's phone number is missing a country code. Please update it to include the country code. Example: +4712345890."
		},
		cornell: {}
	},
	NB: {
		sykehuspartner: {
			missing_email: 'Din brukerkonto mangler e-postadresse.',
			missing_phone_number: 'Din brukerkonto mangler telefonnummer.',
			invalid_phone_number_format:
				'Din brukerkontos telefonnummer mangler landskode. Vennligst oppdater det til å inkludere landskode. Eksempel: +4712345890.'
		},
		cmr: {},
		ledidissotest: {
			missing_email: 'Din brukerkonto mangler e-postadresse.',
			missing_phone_number: 'Din brukerkonto mangler telefonnummer.',
			invalid_phone_number_format:
				'Din brukerkontos telefonnummer mangler landskode. Vennligst oppdater det til å inkludere landskode. Eksempel: +4712345890.'
		},
		cornell: {}
	}
} as const;
