import { z } from 'zod';
import { Layout } from './Layout';
import { Input } from 'features/entry-form-v2/component/Input';
import { Button } from 'features/entry-form-v2/component/Button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useSelector } from 'hooks/utils';
import { confirmResetPassword, signIn } from '@aws-amplify/auth';
import { useState } from 'react';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import {
	isUserNotConfirmedException,
	isExpiredCodeException,
	isInvalidCodeError
} from './amplify/amplify-errors';

export const ConfirmResetPassword = () => {
	const [searchParams] = useSearchParams();
	const username = searchParams.get('username') ?? '';

	const { language } = useSelector(state => state.ui.i18n);
	const dictionary = DICTIONARY[language];

	const navigateWithRedirect = useNavigateToNextStep();

	const form = useForm<ResetPasswordFormData>({
		resolver: zodResolver(createSchema(dictionary))
	});

	const [isResettingPassword, setIsResettingPassword] = useState(false);

	const onSubmit = async (data: ResetPasswordFormData) => {
		if (!username) {
			console.error('user not available, this is a no-op');
			return;
		}

		try {
			setIsResettingPassword(true);

			await confirmResetPassword({
				confirmationCode: data.code,
				newPassword: data.newPassword,
				username
			});

			const signinResult = await signIn({
				username,
				password: data.newPassword
			});

			navigateWithRedirect({
				step: signinResult.nextStep.signInStep,
				username: encodeURIComponent(username)
			});
		} catch (error) {
			if (isInvalidCodeError(error)) {
				return form.setError('code', { message: dictionary.codeInput.validation.mismatch });
			}

			if (isExpiredCodeException(error)) {
				return form.setError('code', { message: dictionary.errors.expiredCode });
			}

			if (isUserNotConfirmedException(error)) {
				return form.setError('code', { message: dictionary.errors.expiredCode });
			}

			form.setError('confirmNewPassword', { message: dictionary.errors.unknown });
			console.error(error);
		} finally {
			setIsResettingPassword(false);
		}
	};

	return (
		<Layout>
			<form
				className="flex flex-col"
				onSubmit={form.handleSubmit(onSubmit)}
				title={dictionary.title}
			>
				<Input
					label={dictionary.codeInput.label}
					placeholder={dictionary.codeInput.placeholder}
					id="code"
					{...form.register('code')}
					className="mt-10"
					error={form.formState.errors.code?.message}
				/>
				<Input
					label={dictionary.newPasswordInput.label}
					placeholder={dictionary.newPasswordInput.placeholder}
					id="newPassword"
					type="password"
					{...form.register('newPassword')}
					className="mt-4"
					error={form.formState.errors.newPassword?.message}
				/>
				<Input
					label={dictionary.confirmNewPasswordInput.label}
					placeholder={dictionary.confirmNewPasswordInput.placeholder}
					id="confirmNewPassword"
					type="password"
					className="mt-4"
					{...form.register('confirmNewPassword')}
					error={form.formState.errors.confirmNewPassword?.message}
				/>

				<Button
					title={dictionary.updatePasswordButtonText}
					className="mt-6"
					loading={isResettingPassword}
				/>

				<Link
					to={ROUTE_MAP.auth.login.path}
					className="text-sm font-semibold text-center mt-6 hover:underline"
				>
					{dictionary.backToLoginButtonText}
				</Link>
			</form>
		</Layout>
	);
};

const createSchema = (t: Dictionary) =>
	z
		.object({
			code: z.string().min(1, t.codeInput.validation.code),
			newPassword: z
				.string()
				.min(16, t.newPasswordInput.validation.required)
				.regex(/[a-z]/, { message: t.newPasswordInput.validation.missingLowerCase })
				.regex(/[A-Z]/, { message: t.newPasswordInput.validation.missingUpperCase })
				.regex(/[0-9]/, { message: t.newPasswordInput.validation.missingNumber }),
			confirmNewPassword: z.string().min(1, t.confirmNewPasswordInput.validation.required)
		})
		.refine(data => data.newPassword === data.confirmNewPassword, {
			path: ['confirmNewPassword'],
			message: t.confirmNewPasswordInput.validation.passwordsMustMatch
		});
type ResetPasswordFormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		title: 'Reset password',

		codeInput: {
			label: 'Code',
			placeholder: 'Enter the code from the email we sent you',
			validation: {
				code: 'Code is required',
				mismatch: 'The code you entered is incorrect'
			}
		},

		newPasswordInput: {
			label: 'New password',
			placeholder: 'Enter new password',
			validation: {
				required: 'New password must be at least 16 characters long',
				missingLowerCase: 'At least a lowercase character',
				missingUpperCase: 'At least an uppercase character',
				missingNumber: 'At least a digit'
			}
		},

		confirmNewPasswordInput: {
			label: 'Confirm new password',
			placeholder: 'Confirm new password',
			validation: {
				required: 'Confirm new password is required',
				missingLowerCase: 'At least a lowercase character',
				missingUpperCase: 'At least an uppercase character',
				missingNumber: 'At least a digit',
				passwordsMustMatch: 'Passwords must match'
			}
		},

		updatePasswordButtonText: 'Update password',
		backToLoginButtonText: 'Back to login',

		errors: {
			unknown: 'An error occured while resetting password, please try again',
			expiredCode: 'The code has expired, please request a new code'
		}
	},

	NB: {
		title: 'Tilbakestill passord',

		codeInput: {
			label: 'Kode fra e-post',
			placeholder: 'Skriv inn koden',
			validation: {
				code: 'Kode er påkrevd',
				mismatch: 'Koden du skrev inn er feil'
			}
		},

		newPasswordInput: {
			label: 'Nytt passord',
			placeholder: 'Skriv inn nytt passord',
			validation: {
				required: 'Nytt passord er påkrevd',
				missingLowerCase: 'Minst én liten bokstav',
				missingUpperCase: 'Minst én stor bokstav',
				missingNumber: 'Minst ett tall'
			}
		},

		confirmNewPasswordInput: {
			label: 'Bekreft nytt passord',
			placeholder: 'Bekreft nytt passord',
			validation: {
				required: 'Bekreft nytt passord er påkrevd',
				passwordsMustMatch: 'Passordene må være like'
			}
		},

		updatePasswordButtonText: 'Oppdater passord',
		backToLoginButtonText: 'Tilbake til innlogging',
		errors: {
			unknown: 'En feil oppsto under tilbakestilling av passord, vennligst prøv igjen',
			expiredCode: 'Koden har utløpt, vennligst be om en ny kode'
		}
	}
} as const;

type Dictionary = (typeof DICTIONARY)[keyof typeof DICTIONARY];
