import { useState } from 'react';
import { TemplateRoleCard, ShareRoleTemplateModal } from 'components/Roles';
import { DeleteTemplateRoleModal } from 'components/Roles/DeleteTemplateRoleModal';
import { TemplateRoleModal } from 'components/Roles/TemplateRoleModal';
import { Svgs } from 'environment';
import { selectTemplateRolesPageActiveTab } from 'store/data/roles';
import { TemplateRole, TemplateRolesHeaderTab } from 'store/data/roles/types';
import { RolesTemplatePageHeader } from './TemplateRolesPageHeader';
import { CardContainer } from './TemplateRolesPage.style';
import { RoleTemplateShareLevel } from 'types/index';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { useSelector } from 'hooks/utils';
import { useTranslation, useTemplateRoles } from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';

interface SharePayload {
	templateRole: TemplateRole;
	shareLevel: RoleTemplateShareLevel;
}

export function TemplateRolesPage() {
	const { translate } = useTranslation();
	const [searchTerm, setSearchTerm] = useState('');

	const templateRoleModal = useModalState<TemplateRole>();
	const deleteModal = useModalState<TemplateRole>();
	const shareModal = useModalState<SharePayload>();

	const currentUserQuery = useCurrentUserQuery();

	const activeTabValue = useSelector(state => selectTemplateRolesPageActiveTab(state.data.roles));

	const [
		{
			data: { roles },
			loading: loadingTemplateRoles,
			fetched: areTemplateRolesFetched
		}
	] = useTemplateRoles();

	function hasDataAndOnSameTab(tab: TemplateRolesHeaderTab) {
		const dataByTab = {
			[TemplateRolesHeaderTab.OWNED]: hasOwnedData,
			// [TemplateRolesHeaderTab.ORGANIZATION]: hasPublicData,
			[TemplateRolesHeaderTab.SHARED]: hasSharedData
		};

		return dataByTab[tab];
	}

	function getTemplateRolesByTab(tab: TemplateRolesHeaderTab) {
		const templateRolesByTab = {
			[TemplateRolesHeaderTab.OWNED]: roles.owned,
			[TemplateRolesHeaderTab.SHARED]: roles.shared
			// [TemplateRolesHeaderTab.ORGANIZATION]: roles.public
		};

		return templateRolesByTab[tab];
	}

	function isShareCard() {
		return activeTabValue === TemplateRolesHeaderTab.SHARED;
	}

	const hasOwnedData = roles.owned.length > 0;
	// const hasPublicData = roles.public.length > 0;
	const hasSharedData = roles.shared.length > 0;

	const hasData = hasOwnedData || hasSharedData; // || hasPublicData;

	const suspendProps = {
		loading: loadingTemplateRoles && !areTemplateRolesFetched,
		immediate: !areTemplateRolesFetched
	};

	const noRolesView = (
		<Flex align={a => a.center} column>
			<Spacer size={s => s.xxl} />
			<Svgs.EmptyStatesRoles style={{ minHeight: 240 }} />
			<Spacer size={s => s.m} />
			<Typography.H3>{translate(dict => dict.roles.noRoles.title)}</Typography.H3>

			<Spacer size={s => s.xs} />
			{activeTabValue === 'owned' && (
				<>
					<Typography.Paragraph multiline alignCenter>
						{translate(dict => dict.roles.noRoles.templateDescription)}
					</Typography.Paragraph>
					<Spacer size={s => s.m} />
					<Button
						title={translate(dict => dict.templates.createNewTemplateRole)}
						onClick={templateRoleModal.open}
					/>
				</>
			)}
		</Flex>
	);

	return (
		<>
			<RolesTemplatePageHeader
				hasData={hasData}
				searchTermState={{
					searchTerm,
					setSearchTerm
				}}
				onCreate={templateRoleModal.open}
			/>

			<Suspend loading={suspendProps.loading} immediate={suspendProps.immediate}>
				<Grid.Container>
					<CardContainer>
						{getTemplateRolesByTab(activeTabValue).map(templateRole => (
							<TemplateRoleCard
								key={templateRole.id}
								templateRole={templateRole}
								isShareCard={isShareCard()}
								actions={{
									onEdit: templateRoleModal.open,
									onDelete: deleteModal.open,
									onShare: shareModal.open
								}}
							/>
						))}
					</CardContainer>

					{!hasDataAndOnSameTab(activeTabValue) && <>{noRolesView}</>}
				</Grid.Container>
			</Suspend>

			{templateRoleModal.visible && (
				<TemplateRoleModal
					isShared={isShareCard()}
					templateRole={templateRoleModal.payload}
					onClose={templateRoleModal.close}
				/>
			)}
			{deleteModal.visible && deleteModal.payload && (
				<DeleteTemplateRoleModal
					templateRole={deleteModal.payload}
					onClose={deleteModal.close}
				/>
			)}
			{shareModal.visible && shareModal.payload && (
				<ShareRoleTemplateModal
					currentUserId={currentUserQuery.data?.username ?? ''}
					templateRoleId={shareModal.payload.templateRole.id}
					shareLevel={shareModal.payload.shareLevel}
					onClose={shareModal.close}
				/>
			)}
		</>
	);
}
