import { useMemo } from 'react';
import { isEqual, values } from 'lodash';

import { EntryFilter } from 'api/data/filters';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { BooleanMap, SelectItem } from 'types/index';
import { withMemo } from 'helpers/HOCs';
import { useFilters, useStatuses, useTranslation } from 'hooks/store';
import { useMutableState, useOutsideClick } from 'hooks/utils';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';

function buildSelectedMap(values: string[], selectedValues: (string | null)[] = []) {
	const map: BooleanMap = {};

	values.forEach(value => (map[value] = false));

	if (selectedValues)
		selectedValues.forEach(value => {
			if (value === null) {
				return;
			} else {
				map[value] = true;
			}
		});

	return map;
}

interface Props {
	filter: EntryFilter;
	disabled: boolean;
	tableFilter?: boolean;
	hideFilter: () => void;
	filterContainerRef: React.RefObject<HTMLDivElement>;
}

function Component({ filter, disabled, hideFilter, filterContainerRef, tableFilter }: Props) {
	const { translate } = useTranslation();
	const [
		{
			data: { statuses }
		}
	] = useStatuses({ lazy: true, omitSystemGenerated: false });

	const statusNames = useMemo<string[]>(() => statuses.map(status => status.name), [statuses]);
	const selectItems = useMemo<SelectItem[]>(
		() =>
			statuses.map(status => ({
				label: status.label,
				value: status.name
			})),
		[statuses]
	);

	const [, { updateFilter, deleteDatasetFilter }] = useFilters();

	const [selected, setSelected] = useMutableState(buildSelectedMap(statusNames, filter.values));

	useOutsideClick(() => {
		if (!tableFilter) return;
		hideFilter();
	}, [filterContainerRef]);

	function applyFilter() {
		const map = buildSelectedMap(statusNames, filter.values);

		if (isEqual(map, selected)) return;

		const values = getSelectedValues(selected);

		updateFilter({ filter: { ...filter, values } });
	}

	function onClear() {
		deleteDatasetFilter(filter.itemId);
	}

	function onCheck(value: string) {
		setSelected(state => {
			state[value] = !state[value];
		});
	}

	function onToggleAll(flag: boolean) {
		setSelected(state => {
			Object.keys(state).forEach(key => (state[key] = flag));
		});
	}

	function getSelectedValues(selected: BooleanMap) {
		return Object.keys(selected).filter(key => selected[key]);
	}

	const canUpdate = useMemo(() => {
		if (!values(selected).find(val => !!val)) return false;
		const map = buildSelectedMap(statusNames, filter.values);

		if (isEqual(map, selected)) return false;
		return true;
	}, [statusNames, selected, filter.values]);

	return (
		<>
			<SelectMultiple
				items={selectItems}
				onSelect={item => onCheck(item.value)}
				selectedItems={getSelectedValues(selected)}
				disabled={disabled}
				onToggleAll={onToggleAll}
			/>
			<Flex marginOffset={{ top: 1.6 }} fullWidth>
				<Flex align={align => align.center}>
					<Button
						onClick={onClear}
						size="small"
						title="Clear"
						variant={variant => variant.link}
					/>
				</Flex>
				<Flex fullWidth justify={j => j.end}>
					<Button
						size="small"
						onClick={hideFilter}
						title={translate(dict => dict.buttons.cancel)}
						variant={variant => variant.secondary}
						marginOffset={{ right: 1.6 }}
					/>
					<Button
						size="small"
						disabled={!canUpdate}
						onClick={applyFilter}
						title={translate(dict => dict.buttons.apply)}
						variant={variant => variant.primary}
					/>
				</Flex>
			</Flex>
		</>
	);
}

export const FilterStatuses = withMemo(Component);
