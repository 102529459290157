export const ROUTE_MAP = {
	auth: {
		login: {
			path: '/login',
			createPath: () => '/login'
		},
		federatedLogin: {
			path: '/federated-login',
			createPath: ({ provider }: { provider: string }) => `/federated-login?sso=${provider}`
		},
		newPasswordRequired: {
			path: '/new-password-required',
			createPath: ({ username }: { username?: string }) =>
				`/new-password-required?username=${username}`
		},
		forgotPassword: {
			path: '/forgot-password',
			createPath: ({ username }: { username: string }) =>
				`/forgot-password?username=${username}`
		},
		confirmResetPassword: {
			path: '/confirm-reset-password',
			createPath: ({ username }: { username?: string }) => {
				let path = '/confirm-reset-password';
				if (username) {
					path += `?username=${username}`;
				}

				return path;
			}
		},
		setupTotp: {
			path: '/setup-totp',
			createPath: () => '/setup-totp'
		},
		confirmTotp: {
			path: '/confirm-totp',
			createPath: () => '/confirm-totp'
		},
		setupAccountInfo: {
			path: '/setup-account-info',
			createPath: () => '/setup-account-info'
		},
		logout: {
			path: '/logout',
			createPath: () => '/logout'
		}
	},

	projects: {
		path: '/projects',
		byId: {
			dataset: {
				path: '/projects/:projectId/dataset',
				createPath: ({ projectId }: { projectId: string }) =>
					`/projects/${projectId}/dataset`,

				create: {
					path: '/v1.5/projects/:projectId/dataset/create',
					createPath: ({
						projectId,
						formId
					}: {
						projectId: string;
						formId: string | null;
					}) => {
						const path = `/v1.5/projects/${projectId}/dataset/create`;

						if (formId) {
							return `${path}?formId=${formId}`;
						}

						return path;
					},

					print: {
						path: '/v1.5/projects/:projectId/dataset/print',
						createPath: ({
							projectId,
							formId
						}: {
							projectId: string;
							formId: string | null;
						}) => {
							const path = `/v1.5/projects/${projectId}/dataset/print`;
							if (formId) {
								return `${path}?formId=${formId}`;
							}

							return path;
						}
					}
				},

				update: {
					path: '/v1.5/projects/:projectId/dataset/update/:entryId',
					createPath: ({
						projectId,
						entryId,
						formId
					}: {
						projectId: string;
						entryId: string;
						formId: string | null;
					}) => {
						const path = `/v1.5/projects/${projectId}/dataset/update/${entryId}`;
						if (formId) {
							return `${path}?formId=${formId}`;
						}

						return path;
					},

					print: {
						path: '/v1.5/projects/:projectId/dataset/:entryId/print',
						createPath: ({
							projectId,
							entryId,
							formId
						}: {
							projectId: string;
							entryId: string;
							formId: string | null;
						}) => {
							const path = `/v1.5/projects/${projectId}/dataset/${entryId}/print`;
							if (formId) {
								return `${path}?formId=${formId}`;
							}

							return path;
						}
					},

					series: {
						bySeriesName: {
							path: '/v1.5/projects/:projectId/dataset/:entryId/series/:seriesName',
							pathWithout1_5:
								'/projects/:projectId/dataset/:entryId/series/:seriesName',

							createPath: ({
								projectId,
								entryId,
								seriesName
							}: {
								projectId: string;
								entryId: string;
								seriesName: string;
							}) =>
								`/v1.5/projects/${projectId}/dataset/${entryId}/series/${seriesName}`,

							create: {
								path: '/v1.5/projects/:projectId/dataset/:entryId/series/:seriesName/create',
								pathWithout1_5:
									'/projects/:projectId/dataset/:entryId/series/:seriesName/create',

								createPath: ({
									projectId,
									entryId,
									seriesName,
									formId
								}: {
									projectId: string;
									entryId: string;
									seriesName: string;
									formId: string | null;
								}) => {
									const path = `/v1.5/projects/${projectId}/dataset/${entryId}/series/${seriesName}/create`;
									if (formId) {
										return `${path}?formId=${formId}`;
									}

									return path;
								},

								print: {
									path: '/v1.5/projects/:projectId/dataset/:entryId/series/:seriesName/create/print',
									createPath: ({
										projectId,
										entryId,
										seriesName,
										formId
									}: {
										projectId: string;
										entryId: string;
										seriesName: string;
										formId: string | null;
									}) => {
										const path = `/v1.5/projects/${projectId}/dataset/${entryId}/series/${seriesName}/create/print`;
										if (formId) {
											return `${path}?formId=${formId}`;
										}

										return path;
									}
								}
							},

							update: {
								path: '/v1.5/projects/:projectId/dataset/:entryId/series/:seriesName/entry/:seriesEntryId/update',
								pathWithout1_5:
									'/projects/:projectId/dataset/:entryId/series/:seriesName/entry/:seriesEntryId/update',

								createPath: ({
									projectId,
									entryId,
									seriesName,
									seriesEntryId,
									formId
								}: {
									projectId: string;
									entryId: string;
									seriesName: string;
									seriesEntryId: string;
									formId: string | null;
								}) => {
									const path = `/v1.5/projects/${projectId}/dataset/${entryId}/series/${seriesName}/entry/${seriesEntryId}/update`;
									if (formId) {
										return `${path}?formId=${formId}`;
									}

									return path;
								},

								print: {
									path: '/v1.5/projects/:projectId/dataset/:entryId/series/:seriesName/entry/:seriesEntryId/update/print',
									createPath: ({
										projectId,
										entryId,
										seriesName,
										seriesEntryId,
										formId
									}: {
										projectId: string;
										entryId: string;
										seriesName: string;
										seriesEntryId: string;
										formId: string | null;
									}) => {
										const path = `/v1.5/projects/${projectId}/dataset/${entryId}/series/${seriesName}/entry/${seriesEntryId}/update/print`;
										if (formId) {
											return `${path}?formId=${formId}`;
										}

										return path;
									}
								}
							}
						}
					}
				}
			}
		}
	}
} as const;
