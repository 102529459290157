// consts

export enum EnvType {
	Development = 'development',
	Playground = 'playground',
	Playground_2 = 'playground_2',
	Playground_3 = 'playground_3',
	Systest = 'systest',
	Preprod = 'preprod',
	Production = 'production',
	Test = 'test'
}

export enum EventType {
	Resize = 'resize',
	Scroll = 'scroll',
	Click = 'click',
	MouseDown = 'mousedown',
	Initialized = 'initialized',
	Wheel = 'wheel',
	KeyUp = 'keyup',
	KeyDown = 'keydown',
	MouseMove = 'mousemove'
}

export enum ElementType {
	Title = 'title',
	Subtitle = 'subtitle',
	Text = 'text',
	Separator = 'separator',
	Input = 'input',
	Dropdown = 'dropdown',
	Radiobuttons = 'radiobuttons',
	Checkboxes = 'checkboxes',
	Slider = 'slider',
	File = 'file'
}

export enum OrientationType {
	Horizontal = 'horizontal',
	Vertical = 'vertical'
}

export enum SliderType {
	Horizontal = 'horizontal',
	Vertical = 'vertical'
}

export enum ChoiceType {
	SingleChoice = 'single_choice',
	MultipleChoice = 'multiple_choice'
}

export enum InputType {
	Text = 'text',
	Textarea = 'textarea',
	Email = 'email',
	Password = 'password',
	Date = 'date',
	DateTime = 'datetime',
	Number = 'number',
	Radio = 'radio',
	Checkbox = 'checkbox',
	Slider = 'slider'
}

export enum AlertType {
	Notification = 'notification',
	Error = 'error'
}

export enum ImportType {
	Manual = 'manual',
	Now = 'now',
	Later = 'later',
	VariableTemplate = 'variable-template',
	MoreDataToExistingEntries = 'more-data-to-existing-entries',
	MoreEntriesToDataset = 'more-entries-to-dataset',
	ReplaceAll = 'replace'
}

export enum FileType {
	Default = 'application/octet-stream',
	Csv = 'text/csv;charset=utf-8;'
}

export enum FileMimeType {
	JPG = 'image/jpeg',
	PNG = 'image/png',
	GIF = 'image/gif',
	BMP = 'image/bmp',
	TIFF = 'image/tiff',
	PDF = 'application/pdf',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	XLS = 'application/vnd.ms-excel',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ZIP = 'application/zip',
	XZIP = 'application/x-zip-compressed',
	CSV = 'text/csv',
	TXT = 'text/plain',
	OS = 'application/octet-stream'
}

export enum FileExtension {
	JPG = 'jpg',
	JPEG = 'jpeg',
	PNG = 'png',
	GIF = 'gif',
	BMP = 'bmp',
	TIFF = 'tiff',
	PDF = 'pdf',
	XLSX = 'xlsx',
	XLS = 'xls',
	DOC = 'doc',
	DOCX = 'docx',
	ZIP = 'zip',
	XZIP = 'xzip',
	CSV = 'csv',
	TXT = 'txt',
	OS = 'os'
}

export enum StorageKeys {
	Translations = 'translations',
	UserLanguage = 'ledidi-user-language',
	PrevPathname = 'previous-pathname',
	SubscriptionUpdating = 'subscription-updating',
	// AUTOLOGOUT
	PageUnloadedTimestamp = 'page-unloaded-timestamp',
	InactivityTimestamp = 'ledidi-inactivity-timestamp',
	UniqueTabIDs = 'ledidi-tab-ids',
	// TODO: Remove once we have a notification for this change
	LicenceModelSelfAssigned = 'licence-model-self-assigned'
}

export enum DragAndDropTypes {
	Separator = '###',
	// FORM DESIGNER
	DroppableFormElement = 'droppableFormElement',
	DroppableFormVariable = 'droppableFormVariable',
	FormDroppableZone = 'formDroppableZone',
	FormDroppableZoneRow = 'formDroppableZoneRow',
	DraggableFormGroup = 'group-',
	DraggableFormSet = 'set-',
	// DEPENDENCIES
	DroppableDependenciesVarList = 'drop_dep_var_list',
	DraggableDependenciesVarList = 'drag_dep_var_list',
	// TEMPLATES
	DroppableTemplate = 'droppableTemplate',
	DraggableTemplateVarOrGroup = 'draggableTemplateVarOrGroup',
	DraggableTemplateVariable = 'draggableTemplateVariable',
	DraggableTemplateGroupedVariable = 'draggableTemplateGroupedVariable',
	DraggableTemplateGroup = 'draggableTemplateGroup',
	DroppableTemplateGroup = 'droppableTemplateGroup',
	TemplateDroppableZone = 'templateDroppableZone'
}

export enum TemplateViewTypes {
	Dropzone = 'dropzone',
	Description = 'description'
}

export enum TemplatePageViews {
	MyTemplates = 'mytemplates',
	OtherTemplates = 'otherTemplates'
}

export enum TemplatesOwnership {
	Public = 'public',
	SharedWithMe = 'sharedWithMe',
	SharedWithProject = 'sharedWithProject'
}

export enum TemplateShareLevel {
	ShareWithUsers = 'shareWithUsers',
	ShareWithProjects = 'shareWithProjects',
	ShareGlobally = 'shareGlobablly'
}
export enum RoleTemplateShareLevel {
	ShareWithUsers = 'shareWithUsers',
	ShareWithProjects = 'shareWithProjects'
}

export enum DrawerChoices {
	profile = 'profile',
	accountInformation = 'accountInformation',
	workInformation = 'workInformation',
	personalSettings = 'personalSettings',
	security = 'security'
}

export enum FilterByTypeComponent {
	Collaborators = 'collaborators',
	HelpPage = 'helpPage',
	AdminUsers = 'adminUsers',
	Documents = 'documents',
	Templates = 'templates',
	SubscriptionUsers = 'subscriptionUsers'
}

export enum ExportFileNames {
	// DATASET
	DatasetTable = 'dataset_table',
	// ANALYSIS
	FrequenciesTable = 'frequencies_table',
	FrequenciesColumns = 'frequencies_columns',
	FrequenciesPie = 'frequencies_pie',
	ExploreTable = 'explore_table',
	ExploreTableV2 = 'explore_table_v2',
	CompareNumericTableV1 = 'compare_numeric_table',
	CompareNumericTableV2 = 'compare_numeric_table_V2',
	CrosstabTable = 'crosstab_table',
	CrosstabGroupedChart = 'crosstab_grouped_chart',
	CrosstabStackedChart = 'crosstab_stacked_chart',
	CrosstabSunburstChart = 'crosstab_sunburst_chart',
	KaplanMeierChart = 'kaplan_meier_chart',
	CorrelationsScatterChart = 'correlations_scatter_chart',
	PlotNumericColumnsChart = 'plot_numeric_columns_chart',
	PlotNumericBoxPlotChart = 'plot_numeric_box-plot_chart',
	PlotNumericScatterChart = 'plot_numeric_scatter_chart',
	DensityPlotChart = 'density_plot_chart',
	TimeCourseChartV1 = 'time_course_chart_V1',
	TimeCourseChartV2 = 'time_course_chart_V2',
	ComparePairedTable = 'compare_paired_table',
	NumberPlotXYChart = 'number_plot_xy_chart',
	LogisticRegressionChart = 'logistic_regression',

	// EXPORT WIZARD
	SeriesExport = 'series_export'
}

export enum LanguageType {
	English = 'EN',
	Norwegian = 'NB'
}

export enum CreditCardBrands {
	Mastercard = 'Mastercard',
	Maestro = 'Maestro',
	Visa = 'Visa',
	VisaElectron = 'Visa Electron',
	Invalid = 'invalid'
}

export enum LedidiStatusCode {
	// Subscription transfer
	NoSubscription = 'nosubscription',
	SubscriptionNewUserAdded = 'newUserAddedToSubscription',
	SubscriptionUserIsAlreadyOwner = 'userIsAlreadySubscriptionOwner',
	SubscriptionUserInvited = 'userInvitedToSubscription',
	SubscriptionUserInTransfer = 'userInSubscriptionTransfer',
	UserIsChangingPlan = 'userIsChangingPlan',
	UserInEnterpriseSubscription = 'userInEnterpriseSubscription',

	// Licence limitations
	ErrorLicence = 'error.licence',
	ErrorLicenceOther = 'error.licence.other',
	ErrorLicenceCollaborator = 'error.licence.other',

	// Others
	UniqueFieldValue = 'error.uniqueFieldValue',
	DependenciesViolation = 'error.dependenciesViolation',

	// Frequencies corrupted data
	CorruptedData = 'error.corruptedData'
}

export enum TableName {
	SubscriptionUsers = 'SubscriptionUsers',
	Billing = 'Billing',
	Collaborators = 'Collaborators',
	Statuses = 'Statuses',
	Documents = 'Documents',
	AdminUsers = 'AdminUsers',
	Entries = 'Entries',
	SeriesEntries = 'SeriesEntries',
	EnterpriseAdminProjects = 'EnterpriseAdminProjects',
	OwnedProjects = 'OwnedProjects',
	Test = 'Test'
}

export enum DateLabels {
	Years = 'dateLabels.years',
	Months = 'dateLabels.months',
	Weeks = 'dateLabels.weeks',
	Days = 'dateLabels.days',
	Hours = 'dateLabels.hours',
	Minutes = 'dateLabels.minutes',
	Seconds = 'dateLabels.seconds'
}

export enum SeriesEntryFormType {
	NextAndPrevForms = 'nextAndPrevForms'
}

export enum SeriesViewType {
	GridView = 'gridView',
	NarrowTableView = 'narrowTableView',
	FullSizeTableView = 'fullSizeTableView',
	EditEntryView = 'editEntryView'
}

export enum SeriesFormState {
	Prev = 'prev',
	Current = 'current',
	Next = 'next'
}

export enum PaginationDirection {
	Right = 'right',
	Left = 'left'
}

export enum EntryDrawerType {
	SeriesEntryForm = 'seriesEntryForm',
	AddEditForm = 'addEditForm'
}

export enum DrawerHistoryType {
	Mobile = 'mobile'
}

export enum CategoryExportOption {
	Values,
	Labels
}

export enum ProjectMetadataName {
	archiveNumber = 'archiveNumber',
	projectType = 'projectType'
}
