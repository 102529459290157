import { useEffect } from 'react';

interface Props {
	ssoProvider: 'sykehuspartner' | 'cmr' | 'ledidissotest' | 'cornell';
}

export const TinyURLPage = ({ ssoProvider }: Props) => {
	useEffect(() => {
		window.location.replace(`${window.location.origin}/federated-login?sso=${ssoProvider}`);
	}, [ssoProvider]);

	return <></>;
};
