import produce from 'immer';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
	GeneralPermissions,
	ModulesPermissions,
	StatusesPermissions
} from 'components/Collaborators';
import { DefaultCollaboratorPermissions } from 'consts';
import { initButtonProps } from 'helpers/buttons';
import {
	CollaboratorPermissions as CollaboratorPermissionsType,
	GeneralPermissionFlags,
	GeneralPermissions as GeneralPermissionsType,
	ModulesPermissions as ModulesPermissionsType,
	StatusTypeAccesses
} from 'store/data/collaborators';
import { Role } from 'store/data/roles';
import { HTMLInput, InputType, SelectItem } from 'types/index';
import {
	generateCollaboratorSelectItems,
	generateModalTitle,
	generateOrganizationSelectItems,
	generateSelectRoleItems,
	getCollaboratorList,
	getOrganizationList,
	getRolePermission,
	getSelectedRole,
	isRoleNameUnique
} from './helpers';
import { RoleModalType } from './types';
import { Row } from './RoleModal.style';
import { useResetFetchedCollaborators } from '../../../hooks/store/data/collaborators/useResetFetchedCollaborators';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Modal } from 'components/UI/Modal';
import { Tabs } from 'components/UI/Tabs';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Typography } from 'components/UI/Typography';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { buildInitialPermissions } from 'helpers/collaborators';
import {
	useCollaborators,
	useCreateRole,
	useOrganizationAccessRights,
	useProjectId,
	useRoles,
	useStatuses,
	useTemplateRoles,
	useTranslation,
	useUpdateRole
} from 'hooks/store';
import { useMutableState, useCompletedAction } from 'hooks/utils';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';

enum Views {
	Main = 'main',
	AssignRole = 'assign'
}

interface Props {
	role?: Role;
	onClose: (success?: true) => void;
}

export function RoleModal({ role, onClose }: Props) {
	const isCreateModal = !role;
	const isUpdateModal = !isCreateModal;

	const type = isCreateModal ? RoleModalType.Create : RoleModalType.Update;

	const { translate } = useTranslation();
	const [projectId] = useProjectId();
	const resetFetchedCollaborators = useResetFetchedCollaborators();
	const [{ data: rolesData }] = useRoles();
	const [
		{
			data: collaboratorsData,
			loading: loadingCollaborators,
			error: errorLoadingCollaborators,
			fetched: areCollaboratorsFetched
		}
	] = useCollaborators();

	const currentUserQuery = useCurrentUserQuery();

	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();

	const nameInputRef = useRef<HTMLInput>(null);

	const [activeTab, setActiveTab] = useState(0);

	const initialRoleName = role?.name.toLowerCase() ?? '';

	const [
		{
			data: templateRolesData,
			loading: loadingTemplateRoles,
			error: errorGettingRoles,
			fetched: hasTemplateRoles
		}
	] = useTemplateRoles();

	const [{ loading: creatingRole, error: errorCreatingRole }, createRole] = useCreateRole();
	const [{ loading: updatingRole, error: errorUpdatingRole }, updateRole] = useUpdateRole();

	const [
		{
			data: { statuses, hasStatuses }
		}
	] = useStatuses({ lazy: false, omitSystemGenerated: false });

	const templateRolesValidator = {
		hasTemplateRoles:
			hasTemplateRoles &&
			!loadingTemplateRoles &&
			!errorGettingRoles &&
			!!templateRolesData.roles,
		hasOwned: templateRolesData?.roles.owned.length > 0,
		hasShared: templateRolesData?.roles.shared.length > 0,
		hasOrganization: templateRolesData?.roles.public.length > 0
	};

	// Set of collaborators and organizations to be assigned to the role
	// This is used to initialize the SelectedItems for collaborators(value = userId) and organizations(value = id) -> <CreatableSelect>
	const orgItems = useMemo(
		() => generateOrganizationSelectItems(collaboratorsData.organizations),
		[collaboratorsData.organizations]
	);
	const collabItems = useMemo(
		() =>
			generateCollaboratorSelectItems(
				currentUserQuery.data?.username || null,
				collaboratorsData.collaborators
			),
		[currentUserQuery.data?.username, collaboratorsData.collaborators]
	);

	const selectRoleItems = useMemo(() => {
		return generateSelectRoleItems(
			rolesData.roles,
			templateRolesData.roles,
			templateRolesValidator
		);
	}, [rolesData.roles, templateRolesData.roles, templateRolesValidator, translate]);

	const goToView = {
		main() {
			setModalState(state => {
				state.view = Views.Main;
			});
		},
		assign() {
			setModalState(state => {
				state.view = Views.AssignRole;
			});
		}
	};

	const initialPermissions = useMemo(() => {
		const initial = role ? role.permissions : DefaultCollaboratorPermissions;

		return buildInitialPermissions(initial, statuses);
	}, [role, statuses]);

	const [draftRole, setDraftRole] = useMutableState<Role>({
		id: role ? role.id : '',
		name: role ? role.name : '',
		description: role ? role.description : '',
		permissions: initialPermissions,
		projectId: projectId || ''
	});

	const [modalState, setModalState] = useMutableState({
		view: Views.Main,
		title: generateModalTitle(type, translate),
		mainView: {
			selectedRoleId: null as string | null,
			assignRole: false
		},
		secondaryView: {
			title: translate(dict => dict.rolesPage.roleModal.title),
			assignRole: {
				organizations: true,
				specificOrganization: false,
				collaborators: false,
				specificCollaborator: false
			},
			organizations: {
				items: generateOrganizationSelectItems(collaboratorsData.organizations),
				selectedItems: [] as SelectItem[],
				hasOrganizations: collaboratorsData.hasOrganizations
			},
			collaborators: {
				items: generateCollaboratorSelectItems(
					currentUserQuery.data?.username || null,
					collaboratorsData.collaborators
				),
				selectedItems: [] as SelectItem[],
				hasCollaborators: collaboratorsData.hasCollaborators
			}
		}
	});

	const hasChanges = useMemo(() => !isEqual(role, draftRole), [role, draftRole]);

	// hydrate `draftRole.permissions`
	useEffect(() => {
		if (isEqual(draftRole.permissions, initialPermissions)) return;

		setDraftRole(state => {
			state.permissions = initialPermissions;
		});
	}, [initialPermissions]);

	// hydrate `modalState.secondaryView.organization.items` && `modalState.secondaryView.collaborators.items`
	useEffect(() => {
		if (!areCollaboratorsFetched) return;

		if (!modalState.secondaryView.organizations.hasOrganizations) {
			setModalState(state => {
				state.secondaryView.organizations.items = orgItems;
				state.secondaryView.organizations.hasOrganizations =
					collaboratorsData.hasOrganizations;
			});
		}

		if (!modalState.secondaryView.collaborators.hasCollaborators) {
			setModalState(state => {
				state.secondaryView.collaborators.items = collabItems;
				state.secondaryView.collaborators.hasCollaborators =
					collaboratorsData.hasCollaborators;
			});
		}
	}, [collaboratorsData, areCollaboratorsFetched]);

	useCompletedAction(creatingRole, errorCreatingRole, () => {
		onClose(true);
		resetFetchedCollaborators({ projectId: projectId || '' });
	});
	useCompletedAction(updatingRole, errorUpdatingRole, () => {
		onClose(true);
		resetFetchedCollaborators({ projectId: projectId || '' });
	});

	function handleSelectItem(value: string | null | undefined) {
		if (!value) {
			setModalState(state => {
				state.mainView.selectedRoleId = null;
			});
			setDraftRole(state => {
				state.permissions = initialPermissions;
				state.name = '';
			});
		} else {
			const selectedRole = getSelectedRole(
				value,
				selectRoleItems,
				!!templateRolesValidator.hasTemplateRoles
			);

			if (selectedRole) {
				const permission = getRolePermission(
					rolesData.roles,
					templateRolesData.roles,
					selectedRole.value,
					projectId,
					draftRole.permissions.statusTypeAccesses
				);

				setModalState(state => {
					state.mainView.selectedRoleId = value;

					if (state.mainView.assignRole) {
						state.mainView.assignRole = false;
						state.secondaryView.assignRole = {
							organizations: true,
							specificOrganization: false,
							collaborators: false,
							specificCollaborator: false
						};
						state.secondaryView.organizations.selectedItems = [];
						state.secondaryView.collaborators.selectedItems = [];
					}
				});

				setDraftRole(state => {
					state.name = selectedRole.label;
					state.permissions = permission ? permission : initialPermissions;
				});

				nameInputRef.current?.focus();
			}
		}
	}

	function handleCollaboratorsSelected(values: string[]) {
		setModalState(state => {
			const items = state.secondaryView.collaborators.items.filter(item =>
				values.includes(item.value)
			);
			state.secondaryView.collaborators.selectedItems = items;
			state.secondaryView.organizations.selectedItems = [];
		});
	}

	function handleOrganizationsSelected(values: string[]) {
		setModalState(state => {
			const items = state.secondaryView.organizations.items.filter(item =>
				values.includes(item.value)
			);
			state.secondaryView.organizations.selectedItems = items;
			state.secondaryView.collaborators.selectedItems = [];
		});
	}

	function isLoadingAction(): boolean {
		return updatingRole || creatingRole || loadingTemplateRoles || loadingCollaborators;
	}

	function isErrorAction(): boolean {
		return (
			errorUpdatingRole || errorCreatingRole || errorGettingRoles || errorLoadingCollaborators
		);
	}

	function getErrorMessage() {
		if (!isNameUnique()) {
			return translate(({ roles }) => roles.modals.roleModal.errors.nameUnique);
		}

		return '';
	}

	function isNameUnique(): boolean {
		if (isCreateModal) return isRoleNameUnique(draftRole.name, { roles: rolesData.roles });

		return isRoleNameUnique(draftRole.name, {
			roles: rolesData.roles,
			exclude: [initialRoleName]
		});
	}

	function isStringValid(value: string) {
		return value.trim().length > 0;
	}

	function getInputError() {
		if (
			!isRoleNameUnique(draftRole.name, {
				roles: rolesData.roles,
				exclude: [initialRoleName]
			})
		) {
			return translate(({ roles }) => roles.modals.roleModal.errors.nameUnique);
		}

		if (!isStringValid(draftRole.name)) {
			return translate(({ roles }) => roles.modals.roleModal.errors.nameRequired);
		}
	}

	function getRole() {
		return getSelectedRole(
			modalState.mainView.selectedRoleId,
			selectRoleItems,
			!!templateRolesValidator.hasTemplateRoles
		);
	}

	function getPrimaryButtonLabel() {
		return isCreateModal
			? modalState.mainView.assignRole
				? modalState.view === Views.AssignRole
					? translate(({ buttons }) => buttons.add)
					: translate(({ buttons }) => buttons.next)
				: translate(({ buttons }) => buttons.add)
			: translate(({ buttons }) => buttons.update);
	}

	function getNeutralButtonLabel() {
		if (modalState.view === Views.AssignRole) {
			return translate(({ buttons }) => buttons.back);
		}

		return translate(({ buttons }) => buttons.cancel);
	}

	function handleSubmit() {
		if (isLoadingAction() || isErrorAction() || !isNameUnique()) return;

		const draftRoleTrimmed = trimRoleFields(draftRole);

		if (role) {
			updateRole(draftRoleTrimmed);
		} else {
			const collaborators = getCollaboratorList({
				username: currentUserQuery.data?.username || null,
				allCollaborators: collaboratorsData.collaborators,
				assignTo: {
					collaborators:
						modalState.secondaryView.assignRole.collaborators &&
						modalState.mainView.assignRole,
					specificCollaborator:
						modalState.secondaryView.assignRole.specificCollaborator &&
						modalState.mainView.assignRole
				},
				selectItems: modalState.secondaryView.collaborators.selectedItems
			});

			const groupIds = getOrganizationList({
				allOrganizations: collaboratorsData.organizations,
				assignTo: {
					organizations:
						modalState.secondaryView.assignRole.organizations &&
						modalState.mainView.assignRole,
					specificOrganization:
						modalState.secondaryView.assignRole.specificOrganization &&
						modalState.mainView.assignRole
				},
				selectItems: modalState.secondaryView.organizations.selectedItems
			});

			createRole(draftRoleTrimmed, { assignTo: { collaborators, groupIds } });
		}
	}

	function handleNeutralButtonClick() {
		if (modalState.view === Views.AssignRole) {
			goToView.main();
		} else {
			onClose();
		}
	}

	function toggleCheckbox() {
		setModalState(state => {
			state.mainView.assignRole = !state.mainView.assignRole;
		});
	}

	function trimRoleFields(role: Role): Role {
		return {
			...role,
			name: role.name.trim(),
			description: role.description.trim()
		};
	}

	function validatePrimaryButton() {
		const mainValidation = isErrorAction() || !isNameUnique() || !isStringValid(draftRole.name);

		if (modalState.view === Views.Main) {
			return mainValidation;
		}

		if (modalState.secondaryView.assignRole.specificOrganization) {
			return (
				mainValidation || modalState.secondaryView.organizations.selectedItems.length === 0
			);
		}

		if (modalState.secondaryView.assignRole.specificCollaborator) {
			return (
				mainValidation || modalState.secondaryView.collaborators.selectedItems.length === 0
			);
		}
	}

	const onChangePermissions = {
		general(generalPermissions: GeneralPermissionsType) {
			const updatedPermissions = produce(draftRole.permissions, draft => {
				for (const key in generalPermissions) {
					const value = generalPermissions[key as GeneralPermissionFlags];

					draft[key as GeneralPermissionFlags] = value;
				}
			});

			setDraftRolePermissions(updatedPermissions);
		},
		statuses(statusesPermissions: StatusTypeAccesses) {
			const updatedPermissions = produce(draftRole.permissions, draft => {
				draft.statusTypeAccesses = statusesPermissions;
			});

			setDraftRolePermissions(updatedPermissions);
		},
		modules(modulesPermissions: ModulesPermissionsType) {
			const updatedPermissions = produce(draftRole.permissions, draft => {
				draft.modules = modulesPermissions;
			});

			setDraftRolePermissions(updatedPermissions);
		}
	};

	function setDraftRolePermissions(permissions: CollaboratorPermissionsType) {
		setDraftRole(state => {
			state.permissions = permissions;
		});
	}

	const handleInputTrimOnBlur = (
		e: React.FocusEvent<HTMLInput>,
		fieldName: 'name' | 'description'
	) => {
		const { value } = e.target;

		setDraftRole(state => {
			state[fieldName] = value.trim();
		});
	};

	function isAtView(view: Views) {
		return modalState.view === view;
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: getPrimaryButtonLabel(),
			loading: isLoadingAction(),
			onClick:
				(isAtView(Views.Main) && !modalState.mainView.assignRole) ||
				isAtView(Views.AssignRole)
					? handleSubmit
					: goToView.assign,
			disabled: validatePrimaryButton()
		};

		buttons.neutral = {
			label: getNeutralButtonLabel(),
			onClick: handleNeutralButtonClick
		};

		if (!hasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<Modal
			title={modalState.title}
			onClose={onClose}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			fullSizeConfig={{
				narrow: true
			}}
			visible
			close
		>
			{/* Main View */}
			{modalState.view === Views.Main && (
				<>
					<Row>
						{/* Create Modal */}
						{isCreateModal && (
							<>
								<CreatableSelect
									label={translate(
										dict => dict.rolesPage.roleModal.selectRoleLabel
									)}
									value={getRole()}
									items={selectRoleItems}
									onValueSelected={value => handleSelectItem(value)}
								/>
								<Input
									type={InputType.Text}
									label={translate(
										({ roles }) => roles.modals.roleModal.fields.name
									)}
									value={draftRole.name}
									placeholder={translate(
										({ roles }) =>
											roles.modals.roleModal.inputPlaceholders.custom
									)}
									onChange={e =>
										setDraftRole(state => {
											state.name = e.target.value;
										})
									}
									onBlur={e => handleInputTrimOnBlur(e, 'name')}
									onSubmit={handleSubmit}
									error={getErrorMessage()}
									ref={nameInputRef}
									autoFocus
								/>
							</>
						)}

						{/* Update Modal */}
						{isUpdateModal && (
							<Input
								type={InputType.Text}
								label={translate(({ roles }) => roles.modals.roleModal.fields.name)}
								value={draftRole.name}
								onChange={e =>
									setDraftRole(state => {
										state.name = e.target.value;
									})
								}
								onBlur={e => handleInputTrimOnBlur(e, 'name')}
								onSubmit={handleSubmit}
								error={getInputError()}
							/>
						)}
					</Row>
					<Spacer size={s => s.s} />
					<Input
						type={InputType.Textarea}
						label={translate(({ roles }) => roles.modals.roleModal.fields.description)}
						value={draftRole.description}
						rows={3}
						onChange={e =>
							setDraftRole(state => {
								state.description = e.target.value;
							})
						}
						onBlur={e => handleInputTrimOnBlur(e, 'description')}
						onSubmit={handleSubmit}
					/>
					<Spacer size={s => s.m} />
					<Tabs
						labels={[
							translate(({ roles }) => roles.modals.roleModal.tabs.general.title),
							hasStatuses
								? translate(
										({ roles }) => roles.modals.roleModal.tabs.statuses.title
								  )
								: [],
							translate(({ roles }) => roles.modals.roleModal.tabs.modules.title)
						].flat()}
						startIndex={activeTab}
						onChangeTabs={active => setActiveTab(active)}
						tabClassName="tabs__navigation__item"
					>
						<Tabs.Content>
							<GeneralPermissions
								permissions={draftRole.permissions}
								onChange={onChangePermissions.general}
								hasOrganizationExportAccess={accessExportDelegation}
							/>
						</Tabs.Content>
						{hasStatuses && (
							<Tabs.Content>
								<StatusesPermissions
									statuses={statuses}
									permissions={draftRole.permissions.statusTypeAccesses ?? []}
									onChange={onChangePermissions.statuses}
								/>
							</Tabs.Content>
						)}
						<Tabs.Content>
							<ModulesPermissions
								permissions={draftRole.permissions.modules}
								onChange={onChangePermissions.modules}
							/>
						</Tabs.Content>
					</Tabs>

					{/* Create Modal */}
					{isCreateModal && (
						<>
							<Spacer size={s => s.m} />
							<Checkbox
								label={translate(
									({ roles }) => roles.modals.roleModal.assignRole.checkbox.title
								)}
								checked={modalState.mainView.assignRole}
								onClick={toggleCheckbox}
							/>
						</>
					)}
				</>
			)}

			{/* Assign Role View */}
			{modalState.view === Views.AssignRole && (
				<>
					<Typography.H6
						css={`
							font-size: 1.8rem;
						`}
					>
						{modalState.secondaryView.title}
					</Typography.H6>

					<Spacer size={s => s.m} />

					<RadioButton
						label={translate(dict => dict.rolesPage.roleModal.roleToAllGroups)}
						selected={modalState.secondaryView.assignRole.organizations}
						onSelect={() =>
							setModalState(state => {
								state.secondaryView.assignRole.organizations = true;
								state.secondaryView.assignRole.collaborators = false;
								state.secondaryView.assignRole.specificCollaborator = false;
								state.secondaryView.assignRole.specificOrganization = false;
							})
						}
					/>

					<Spacer size={s => s.m} />

					<RadioButton
						label={translate(dict => dict.rolesPage.roleModal.roleToSpecificGroups)}
						selected={modalState.secondaryView.assignRole.specificOrganization}
						onSelect={() =>
							setModalState(state => {
								state.secondaryView.assignRole.organizations = false;
								state.secondaryView.assignRole.collaborators = false;
								state.secondaryView.assignRole.specificCollaborator = false;
								state.secondaryView.assignRole.specificOrganization = true;
							})
						}
					/>

					<Spacer size={s => s.s} />
					<CreatableSelect
						placeholder={translate(
							dict => dict.rolesPage.roleModal.groupNamePlaceholder
						)}
						items={modalState.secondaryView.organizations.items}
						values={modalState.secondaryView.organizations.selectedItems}
						onValuesSelected={handleOrganizationsSelected}
						disabled={!modalState.secondaryView.assignRole.specificOrganization}
						hasMultipleValues
					/>

					<Spacer size={s => s.m} />

					<RadioButton
						label={translate(dict => dict.rolesPage.roleModal.roleToAllCollaborators)}
						selected={modalState.secondaryView.assignRole.collaborators}
						onSelect={() =>
							setModalState(state => {
								state.secondaryView.assignRole.organizations = false;
								state.secondaryView.assignRole.collaborators = true;
								state.secondaryView.assignRole.specificCollaborator = false;
								state.secondaryView.assignRole.specificOrganization = false;
							})
						}
					/>

					<Spacer size={s => s.m} />

					<RadioButton
						label={translate(
							dict => dict.rolesPage.roleModal.roleToSpecificCollaborators
						)}
						selected={modalState.secondaryView.assignRole.specificCollaborator}
						onSelect={() =>
							setModalState(state => {
								state.secondaryView.assignRole.organizations = false;
								state.secondaryView.assignRole.collaborators = false;
								state.secondaryView.assignRole.specificCollaborator = true;
								state.secondaryView.assignRole.specificOrganization = false;
							})
						}
					/>

					<Spacer size={s => s.s} />

					<CreatableSelect
						placeholder={translate(
							dict => dict.rolesPage.roleModal.collaboratorNamePlaceholder
						)}
						items={modalState.secondaryView.collaborators.items}
						values={modalState.secondaryView.collaborators.selectedItems}
						onValuesSelected={handleCollaboratorsSelected}
						disabled={!modalState.secondaryView.assignRole.specificCollaborator}
						hasMultipleValues
					/>
				</>
			)}
		</Modal>
	);
}
