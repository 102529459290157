import { Routes, Route, Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FreshchatDisplay } from './FreshchatDisplay';
import { SegmentPageTracker } from './SegmentPageTracker';

import { Scroller } from 'components/UI/Scroller';
import { stringAsBoolean } from 'helpers/generic';
import { StorePrevLocation } from 'helpers/storePrevLocation';
import {
	AccountUMSubscriptionPage,
	AccountUMPaymentPage,
	AccountUMBillingPage
} from 'pages/Account';
import {
	AdminUsersPage,
	AdminUserCreatePage,
	AdminUserDetailsPage,
	AdminEnterprisePage
} from 'pages/Admin';
import { AnalysisPage } from 'pages/Analysis';
import { CollaboratorsInvitePage, CollaboratorsPage } from 'pages/Collaborators';
import { DatasetPage, AddEditEntryPage } from 'pages/Dataset';
import { DependenciesPage } from 'pages/Dependencies';
import { DocumentsPage } from 'pages/Documents';
import { FormsPage, FormDesignerPage } from 'pages/Forms';
import { HelpPage } from 'pages/Help';
import { LoginPage } from 'features/auth/LoginPage';
import { NewPasswordRequiredPage } from 'features/auth/NewPasswordRequired';
import { LogoutPage } from 'pages/Logout/LogoutPage';
import { NotFoundPage } from 'pages/NotFound';
import { ProjectsPage, CreateAndImportPage } from 'pages/Projects';
import { RolesPage } from 'pages/Roles';
import { HistoryPage } from 'pages/History';
import { StatusesPage } from 'pages/Statuses';
import { TemplateRolesPage } from 'pages/TemplateRoles';
import { TemplatesPage } from 'pages/Templates';
import { VariablesPage } from 'pages/Variables';
import { ROUTES } from 'types/navigation';
import {
	EnterpriseAdminProjectsPage,
	EnterpriseAdminSettingsPage,
	EnterpriseAdminSubscriptionPage
} from 'pages/EnterpriseAdmin';
import { MicroApp } from 'next-micro-app/NextMicroApp';
import { UpdateEntryPageV1_5 } from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { CreateEntryPageV1_5 } from 'features/entry-form-v2/create-entry/CreateEntryPageV1_5';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { CreateSeriesEntryPageV1_5 } from 'features/entry-form-v2/series/create/CreateSeriesEntryPageV1_5';
import { SeriesDetailsPage } from 'features/entry-form-v2/series/details/SeriesDetailsPage';
import { UpdateSeriesEntryPageV1_5 } from 'features/entry-form-v2/series/update/UpdateSeriesEntryPageV1_5';
import { PrintEntryPage } from 'features/entry-form-v2/print/PrintEntryPage';
import { ConfirmTotpPage } from 'features/auth/ConfirmTotpPage';
import { FederatedLoginPage } from 'features/auth/federated-login/FederatedLoginPage';
import { PublicRoutes } from './PublicRoutes';
import { NavigationHub } from './NavigationHub';
import { ProtectedRoutes } from '../features/auth/protected-routes/ProtectedRoutes';
import {
	WithLoggedInComponent,
	WithSubscriptionRules
} from 'components/RouteComponents/RouteComponents';
import { ForgotPasswordPage } from 'features/auth/ForgotPasswordPage';
import { ConfirmResetPassword } from 'features/auth/ConfirmResetPassword';
import { SetupTotpPage } from 'features/auth/SetupTotpPage';
import { AccountSetupPage } from 'features/auth/AccountSetupPage';
import { TinyURLPage } from '../pages/TinyURLPage';

const ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ADMIN_PATH_ROOT}`;
const ENTERPRISE_ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}`;

const IS_FRESHCHAT_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_FRESHCHAT!);
const IS_HELP_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_HELP_PAGE!);
const IS_FORMS_DESGINER_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_FORMS_DESIGNER!);
const IS_TEMPLATES_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_TEMPLATES!);
const IS_SEGMENT_TRACKING_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_SEGMENT_TRACKING!);
const ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED = stringAsBoolean(
	process.env.REACT_APP_USE_ENTERPRISE_ADMIN_ROUTES!
);

const NEXT_APP_URL = process.env.REACT_NEXT_APP_URL as string;

export function Navigation() {
	const { auditLogV1 } = useFlags();

	return (
		<>
			<Scroller />
			<NavigationHub />
			<StorePrevLocation />

			{IS_FRESHCHAT_ENABLED && <FreshchatDisplay />}
			{IS_SEGMENT_TRACKING_ENABLED && <SegmentPageTracker />}

			<Routes>
				<Route element={<PublicRoutes />}>
					<Route path={ROUTE_MAP.auth.login.path} element={<LoginPage />} />
					<Route
						path={ROUTE_MAP.auth.federatedLogin.path}
						element={<FederatedLoginPage />}
					/>
					<Route
						path={ROUTE_MAP.auth.newPasswordRequired.path}
						element={<NewPasswordRequiredPage />}
					/>
					<Route path={ROUTE_MAP.auth.confirmTotp.path} element={<ConfirmTotpPage />} />

					<Route
						path={ROUTE_MAP.auth.forgotPassword.path}
						element={<ForgotPasswordPage />}
					/>
					<Route
						path={ROUTE_MAP.auth.confirmResetPassword.path}
						element={<ConfirmResetPassword />}
					/>

					<Route path="/hso" element={<TinyURLPage ssoProvider="sykehuspartner" />} />
					<Route path="/cmr" element={<TinyURLPage ssoProvider="cmr" />} />
					<Route
						path="/ledidissotest"
						element={<TinyURLPage ssoProvider="ledidissotest" />}
					/>
					<Route path="/cornell" element={<TinyURLPage ssoProvider="cornell" />} />
				</Route>

				<Route element={<ProtectedRoutes />}>
					<Route path={ROUTE_MAP.auth.setupTotp.path} element={<SetupTotpPage />} />
					<Route
						path={ROUTE_MAP.auth.setupAccountInfo.path}
						element={<AccountSetupPage />}
					/>

					<Route element={<WithLoggedInComponent />}>
						<Route path={'/v2/*'} element={<MicroApp src={NEXT_APP_URL} />} />

						<>
							<Route
								path={ROUTE_MAP.projects.byId.dataset.update.path}
								element={<UpdateEntryPageV1_5 />}
							/>
							<Route
								path={ROUTE_MAP.projects.byId.dataset.update.print.path}
								element={<PrintEntryPage />}
							/>
						</>

						<>
							<Route
								path={ROUTE_MAP.projects.byId.dataset.create.path}
								element={<CreateEntryPageV1_5 />}
							/>
							<Route
								path={ROUTE_MAP.projects.byId.dataset.create.print.path}
								element={<PrintEntryPage />}
							/>
						</>

						<>
							<Route
								path={
									ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName
										.create.path
								}
								element={<CreateSeriesEntryPageV1_5 />}
							/>
							<Route
								path={
									ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName
										.create.print.path
								}
								element={<PrintEntryPage />}
							/>
						</>

						<>
							<Route
								path={
									ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.path
								}
								element={<SeriesDetailsPage />}
							/>
							<Route
								path={
									ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName
										.update.print.path
								}
								element={<PrintEntryPage />}
							/>
						</>

						<Route
							path={
								ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.update
									.path
							}
							element={<UpdateSeriesEntryPageV1_5 />}
						/>

						<Route path={ROUTES.Analysis} element={<AnalysisPage />} />
						<Route path={ROUTES.Dataset} element={<DatasetPage />} />

						<Route path={ROUTES.CreateEntry} element={<AddEditEntryPage />} />
						<Route path={ROUTES.UpdateEntry} element={<AddEditEntryPage />} />
						{auditLogV1 && (
							<Route path={ROUTES.DatasetHistory} element={<HistoryPage />} />
						)}
						<Route path={ROUTES.Variables} element={<VariablesPage />} />
						<Route path={ROUTES.CreateVariable} element={<VariablesPage />} />
						<Route path={ROUTES.Documents} element={<DocumentsPage />} />
						<Route path={ROUTES.Dependencies} element={<DependenciesPage />} />
						<Route path={ROUTES.EntryStatuses} element={<StatusesPage />} />
						<Route
							path={ROUTES.AddCollaborators}
							element={<CollaboratorsInvitePage />}
						/>
						<Route path={ROUTES.Collaborators} element={<CollaboratorsPage />} />
						<Route path={ROUTES.Projects} element={<ProjectsPage />} />
						<Route path={ROUTES.CreateProject} element={<CreateAndImportPage />} />
						<Route path={ROUTES.ImportDataset} element={<CreateAndImportPage />} />
						<Route path={ROUTES.Roles} element={<RolesPage />} />
						{/* ACCOUNT USER MANAGEMENT*/}
						<Route
							path={ROUTES.AccountUMSubscription}
							element={<AccountUMSubscriptionPage />}
						/>
						{/* ADMIN */}
						<Route
							path={ADMIN_PATH_ROOT + ROUTES.AdminUsers}
							element={<AdminUsersPage />}
						/>
						<Route
							path={ADMIN_PATH_ROOT + ROUTES.AdminUserCreate}
							element={<AdminUserCreatePage />}
						/>
						<Route
							path={ADMIN_PATH_ROOT + ROUTES.AdminUserDetails}
							element={<AdminUserDetailsPage />}
						/>
						<Route
							path={ADMIN_PATH_ROOT + ROUTES.AdminEnterprise}
							element={<AdminEnterprisePage />}
						/>
						{/* ENTERPRISE ADMIN */}
						{ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED && (
							<>
								<Route
									path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminUsers}
									element={<AccountUMSubscriptionPage />}
								/>
								<Route
									path={
										ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminProjects
									}
									element={<EnterpriseAdminProjectsPage />}
								/>
								<Route
									path={
										ENTERPRISE_ADMIN_PATH_ROOT +
										ROUTES.EnterpriseAdminSubscription
									}
									element={<EnterpriseAdminSubscriptionPage />}
								/>
								<Route
									path={
										ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminSettings
									}
									element={<EnterpriseAdminSettingsPage />}
								/>
							</>
						)}
						{/* HELP */}
						{IS_HELP_PAGE_ENABLED && (
							<>
								<Route path={ROUTES.Help} element={<HelpPage />} />
								<Route path={ROUTES.HelpTopic} element={<HelpPage />} />
							</>
						)}
						{/* TEMPLATES */}
						{IS_TEMPLATES_PAGE_ENABLED && (
							<Route path={ROUTES.Templates} element={<TemplatesPage />} />
						)}
						{/* FORMS */}
						{IS_FORMS_DESGINER_PAGE_ENABLED && (
							<>
								<Route path={ROUTES.Forms} element={<FormsPage />} />
								<Route path={ROUTES.Form} element={<FormDesignerPage />} />
							</>
						)}
						{/* TEMPLATE ROLES */}
						<Route path={ROUTES.TemplateRoles} element={<TemplateRolesPage />} />
						{/*LPI - Portfolio insights */}
					</Route>

					<Route element={<WithSubscriptionRules />}>
						{/* ACCOUNT USER MANAGEMENT*/}
						<Route path={ROUTES.AccountUMPayment} element={<AccountUMPaymentPage />} />
						<Route
							path={ROUTES.AccountUMBillingHistory}
							element={<AccountUMBillingPage />}
						/>
					</Route>
					<Route path={ROUTES.Root} element={<Navigate to={ROUTES.Projects} />} />
					<Route path={ROUTES.NotFoundPage} element={<NotFoundPage />} />
				</Route>

				<Route path={ROUTES.Logout} element={<LogoutPage />} />
			</Routes>
		</>
	);
}
