import { getCurrentUser } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';
import { isUserUnAuthenticatedException } from 'features/auth/amplify/amplify-errors';

export const useCurrentUserQuery = () =>
	useQuery({
		queryKey: ['currentUser'],
		queryFn: async () => {
			try {
				const user = await getCurrentUser();

				if (!user) {
					return null;
				}
				return user;
			} catch (error) {
				//  We want to be able to call this when the user is signed out in order to reset the state
				if (isUserUnAuthenticatedException(error)) {
					return null;
				}

				throw error;

				return null;
			}
		},
		retry: false
	});

export type CurrentUserQuery = ReturnType<typeof useCurrentUserQuery>;
