import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from '@aws-amplify/auth';

export function useUsername() {
	const result = useQuery({
		queryKey: ['username'],
		queryFn: async () => {
			const currentUser = await getCurrentUser();

			return currentUser.username;
		}
	});

	return result.data;
}
