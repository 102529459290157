import { getCurrentUser } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'hooks/utils';
import { selectProjectOwner } from 'store/data/projects';

export function useIsProjectOwner(projectId?: string) {
	const projectOwner = useSelector(state => selectProjectOwner(state.data.projects, projectId));

	const result = useQuery({
		queryKey: ['isProjectOwner', projectId, projectOwner],
		queryFn: async () => {
			const currentUser = await getCurrentUser();

			return projectOwner === currentUser.username;
		}
	});

	return result.data;
}
