import { Layout, LoginBackLinkStyle } from './Layout';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'features/entry-form-v2/component/Input';
import { useSelector } from 'hooks/utils';
import { confirmSignIn } from '@aws-amplify/auth';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'features/entry-form-v2/component/Button';
import { useState } from 'react';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { isExpiredCodeException, isSignInException } from './amplify/amplify-errors';
import { isInvalidCodeError } from './amplify/amplify-errors';

export function ConfirmTotpPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];

	const [isLoading, setIsLoading] = useState(false);

	const navigateToNextStep = useNavigateToNextStep();

	const navigate = useNavigate();

	const form = useForm({
		defaultValues: { totp: '' },
		resolver: zodResolver(createSchema(translation))
	});

	const onSubmit = async (data: FormData) => {
		setIsLoading(true);

		try {
			const result = await confirmSignIn({
				challengeResponse: data.totp
			});

			navigateToNextStep({ step: result.nextStep.signInStep });
		} catch (error) {
			if (isInvalidCodeError(error)) {
				form.setError('totp', { message: translation.errors.invalidCode });
				return;
			}

			if (isExpiredCodeException(error)) {
				form.setError('totp', { message: translation.errors.expiredCode });
				return;
			}

			if (isSignInException(error)) {
				navigate(ROUTE_MAP.auth.login.createPath());
				return;
			}

			form.setError('totp', { message: translation.errors.genericError });
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout title={translation.title}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
				<Input
					autoFocus
					className="mb-10"
					label={translation.codeInput.label}
					id={'totp'}
					placeholder={translation.codeInput.placeholder}
					{...form.register('totp')}
					error={form.formState.errors.totp?.message}
				/>

				<Button
					className="w-full mb-6"
					type="submit"
					title={translation.submit}
					loading={isLoading}
				/>

				<Link className={LoginBackLinkStyle} to={ROUTE_MAP.auth.login.path}>
					{translation.back}
				</Link>
			</form>
		</Layout>
	);
}

const createSchema = (t: (typeof DICTIONARY)[keyof typeof DICTIONARY]) => {
	return z.object({
		totp: z.string().min(1, { message: t.codeInput.validation.emptyCode })
	});
};

type FormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		title: 'Authenticator code',

		codeInput: {
			label: 'Authenticator code',
			placeholder: 'Code from your Authenticator app',
			validation: {
				emptyCode: 'Please enter the code from your Authenticator app'
			}
		},

		submit: 'Submit',
		back: 'Go back',

		errors: {
			invalidCode: 'The code you entered is not valid',
			genericError: 'Could not log in. Please try again.',
			expiredCode: 'The code you entered has expired. Please try again.'
		}
	},
	NB: {
		title: 'Authenticator kode',
		codeInput: {
			label: 'Authenticator kode',
			placeholder: 'Kode fra din Authenticator-app',
			validation: {
				emptyCode: 'Skriv inn koden fra din Authenticator-app'
			}
		},
		submit: 'Send',
		back: 'Gå tilbake',

		errors: {
			invalidCode: 'Den koden du skrev inn er ikke gyldig',
			genericError: 'Kunne ikke logge inn. Prøv igjen.',
			expiredCode: 'Koden du skrev inn har utløpt. Prøv igjen.'
		}
	}
} as const;
