import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

import { useEffect, useMemo, useState } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';
import { useGetProjectQuery } from 'features/entry-form-v2/data/useGetProjectQuery';
import { useGetProjectOrganizationCollaboratorsQuery } from 'features/entry-form-v2/data/useGetProjectOrganizationCollaboratorsQuery';
import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { Button } from 'features/entry-form-v2/component/Button';

/**
 * UX On this isn't great but we ensure that the user is required to select a group with this approach.
 */
export const SelectGroup = ({
	onGroupSelected,
	projectId
}: {
	onGroupSelected: (groupId?: string) => void;
	projectId: string;
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();

	const groupsWithWriteAccessQuery = useGroupsWithWriteAccessQuery({
		projectId
	});

	useEffect(() => {
		if (!groupsWithWriteAccessQuery.data) {
			return;
		}

		if (groupsWithWriteAccessQuery.data?.length === 0) {
			return onGroupSelected(NO_GROUP_ID);
		}

		if (groupsWithWriteAccessQuery.data?.length === 1) {
			// No need to show the modal if there is only one group
			return onGroupSelected(groupsWithWriteAccessQuery.data[0].organizationId.toString());
		}

		setShowModal(true);
	}, [groupsWithWriteAccessQuery, onGroupSelected]);

	const selectOptions =
		groupsWithWriteAccessQuery.data?.map(group => ({
			label: group.organizationName,
			value: group.organizationId.toString()
		})) || [];

	if (selectOptions.length < 1) {
		return null;
	}

	return (
		<Dialog
			onClose={() => {
				// Not allowed to cancel this
				return;
			}}
			title="Group selection"
			open={showModal}
			hideCloseButton
		>
			<div className="flex flex-col justify-between w-[400px] h-[200px]">
				<div>
					<p className="text-sm">
						As a collaborator with multiple group memberships, select your current
						representative group to proceed. Your choice will determine entry ownership
						and impact operation execution.
					</p>

					<CreatableSelect
						className="mt-4"
						scrollIntoView={false}
						label="Select a group"
						items={selectOptions}
						onValueSelected={value => {
							setSelectedGroupId(value || undefined);
						}}
						value={selectOptions.find(item => item.value === selectedGroupId) || null}
					/>
				</div>

				<Button
					className="self-end"
					title="Confirm"
					onClick={() => {
						onGroupSelected(selectedGroupId);
					}}
				/>
			</div>
		</Dialog>
	);
};

/**
 * We need to determine write access from the projectQuery, but we need the group data from the projectOrganizationCollaboratorsQuery.
 */
const useGroupsWithWriteAccessQuery = ({ projectId }: { projectId: string }) => {
	const currentUserId = useCurrentUserId();

	const projectQuery = useGetProjectQuery({ projectId });

	const projectOrganizationCollaboratorsQuery = useGetProjectOrganizationCollaboratorsQuery({
		projectId
	});

	const groupsWithWriteAccess = useMemo(() => {
		if (!projectQuery.isFetchedAfterMount) {
			// Important e.g. in the case a user adds themselves to a group after the query is cached
			return undefined;
		}

		if (!projectQuery.data || !projectOrganizationCollaboratorsQuery.data) {
			return undefined;
		}

		const groupData = projectOrganizationCollaboratorsQuery.data?.project.organizations.filter(
			org => org.organizationCollaborators.find(c => c.userId === currentUserId)
		);

		const groupsWithWriteAccess =
			projectQuery.data.project.userAccess.projectOrganizationAccesses.filter(
				access => access.writeAccess
			);

		return groupData.filter(groupData =>
			groupsWithWriteAccess.find(g => g.organizationId === groupData.organizationId)
		);
	}, [
		projectQuery.isFetchedAfterMount,
		projectQuery.data,
		projectOrganizationCollaboratorsQuery.data,
		currentUserId
	]);

	return {
		data: groupsWithWriteAccess,
		loading: projectQuery.isLoading || projectOrganizationCollaboratorsQuery.isLoading,
		error: projectQuery.error || projectOrganizationCollaboratorsQuery.error
	};
};

/**
 * Hook to get the current user's ID from amplify.
 *
 * @returns the current user's ID once we've retrieved it from amplify, or undefined if we haven't yet.
 */
const useCurrentUserId = () => {
	const [currentUserId, setCurrentUserId] = useState<string>();
	useEffect(() => {
		async function asyncShit() {
			const user = await getCurrentUser();

			setCurrentUserId(user.username);
		}

		asyncShit();
	}, []);

	return currentUserId;
};

const NO_GROUP_ID = '-1';
