import { BrowserRouter } from 'react-router-dom';

import { Alerts } from './Alerts';
import ErrorBoundary from './ErrorBoundary';
import { Init } from './Init';
import { Navigation } from './Navigation';
import { Store } from './Store';
import { TrackingProvider } from './tracking/TrackingProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from 'features/auth/AuthProvider';
import { configureAmplify } from 'features/auth/amplify/configureAmplify';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false
		}
	}
});

configureAmplify();

export function App() {
	return (
		<BrowserRouter>
			<TrackingProvider>
				<Store>
					<Init>
						<ErrorBoundary>
							<Alerts>
								<QueryClientProvider client={queryClient}>
									<AuthProvider>
										<Navigation />
									</AuthProvider>
								</QueryClientProvider>
							</Alerts>
						</ErrorBoundary>
					</Init>
				</Store>
			</TrackingProvider>
		</BrowserRouter>
	);
}
