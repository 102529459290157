import { fetchAuthSession } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';

export function useGetIsUserAdminQuery() {
	return useQuery({
		queryKey: ['isUserAdmin'],
		queryFn: async () => {
			try {
				const session = await fetchAuthSession();
				const isUserAdmin =
					session.tokens?.idToken?.payload['custom:role'] === 'coreprjctssuperadmin';

				return isUserAdmin;
			} catch {
				return false;
			}
		}
	});
}
