import { Loader } from 'components/UI/Loader';
import { useCurrentUserQuery } from 'features/data/amplify/useCurrentUserQuery';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { Navigate, Outlet } from 'react-router-dom';

export function PublicRoutes() {
	const currentUser = useCurrentUserQuery();

	return currentUser.isLoading ? (
		<Loader primary />
	) : (
		<PublicRoutesInner isLoggedIn={Boolean(currentUser.data)} />
	);
}

function PublicRoutesInner({ isLoggedIn }: { isLoggedIn: boolean }) {
	if (isLoggedIn) {
		return <Navigate to={ROUTE_MAP.projects.path} />;
	}

	return <Outlet />;
}
