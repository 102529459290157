import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { useState, useEffect } from 'react';
import { useSelector } from 'hooks/utils';
import { LanguageType } from 'types';
import { MILLIS_BEFORE_LOGOUT } from './InactivityDetector';

type InactivityDialogProps = {
	open: boolean;
	onClose: () => void;
	lastActivityTimeStamp: number;
};

export const InactivityDialog = ({
	open,
	onClose,
	lastActivityTimeStamp
}: InactivityDialogProps) => {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];

	const [secondsRemaining, setSecondsRemaining] = useState<number>(
		getSecondsRemainingFromLastActivityTimestamp(lastActivityTimeStamp)
	);
	useEffect(() => {
		const interval = setInterval(() => {
			setSecondsRemaining(
				getSecondsRemainingFromLastActivityTimestamp(lastActivityTimeStamp)
			);
		}, 1000);
		return () => clearInterval(interval);
	}, [lastActivityTimeStamp]);

	return (
		<Dialog open={open} onClose={onClose} title={DICTIONARY[language].title} hideCloseButton>
			<p className="text-base">
				{translation.youWillBeLoggedOutDueToInactivityIn({
					secondsRemaining,
					language
				})}
			</p>
		</Dialog>
	);
};

const getSecondsRemainingFromLastActivityTimestamp = (lastActivityTimeStamp: number) => {
	const secondsRemaining = Math.ceil(
		(MILLIS_BEFORE_LOGOUT + lastActivityTimeStamp - Date.now()) / 1000
	);
	return secondsRemaining;
};

const DICTIONARY = {
	EN: {
		title: 'Inactivity Warning',
		youWillBeLoggedOutDueToInactivityIn: ({
			secondsRemaining,
			language
		}: {
			secondsRemaining: number;
			language: LanguageType;
		}) =>
			`You will be logged out due to inactivity in ${formatSeconds({
				secondsRemaining,
				language
			})}. Move your mouse or press a key to stay logged in.`,
		seconds: 'seconds',
		minute: 'minute',
		minutes: 'minutes'
	},

	NB: {
		title: 'Inaktivitetsvarsel',
		youWillBeLoggedOutDueToInactivityIn: ({
			secondsRemaining,
			language
		}: {
			secondsRemaining: number;
			language: LanguageType;
		}) =>
			`Du vil bli logget ut på grunn av inaktivitet om ${formatSeconds({
				secondsRemaining,
				language
			})}. Flytt musen eller trykk en tast for å forbli logget inn.`,
		seconds: 'sekunder',
		minute: 'minutt',
		minutes: 'minutter'
	}
} as const;

const formatSeconds = (args: { secondsRemaining: number; language: LanguageType }) => {
	const { secondsRemaining, language } = args;

	if (secondsRemaining < 60) {
		return `${secondsRemaining} ${DICTIONARY[language].seconds}`;
	}

	const minutes = Math.floor(secondsRemaining / 60);
	return `${minutes} ${
		minutes === 1 ? DICTIONARY[language].minute : DICTIONARY[language].minutes
	}`;
};
