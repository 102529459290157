import { Layout } from 'features/auth/Layout';
import { Button } from 'features/entry-form-v2/component/Button';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { useSelector } from 'hooks/utils';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { logout } from './utils';

export function LogoutPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const dictionary = DICTIONARY[language];

	useEffect(() => {
		logout();
	}, []);

	return (
		<Layout title={dictionary.title}>
			<Link to={ROUTE_MAP.auth.login.createPath()} className="self-center">
				<Button title={dictionary.toLogIn} />
			</Link>
		</Layout>
	);
}

const DICTIONARY = {
	EN: {
		title: 'You got signed out',
		toLogIn: 'Back to log in'
	},

	NB: {
		title: 'Du ble logget ut',
		toLogIn: 'Til innlogging'
	}
} as const;
