import { AuthError } from '@aws-amplify/auth';

export function isSignInException(error: unknown) {
	return error instanceof AuthError && error.name === 'SignInException';
}

export function isInvalidPasswordException(error: unknown) {
	return error instanceof AuthError && error.name === 'InvalidPasswordException';
}

export function isExpiredCodeException(error: unknown) {
	return error instanceof Error && error.name === 'ExpiredCodeException';
}

export function isUserNotConfirmedException(error: unknown) {
	return error instanceof Error && error.name === 'UserNotConfirmedException';
}

export function isInvalidCodeError(error: unknown) {
	return error instanceof AuthError && error.name === 'CodeMismatchException';
}

export function isLimitExceededException(error: unknown) {
	return error instanceof Error && error.name === 'LimitExceededException';
}

export function isUserUnAuthenticatedException(error: unknown) {
	return error instanceof Error && error.name === 'UserUnAuthenticatedException';
}
