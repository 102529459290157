import { z } from 'zod';

const ENV_SCHEMA = z.object({
	REACT_APP_COGNITO_USER_POOL_ID: z.string(),
	REACT_APP_COGNITO_USER_POOL_CLIENT_ID: z.string(),
	REACT_APP_COGNITO_IDENTITY_POOL_ID: z.string(),
	REACT_APP_ENVIRONMENT: z.enum(['systest', 'production', 'playground', 'playground_2'])
});

const parseResult = ENV_SCHEMA.safeParse(process.env);

if (!parseResult.success) {
	document.body.innerHTML = `
		<div style="background-color: #E94747; color: white; padding: 10px;">
			<h1>Missing environment variable configuration. Provide the variables and refresh the page manually.</h1>
			<pre>${JSON.stringify(parseResult.error, null, 2)}</pre>
		</div>
	`;
	console.error(parseResult.error);
	throw new Error(parseResult.error.message);
}

const env: z.infer<typeof ENV_SCHEMA> = parseResult.data;

export { env };
