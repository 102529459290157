import { useEffect, useState } from 'react';

import { apiFactory } from 'api';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { getAddUserValidationSchema, getEmailValidationSchema } from 'helpers/subscription';
import { PrimaryButtonProps, InputType, LedidiStatusCode } from 'types/index';
import {
	ActionTypes,
	SubscriptionStatus,
	UserRole,
	UserLicenceModel
} from 'store/account/subscription';

import { AddSubscriptionUserForm } from './AddSubscriptionUserForm';
import { AddSubscriptionUserView } from './AddSubscriptionUserView';
import { useAPI } from 'hooks/api';
import {
	useTranslation,
	useAccount,
	useSubscription,
	useAPIAddSubscriptionUser
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { usePrevious, useCompletedAction } from 'hooks/utils';

export type AddFormFields = {
	userFirstName: string;
	userSirName: string;
	country: string;
	phoneNumber: string;
	department: string;
	position: string;
	workplace: string;
	userRole?: UserRole;
	licenceModel: UserLicenceModel;
};

interface Props {
	visible: boolean;
	onClose: () => void;
}

export enum Steps {
	VerifyEmail = 'VerifyEmail',
	AlreadyExists = 'AlreadyExists',
	AlreadyExistsIsPending = 'AlreadyExistsIsPending',
	AlreadyExistsIsOwner = 'AlreadyExistsIsOwner',
	AddUser = 'AddUser',
	RequestTransfer = 'RequestTransfer',
	Confirmation = 'Confirmation'
}

export function AddSubscriptionUserModal({ visible, onClose }: Props) {
	const { translate } = useTranslation();
	const [{ data: accountData }] = useAccount();

	const [step, setStep] = useState<Steps>(Steps.VerifyEmail);

	// CHECKING If USER EXISTS / VERIFY EMAIL
	const [emailAddress, setEmailAddress] = useState('');
	const emailValidationSchema = getEmailValidationSchema({ translate });

	const {
		Form: EmailForm,
		handleSubmit: handleEmailSubmit,
		isDirtyAndValid: canSubmitEmailForm,
		errors,
		register
	} = useReactForm({
		initialValues: { emailAddress: '' },
		validationSchema: emailValidationSchema
	});

	const [
		{ data: userExists, error: verifyUserError, loading: verifyUserLoading },
		verifyUser,
		resetVerifyUser
	] = useAPI<{ found: boolean; isEnterpriseUser?: boolean } | undefined>({
		promiseFn: () => apiFactory().account.subscription().doesUserExist(emailAddress),
		lazy: true,
		initialData: undefined,
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: true
		},

		activityType: ActionTypes.VERIFY_USER_EXISTS
	});

	const [
		{
			data: {
				users,
				subscriptionTypes: { isLedidiEnterprise }
			}
		}
	] = useSubscription();

	/**
	 * Verifies if user is already part of the current users subscripiton.
	 * If not sends API call to check if user with this email exists in another subscription.
	 */
	const onEmailSubmit = handleEmailSubmit(data => {
		const foundUser = users.find(user => user.emailAddress === data.emailAddress);
		if (foundUser) {
			if (foundUser.isOwner) setStep(Steps.AlreadyExistsIsOwner);
			else if (foundUser.subscriptionStatus === SubscriptionStatus.Pending)
				setStep(Steps.AlreadyExistsIsPending);
			else setStep(Steps.AlreadyExists);
		} else {
			setEmailAddress(data.emailAddress);

			verifyUser(() => apiFactory().account.subscription().doesUserExist(data.emailAddress));
		}
	});

	/**
	 * In case user exists show Request Transfer User view (Steps.TransferUser),
	 * otherwise show the Form to Add new user (Steps.AddUser)
	 */
	const userPreviouslyFound = usePrevious(userExists?.found);
	useEffect(() => {
		if (!userPreviouslyFound && userExists !== undefined && !verifyUserError) {
			if (userExists?.found) {
				setStep(Steps.RequestTransfer);
			} else {
				setStep(Steps.AddUser);
			}
		}
	}, [userExists, verifyUserError]);

	// END - CHECKING If USER EXISTS / VERIFY EMAIL

	// ADD USER FORM / REQUEST TRANSFER

	const userToAdd: AddFormFields = {
		userFirstName: '',
		userSirName: '',
		country: '',
		phoneNumber: '',
		department: '',
		position: '',
		workplace: isLedidiEnterprise
			? accountData?.details
				? accountData.details.workplace
				: ''
			: '',
		userRole: isLedidiEnterprise ? UserRole.User : undefined,
		licenceModel: UserLicenceModel.Full // selector is disabled till there will be more options.
	};

	const [
		{
			data: { ledidiStatusCode },
			loading: addingUser,
			error: errorAddingUser
		},
		addUser,
		resetAddUser
	] = useAPIAddSubscriptionUser();

	const addUserValidationSchema = getAddUserValidationSchema({ translate });

	const {
		Form: AddForm,
		FormProvider: AddFormProvider,
		formProviderProps: addFormProviderProps,
		handleSubmit: handleAddSubmit
	} = useReactForm({
		initialValues: userToAdd,
		validationSchema: addUserValidationSchema
	});

	const onAddSubmit = handleAddSubmit(data => {
		addUser({ ...data, emailAddress });
	});

	const onRequestTransferSubmit = () => {
		addUser({ emailAddress });
	};

	// END - ADD USER FORM / REQUEST TRANSFER

	function onModalClose() {
		setStep(Steps.VerifyEmail);
		setEmailAddress('');
		resetVerifyUser();
		resetAddUser();
		onClose();
	}

	useCompletedAction(addingUser, errorAddingUser, () => {
		setStep(Steps.Confirmation);
	});

	function getPrimaryButton(): PrimaryButtonProps | undefined {
		switch (step) {
			case Steps.VerifyEmail:
				return {
					label: translate(({ buttons }) => buttons.continue),
					loading: verifyUserLoading,
					disabled: !canSubmitEmailForm, // emailAddress not en
					onClick: onEmailSubmit // call api
				};
			case Steps.AddUser:
				return {
					label: translate(({ buttons }) => buttons.addUser),
					loading: addingUser,
					onClick: onAddSubmit
				};
			case Steps.RequestTransfer:
				return {
					label: translate(({ buttons }) => buttons.requestTransfer),
					loading: addingUser,
					onClick: onRequestTransferSubmit
				};
			case Steps.AlreadyExists:
			case Steps.AlreadyExistsIsOwner:
			case Steps.AlreadyExistsIsPending:
				return {
					label: translate(({ buttons }) => buttons.ok),
					onClick: onModalClose
				};
			case Steps.Confirmation:
				return {
					label:
						ledidiStatusCode === LedidiStatusCode.SubscriptionUserInTransfer ||
						ledidiStatusCode === LedidiStatusCode.SubscriptionUserIsAlreadyOwner
							? translate(({ buttons }) => buttons.ok)
							: translate(({ buttons }) => buttons.done),
					onClick: onModalClose
				};

			default:
				return undefined;
		}
	}

	function getNeutralButton(): PrimaryButtonProps | undefined {
		switch (step) {
			case Steps.VerifyEmail:
			case Steps.AddUser:
			case Steps.RequestTransfer:
				return {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onModalClose
				};
			default:
				return undefined;
		}
	}
	const emailEligibleStep = step === Steps.AddUser && !!emailAddress;
	const emailCheckedSteps = [
		Steps.AlreadyExists,
		Steps.AlreadyExistsIsOwner,
		Steps.AlreadyExistsIsPending,
		Steps.RequestTransfer
	];
	const emailVerified = emailCheckedSteps.includes(step) || emailEligibleStep;

	return (
		<Modal
			visible={visible}
			title={translate(({ accountUM }) => accountUM.addSubscriptionUser.addModalTitle)}
			primary={getPrimaryButton()}
			neutral={getNeutralButton()}
			onClose={onModalClose}
			close
		>
			{step !== Steps.Confirmation && (
				<EmailForm onSubmit={onEmailSubmit}>
					<Input
						{...register('emailAddress')}
						type={InputType.Email}
						autoComplete="off"
						autoCorrect="off"
						error={emailVerified ? undefined : errors.emailAddress?.message}
						label={translate(dict => dict.accountUM.userDetails.newUserEmailAddress)}
						placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
						required
						disabled={step !== Steps.VerifyEmail}
						dataTestId="add-user-email-input"
					/>
				</EmailForm>
			)}

			{step === Steps.AddUser && (
				<AddFormProvider {...addFormProviderProps}>
					<AddForm onSubmit={onAddSubmit}>
						<AddSubscriptionUserForm />
					</AddForm>
				</AddFormProvider>
			)}

			{(step === Steps.RequestTransfer ||
				step === Steps.AlreadyExists ||
				step === Steps.AlreadyExistsIsOwner ||
				step === Steps.AlreadyExistsIsPending) && <AddSubscriptionUserView type={step} />}

			{step === Steps.Confirmation && <AddSubscriptionUserView type={ledidiStatusCode} />}
		</Modal>
	);
}
