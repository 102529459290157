import { UserLicenceModel } from 'store/account/subscription';
import { useAccount } from './useAccount';
import { SubscriptionStatus } from 'store/account/subscription';
import { useSubscription } from './useSubscription';
import { useMemo } from 'react';
import { stringAsBoolean } from 'helpers/generic';
import { useFlags } from 'launchdarkly-react-client-sdk';

type InterfaceRules = {
	canChangePlan: boolean;
	canCancelSubscription: boolean;
	canTransferProjectOwnership: boolean;
	showUpgradeButton: boolean;
	isSubscriptionUpdating: boolean;
	showEnterpriseAdminRoute: boolean;
	isNonPaidSubscription: boolean;
};

export function useSubscriptionRules(): InterfaceRules {
	const [
		{
			data: {
				subscriptionTypes: {
					isNonSubscriber,
					isLedidiFree,
					isLedidiFreeTrial,
					isLedidiCollaborator,
					isLedidiCore,
					isLedidiEnterprise
				},
				userTypesAndRoles: {
					ledidiCore,
					ledidiEnterprise: {
						modules: { hasEnterpriseAdminRights },
						isOrganizationAdmin,
						isSuperAdmin
					}
				},
				status
			},
			loading: subscriptionLoading
		}
	] = useSubscription();

	const [{ data: accountData }] = useAccount();

	const ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED = stringAsBoolean(
		process.env.REACT_APP_USE_ENTERPRISE_ADMIN_ROUTES!
	);

	const { deactivationReactivationUserFlag } = useFlags();

	const canChangePlan = useMemo(
		() =>
			isNonSubscriber ||
			isLedidiFree ||
			isLedidiFreeTrial ||
			isLedidiCollaborator ||
			ledidiCore.isOwner,
		[isNonSubscriber, isLedidiFree, isLedidiFreeTrial, isLedidiCollaborator, ledidiCore]
	);

	const canCancelSubscription = useMemo(
		() => isLedidiCollaborator || ledidiCore.isOwner,
		[isLedidiCollaborator, ledidiCore]
	);

	const showUpgradeButton = useMemo(
		() =>
			(isLedidiFree || isLedidiFreeTrial || isNonSubscriber) &&
			!!accountData &&
			accountData.details?.licenceModel !== UserLicenceModel.Full,
		[isNonSubscriber, isLedidiCore, ledidiCore]
	);

	const isSubscriptionUpdating = useMemo(
		() => status === SubscriptionStatus.ChangePlanInProgress || subscriptionLoading,
		[status, subscriptionLoading]
	);

	const showEnterpriseAdminRoute = useMemo(
		() => hasEnterpriseAdminRights && ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED,
		[hasEnterpriseAdminRights]
	);

	const isNonPaidSubscription = useMemo(
		() => isLedidiFree || isLedidiFreeTrial || isNonSubscriber,
		[isLedidiFree, isLedidiFreeTrial, isNonSubscriber]
	);

	const canTransferProjectOwnership = useMemo(
		() =>
			isLedidiEnterprise &&
			deactivationReactivationUserFlag &&
			(isOrganizationAdmin || isSuperAdmin),
		[isLedidiEnterprise, deactivationReactivationUserFlag, accountData]
	);

	return {
		canChangePlan,
		canCancelSubscription,
		canTransferProjectOwnership,
		showUpgradeButton,
		isSubscriptionUpdating,
		showEnterpriseAdminRoute,
		isNonPaidSubscription
	};
}
