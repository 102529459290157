import { Link, useNavigate, useParams } from 'react-router-dom';
import { isSystemVariable, SystemVariable } from '../../types';
import { PropsWithChildren, useState } from 'react';
import { DateTime } from 'luxon';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import clsx from 'clsx';
import { SeriesEntryTableColumn, SeriesEntryTableVariables } from './useSeriesTablesDataQuery';
import { Skeleton } from 'features/entry-form-v2/component/Skeleton';
import { createEntriesSort } from './entriesSort';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { Button } from 'features/entry-form-v2/component/Button';
import { EmptyDatasetIcon } from '@icons';
import { BackendEntryValue } from 'features/entry-form-v2/types';

export const SeriesEntryBody = ({
	seriesName,
	columns,
	rows,
	variables,
	projectId,
	entryId
}: {
	seriesName: string;
	columns: SeriesEntryTableColumn[];
	rows: SeriesEntryBodyRow[];
	variables: SeriesEntryTableVariables;
	projectId: string;
	entryId: string;
}) => {
	return (
		<div className="w-full overflow-x-auto">
			{rows.length === 0 && (
				<div className="flex flex-col items-center justify-center h-full">
					<EmptyDatasetIcon className="w-[200px] h-[200px]" />

					<p className="text-base font-semibold">No entries to display</p>

					<p className="text-base mt-6">Get started by creating your first entry</p>

					<Link
						to={ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.create.createPath(
							{
								projectId,
								entryId,
								seriesName,
								formId: null
							}
						)}
						className="mt-10"
					>
						<Button title="Create series entry" variant="primary" />
					</Link>
				</div>
			)}

			{rows.length > 0 && (
				<SeriesTable
					columns={columns}
					rows={rows}
					seriesName={seriesName}
					variables={variables}
				/>
			)}
		</div>
	);
};

export const TableSkeleton = () => {
	return (
		<div className="min-w-full flex flex-col gap-2">
			{Array.from({ length: 10 }).map((_, i) => (
				<Skeleton key={i} className="w-full h-12 rounded-md" />
			))}
		</div>
	);
};

const DEFAULT_SORT = {
	variableName: 'creationdate',
	direction: 'desc'
} as const;

type GroupColumnVisibilityState = 'expanded' | 'collapsed';
type ColumnVisibilityStateByGroupName = Record<string, GroupColumnVisibilityState>;
type ColumnSort = { variableName: string; direction: SortDirection };
const SeriesTable = ({
	columns,
	rows,
	variables,
	seriesName
}: {
	columns: SeriesEntryTableColumn[];
	rows: SeriesEntryBodyRow[];
	variables: SeriesEntryTableVariables;
	seriesName: string;
}) => {
	const [groupVisibilityState, setGroupVisibilityState] =
		useState<ColumnVisibilityStateByGroupName>(
			columns.reduce((acc, column) => {
				if (column.type !== 'group') {
					return acc;
				}

				acc[column.group.groupName] = 'expanded';
				return acc;
			}, {} as ColumnVisibilityStateByGroupName)
		);

	const [overrideSort, setOverrideSort] = useState<ColumnSort | undefined>();

	return (
		<table className="w-full overflow-auto">
			<thead className="border-2 border-gray-300">
				<GroupHeaderRow
					columns={columns}
					groupVisibilityState={groupVisibilityState}
					onGroupVisibilityStateChanged={updated =>
						setGroupVisibilityState({
							...groupVisibilityState,
							[updated.groupName]: updated.state
						})
					}
				/>

				<VariableNamesHeaderRow
					columns={columns}
					variables={variables}
					groupVisibilityState={groupVisibilityState}
					currentSort={overrideSort}
					onSortChanged={setOverrideSort}
				/>
			</thead>

			<Body
				columns={columns}
				seriesName={seriesName}
				groupVisibilityState={groupVisibilityState}
				rows={rows.sort((a, b) => {
					const sortState = overrideSort ?? DEFAULT_SORT;

					const variable = variables[sortState.variableName];

					const sort = createEntriesSort({
						variable,
						direction: sortState.direction
					});

					return sort(
						a.fieldsByVariableName[sortState.variableName],
						b.fieldsByVariableName[sortState.variableName]
					);
				})}
				variables={variables}
			/>
		</table>
	);
};

const GroupHeaderRow = ({
	columns,
	groupVisibilityState,
	onGroupVisibilityStateChanged: setGroupVisibilityState
}: {
	columns: SeriesEntryTableColumn[];
	groupVisibilityState: ColumnVisibilityStateByGroupName;
	onGroupVisibilityStateChanged: (newState: {
		groupName: string;
		state: GroupColumnVisibilityState;
	}) => void;
}) => {
	return (
		<tr>
			{columns.map(column => {
				if (column.type === 'variable' || column.type === 'system-variable') {
					return <th key={column.variable.variableName} className="p-4" />;
				}

				const isGroupCollapsed =
					groupVisibilityState[column.group.groupName] === 'collapsed';

				return (
					<th
						colSpan={
							isGroupCollapsed ? 1 : column.group.variablesBelongingToGroup.length
						}
						key={column.group.groupName}
						className="border-t-2 border-accent-500 px-4 py-1"
					>
						<button
							type="button"
							className="text-start font-semibold text-sm w-full flex gap-1 items-center text-primary-500 whitespace-nowrap"
							onClick={() =>
								setGroupVisibilityState({
									groupName: column.group.groupName,
									state: isGroupCollapsed ? 'expanded' : 'collapsed'
								})
							}
						>
							<Icon
								svg={Svgs.ChevronDown}
								size={s => s.m}
								className={clsx(isGroupCollapsed && '-rotate-90')}
								propagate
							/>
							{column.group.groupLabel}
						</button>
					</th>
				);
			})}
		</tr>
	);
};

const VariableNamesHeaderRow = ({
	columns,
	variables,
	groupVisibilityState,
	currentSort,
	onSortChanged
}: {
	columns: SeriesEntryTableColumn[];
	variables: SeriesEntryTableVariables;
	groupVisibilityState: ColumnVisibilityStateByGroupName;
	currentSort?: ColumnSort;
	onSortChanged: (columnSort?: ColumnSort) => void;
}) => {
	return (
		<tr>
			{columns.map(column => {
				if (column.type === 'variable' || column.type === 'system-variable') {
					return (
						<ColumnHeader
							key={column.variable.variableName}
							variableLabel={column.variable.variableLabel}
							variableName={column.variable.variableName}
							currentSort={currentSort}
							onSortChanged={onSortChanged}
						/>
					);
				}

				if (groupVisibilityState[column.group.groupName] === 'collapsed') {
					return <th key={column.group.groupName}></th>;
				}

				return column.group.variablesBelongingToGroup.map(variableName => {
					const variable = variables[variableName];
					if (!variable) {
						console.warn(`Variable ${variableName} not found.`);
						return <th key={variableName}></th>;
					}

					return (
						<ColumnHeader
							key={variable.variableName}
							variableLabel={variable.variableLabel}
							variableName={variable.variableName}
							onSortChanged={onSortChanged}
							currentSort={currentSort}
						/>
					);
				});
			})}
		</tr>
	);
};

export type SeriesEntryBodyRowField = {
	label: string;
	value: BackendEntryValue;
};

export type SeriesEntryBodyRow = {
	fieldsByVariableName: Record<string, SeriesEntryBodyRowField>;
	datasetentryid: string;
};

const Body = ({
	rows,
	columns,
	variables,
	groupVisibilityState,
	seriesName
}: {
	rows: SeriesEntryBodyRow[];
	columns: SeriesEntryTableColumn[];
	variables: SeriesEntryTableVariables;
	groupVisibilityState: ColumnVisibilityStateByGroupName;
	seriesName: string;
}) => {
	const params = useParams();
	const navigate = useNavigate();

	const projectId = params['projectId'] as string;
	const entryId = params['entryId'] as string;

	const navigateToUpdateSeriesEntry = (seriesEntryId: string) =>
		navigate(
			ROUTE_MAP.projects.byId.dataset.update.series.bySeriesName.update.createPath({
				projectId,
				entryId,
				seriesName,
				seriesEntryId,
				formId: null
			})
		);

	return (
		<tbody>
			{rows.map((row, index) => {
				return (
					<tr
						onClick={() => navigateToUpdateSeriesEntry(row.datasetentryid)}
						onKeyDown={e => {
							if (e.key === 'Enter' || e.key === ' ') {
								e.preventDefault();
								navigateToUpdateSeriesEntry(row.datasetentryid);
							}
						}}
						aria-label="Update entry"
						tabIndex={0}
						role="button"
						key={row.datasetentryid}
						className={clsx(index % 2 === 0 && 'bg-gray-300')}
						data-testid={`series-entry-body-row-${row.datasetentryid}`}
					>
						{columns.map(column => {
							if (column.type === 'variable') {
								return (
									<Cell
										key={column.variable.variableName}
										data-testid={`series-entry-row-element-${column.variable.variableName}-${row.datasetentryid}`}
									>
										{
											row.fieldsByVariableName[column.variable.variableName]
												.label
										}
									</Cell>
								);
							}

							if (column.type === 'system-variable') {
								return (
									<Cell
										key={column.variable.variableName}
										data-testid={`series-entry-row-element-${column.variable.variableName}-${row.datasetentryid}`}
									>
										{
											row.fieldsByVariableName[column.variable.variableName]
												.label
										}
									</Cell>
								);
							}

							if (groupVisibilityState[column.group.groupName] === 'collapsed') {
								return (
									<td key={column.group.groupName} className="text-center">
										-
									</td>
								);
							}

							return column.group.variablesBelongingToGroup.map(variableName => {
								const variable = variables[variableName];
								if (!variable) {
									console.warn(`Variable ${variableName} not found.`);
									return <td key={variableName}></td>;
								}

								if (isSystemVariable(variable)) {
									console.error(
										new Error(
											'System variable found in group, this should not be possible'
										)
									);

									return (
										<Cell key={variable.variableName}>
											{row.fieldsByVariableName[variable.variableName].label}
										</Cell>
									);
								}

								return (
									<Cell key={variable.variableName}>
										{row.fieldsByVariableName[variable.variableName].label}
									</Cell>
								);
							});
						})}
					</tr>
				);
			})}
		</tbody>
	);
};

export const formatSystemVariableValue = ({
	variable,
	value,
	namesByUserId
}: {
	variable: SystemVariable;
	value: string;
	namesByUserId: Record<string, string>;
}): string => {
	switch (variable.variableName) {
		case 'creationdate':
		case 'lastmodifieddate':
			return DateTime.fromJSDate(new Date(value)).toLocaleString(DateTime.DATETIME_MED);

		case 'ownedbyuser':
		case 'enteredbyuser':
			return namesByUserId[value] || 'Empty';

		case 'datasetentryid':
			return value;

		default:
			console.error('Unknown system variable', { variable });
			return 'Unknown variable type';
	}
};

const Cell = ({
	children,
	...props
}: PropsWithChildren<React.HTMLAttributes<HTMLTableCellElement>>) => {
	return (
		<td className="whitespace-nowrap p-4 text-base" {...props}>
			{children}
		</td>
	);
};

export type SortDirection = 'asc' | 'desc';

const ColumnHeader = ({
	variableLabel,
	variableName,
	currentSort,
	onSortChanged
}: {
	variableName: string;
	variableLabel: string;
	currentSort?: ColumnSort;
	onSortChanged: (columnSort?: ColumnSort) => void;
}) => {
	const updateSort = () => {
		if (!currentSort || currentSort.variableName !== variableName) {
			onSortChanged({
				variableName: variableName,
				direction: 'asc'
			});
			return;
		}

		if (currentSort.direction === 'asc') {
			onSortChanged({
				variableName: variableName,
				direction: 'desc'
			});
			return;
		}

		onSortChanged(undefined);
	};

	return (
		<th
			key={variableName}
			className="font-semibold text-primary-500 whitespace-nowrap p-4 text-sm text-start border border-gray-300"
		>
			<button type="button" className="flex gap-2 items-center" onClick={updateSort}>
				{variableLabel}

				<Icon
					svg={Svgs.ArrowDown}
					className={clsx(
						currentSort?.direction === 'desc' && '-rotate-180',
						currentSort?.variableName !== variableName && 'invisible'
					)}
					propagate
					customSize={2}
					colors={{
						color: Colors.primary.normal
					}}
				/>
			</button>
		</th>
	);
};
