import { HickupsWarning } from 'components/Login';
import { Svgs } from 'environment';

export function Layout({ children, title }: { children: React.ReactNode; title?: string }) {
	return (
		<>
			<HickupsWarning />

			<div className="p-[6rem_2.4rem_4.8rem]">
				<div className="flex flex-col w-full h-full mx-auto max-w-[42.8rem]">
					<div className="flex justify-center mb-[9.6rem]">
						<Svgs.LedidiLogoLogin style={{ height: 30 }} />
					</div>

					{title && <h1 className="text-4xl text-center font-semibold mb-20">{title}</h1>}

					{children}
				</div>
			</div>
		</>
	);
}

export const LoginBackLinkStyle = 'text-sm hover:underline font-semibold text-center';
